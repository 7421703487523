import React, { useState } from 'react'
//antd
import { Col, Layout, Menu, Modal, Row, Tabs, Typography } from 'antd'

//component
import DisplayMedia from './Detail/Display/index';
import UploadMedia from './Detail/Upload/index';

const {  Content, Sider } = Layout;

function MediaModal({isModalVisible,setModalVisible,setValue,selectedMedia}) {
  const [tab,setTabs] = useState()
  const onCancel = () =>{
    setModalVisible(false)
  }

  const items = [
    getItem('Thêm media', '1'),
  ]

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const changeTabs = (value) =>{
    setTabs(value)
  }
  const ChooseTab = [
    {
        icon: 'ss',
        label: 'Tải file',
        key: 'Upload',
        children: <UploadMedia></UploadMedia>
    },
    {
        icon: 'ss',
        label: 'Media',
        key: 'Display',
        children: <DisplayMedia onCancel={onCancel} setValue={setValue} setModalVisible={setModalVisible} selectedMedia={selectedMedia} isShowButton={true} tab={tab}></DisplayMedia>
    }
]
  return (
    <>
      <Modal
          open={isModalVisible}
          width={2000}
          onCancel={onCancel}
          className='top-5 media   '
          cancelButtonProps={ { style: { display:'none'}}}
          okButtonProps={{ style: { display:'none'}}}
        >
          <Layout>
            <Sider breakpoint="lg"          
                  className='pt-5 pl-4'
                  style={{background:'white'}
                }
            >
              <p className='text-base mb-4 font-bold'>Hành động</p>
              <Menu  defaultSelectedKeys={['1']} items={items} className='text-base'>
              </Menu>
            </Sider>
                 
            <Layout className='pt-5 bg-white'>
              <Content>
                <Typography.Title className='px-5' level={3}>Thêm media</Typography.Title>
                <Row >
                  <Col span={24}>
                    <Tabs         
                      type="card"
                      defaultActiveKey="2"
                      items={ChooseTab}
                      className="media"
                      onChange={(value)=>changeTabs(value)}
                    />
                  </Col>       
              </Row>
              </Content>
            </Layout>
          </Layout>       
        </Modal>
    </>
  )
}

export default MediaModal
