import { DeleteOutlined, DownloadOutlined, EnterOutlined, PictureOutlined, SaveOutlined } from '@ant-design/icons'
import { Form, Input, notification, Popconfirm, Space, Button, Row, Col, Tabs, Card, InputNumber, Select, Image } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { processedSlug, translate } from '../../../helper'
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import './detail.scss'
import MediaModal from '../../../components/Modal/ModalMedia'
import { ImageDefault, editorConfiguration } from '../../../config/const'
import { createProduct, detailProduct, updateProduct } from '../../../services/productService'
import { listCategoryProduct } from '../../../services/categoryProductService'

function ProductDetail() {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [dataForm, setDataForm] = useState({
    productLangs:{
      vi:{},
      en:{}
    }
  })
  const [loading, setLoading] = useState(false)
  const [categorys, setCategorys] = useState([])
  const [hostName, setHostName] = useState('')
  const [seo, setSeo] = useState({})
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [urlImage, setUrlImage] = useState("");

  const params = useParams();
  let editorInstance = {};
  const _detail = async (id) => {
    try {
      setLoading(true)
      const res = await detailProduct(id)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      const { actived, categoryProductId, name, ordering, productLangs, createdAt, urlImage } = res.data.data;
      const dataform = {
        actived,
        categoryProductId,
        name,
        ordering,
        createdAt,
        urlImage,
        productLangs: {}
      }
      for (let i = 0; i < productLangs.length; i++) {
        const { langCode } = productLangs[i];
        dataform.productLangs[langCode] = productLangs[i];
      }
      setUrlImage(urlImage)
      setDataForm(dataform)
      form.setFieldsValue(dataform)
    } catch (err) {
      console.log(err)
      notification.error({ message: err.message })
    } finally {
      setLoading(false)
    }
  }

  const submit = async () => {
    try {
      await form.validateFields();
      const { name, ordering, categoryProductId, productLangs, actived } = form.getFieldsValue();
      const body = {
        name,
        ordering,
        categoryProductId,
        actived,
        urlImage,
        productLangs: []
      };
      let isCheckTab = true;
      ['en', 'vi'].forEach(item => {
        if (!productLangs[item]) {
          isCheckTab = false
        }
      });
      if (!params.id && !isCheckTab) {
        return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
      }
      for (const item in productLangs) {
        productLangs[item].langCode = item;
        productLangs[item].urlLink = productLangs['vi'].urlLink;
        if (params.id) { // type Update
            productLangs[item].id = dataForm.productLangs[item].id;
        }
        body.productLangs.push(productLangs[item])
      }
      if(urlImage && urlImage.length > 0){
        body.urlImage = urlImage
      }
      if (!params.id) {
        const res = await createProduct(body)
        if (!res.data || res.data.code !== 200) {
          return notification.warning({
            message: res.data.message || 'Tạo sản phẩm thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
      }
      if (params.id) {
        body.id = parseInt(params.id)
        const res = await updateProduct(body)
        if (!res.data || res.data.code !== 200) {
          return notification.warning({
            message: res.data.message || 'Chỉnh sửa sản phẩm thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
      }
      navigate('/product')
    } catch (err) {
      if (err.errorFields?.length > 0) {
        return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
      }
      console.log(err)
      notification.error({ message: err.message })
    } finally {
    }
  }

  // getCategorys
  const getCategorys = async () => {
    try {
      const res = await listCategoryProduct()
      if (!res.data || res.data.code !== 200) {
        return notification.warning({ message: res.data.message })
      }
      setCategorys(
        res.data.data.list.map((item) => {
          return {
            value: item.id,
            label: item.name,
          }
        })
      )
    } catch (error) {
      notification.error({ message: error.message })
    } finally {
    }
  }

  const changeHeading = (value, langCode) => {
    form.setFieldValue(['productLangs', langCode, 'urlLink'], processedSlug(value.target.value));
  }

  const blurHeading = (value, langCode) => {
    let productLangs = {
      vi: {},
      en: {}
    }
    productLangs[langCode].title = value.target.value;
    setSeo(productLangs)
  }

  const handleMedia = (value) => {
    setSelectedMedia(value);
    setModalVisible(true)
  };

  const handleValueMedia = (value) => {
    if(selectedMedia.field  === 'urlImage'){
      setUrlImage(value[0].url)
    }
    if(selectedMedia.field  === 'description'){
      for (let i = 0; i < value.length; i++) {
        if(value[i].type === "image"){
          editorInstance[selectedMedia.langCode].model.change( writer => {
            const imageElement = writer.createElement("image", {
              src: process.env.REACT_APP_API_ENDPOINT_DEV +'/'+ value[i].url,
              alt:"",
              width:"300px"
            });
            editorInstance[selectedMedia.langCode].model.insertContent(
              imageElement,
              editorInstance[selectedMedia.langCode].model.document.selection.getFirstPosition(),
            );
          });
        }
      }
    }
  };

  const handleDeleteMedia = (value) => {
    if (value.field === "urlImage") {
      setUrlImage("");
    } 
  };

  useEffect(() => {
    if (params.id) {
      _detail(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    setHostName(process.env.REACT_APP_DOMAIN)
    getCategorys()
  }, []);

  const ContentTab = ({ langCode }) => {
    return (
      <>
        <div>
          <Form.Item
            name={['productLangs', langCode, 'title']}
            label={translate("Tiêu đề", langCode)}
            rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}
          >
            <Input placeholder={translate("Nhập tiêu đề", langCode)} onInput={(value) => { changeHeading(value, langCode) }} onBlur={(value) => blurHeading(value, langCode)} />
          </Form.Item>
          <Form.Item
            name={['productLangs', langCode, 'description']}
            label={translate("Mô tả", langCode)}
            rules={[{ required: false }]}
          >
            <Button className='mb-1' onClick={() => handleMedia({ key: 'mutiple', type: 'image', field: 'description', langCode })} ><PictureOutlined />Thêm Media</Button>
            <CKEditor
              editor={ Editor }
              config={ editorConfiguration }
              data={params && params.id && dataForm.productLangs && dataForm.productLangs[langCode] ? dataForm.productLangs[langCode].description : form.getFieldValue(['productLangs', langCode, 'description'])}
              onChange={(event, editor) => {
                const data = editor.getData();
                form.setFieldValue(['productLangs', langCode, 'description'], data);
                dataForm.productLangs[langCode].description = data;
                setDataForm(dataForm)
              }}
              onReady={(editor) => {
                editorInstance[langCode] = editor;
              }}
            />
          </Form.Item>
        </div>
        <Card type="inner" title="Tối ưu SEO" bordered={false}>
          <div>
            {translate("Thiết lập các thẻ mô tả giúp khách hàng dễ dàng tìm thấy danh mục này trên công cụ tìm kiếm như Google.", langCode)}
          </div>
          <div>
            Xem trước:
            <div className='yoast-seo'>
              <div className="sc-bSqbRM dlCkVf">
                <span className="screen-reader-text" >
                  Xem trước URL:</span>
                <div className="sc-tAEdG joWiup">
                  <div className="sc-tAEdG sc-dSfclH joWiup hgXshB">
                    <div className="sc-JkiRB hHABJO">
                      <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABs0lEQVR4AWL4//8/RRjO8Iucx+noO0MWUDo16FYABMGP6ZfUcRnWtm27jVPbtm3bttuH2t3eFPcY9pLz7NxiLjCyVd87pKnHyqXyxtCs8APd0rnyxiu4qSeA3QEDrAwBDrT1s1Rc/OrjLZwqVmOSu6+Lamcpp2KKMA9PH1BYXMe1mUP5qotvXTywsOEEYHXxrY+3cqk6TMkYpNr2FeoY3KIr0RPtn9wQ2unlA+GMkRw6+9TFw4YTwDUzx/JVvARj9KaedXRO8P5B1Du2S32smzqUrcKGEyA+uAgQjKX7zf0boWHGfn71jIKj2689gxp7OAGShNcBUmLMPVjZuiKcA2vuWHHDCQxMCz629kXAIU4ApY15QwggAFbfOP9DhgBJ+nWVJ1AZAfICAj1pAlY6hCADZnveQf7bQIwzVONGJonhLIlS9gr5mFg44Xd+4S3XHoGNPdJl1INIwKyEgHckEhgTe1bGiFY9GSFBYUwLh1IkiJUbY407E7syBSFxKTszEoiE/YdrgCEayDmtaJwCI9uu8TKMuZSVfSa4BpGgzvomBR/INhLGzrqDotp01ZR8pn/1L0JN9d9XNyx0AAAAAElFTkSuQmCC' alt="" className="sc-hYQoDq fTqTJk" />
                    </div>
                    <span className="sc-hZpJuv feGpeb">
                      <div className="sc-jvvkMf gYNhjI">{hostName}
                      </div><span className="sc-cHzrye bZphhk">{window.location.origin}</span> › product › {params.id} › {seo && seo.productLangs && seo.productLangs[langCode] ? seo.productLangs[langCode].title : form.getFieldValue(['productLangs', 'vi', 'urlLink'])}</span>
                  </div>
                </div>
                <span className="screen-reader-text">
                  Xem trước tiêu đề SEO:</span>
                <div className="sc-gIBoTZ gMwUgP">
                  <div className="sc-fyrnIy sc-iWVLQz dwZvnc igrcjV">
                    <span className="sc-bUbQrF fbVpha">{seo && seo.productLangs && seo.productLangs[langCode] ? seo.productLangs[langCode].title : form.getFieldValue(['productLangs', langCode, 'title'])} - {hostName}</span>
                  </div>
                </div>
              </div>
              <div className="sc-bSqbRM dlCkVf">
                <span className="screen-reader-text" >Xem trước mô tả:</span>
                <div className="sc-dkQkSb gbobMG"><div className="sc-dkQkSb gbobMG">
                  <div className="sc-jivCuG hcnbXe">
                  </div><span className="sc-iWBMBB hThKVW">{params.id ? moment(dataForm.createdAt).format('MMM DD, YYYY') : moment().format('MMM DD, YYYY')} － </span>
                  {seo && seo.productLangs && seo.productLangs[langCode] ? seo.productLangs[langCode].seoDescription : form.getFieldValue(['productLangs', langCode, 'seoDescription'])}
                </div>
                </div>
              </div>
            </div>
          </div>
          <Form.Item
            name={['productLangs', 'vi', 'urlLink']}
            label={translate("Đường dẫn", langCode)}
            rules={[{ required: false }]}
          >
            <Input addonBefore="/product/" />
          </Form.Item>
          <Form.Item
            name={['productLangs', langCode, 'seoTitle']}
            label={translate("Tiêu đề trang", langCode)}
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['productLangs', langCode, 'seoDescription']}
            label={translate("Mô tả trang", langCode)}
            rules={[{ required: false }]}
          >
            <Input.TextArea
              showCount
              maxLength={250}
              style={{
                height: 70,
                resize: 'none',
              }}
              onBlur={(value) => blurHeading(value, langCode)}
            />
          </Form.Item>
          <Form.Item
            name={['productLangs', langCode, 'seoKeywords']}
            label={translate("Seo từ khóa", langCode)}
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
        </Card>
      </>
    )
  }

  const TABS = [
    {
      label: 'Tiếng Việt',
      key: 'vi',
      children: <ContentTab langCode="vi"></ContentTab>
    },
    {
      label: 'Tiếng Anh',
      key: 'en',
      children: <ContentTab langCode="en"></ContentTab>
    }
  ]
  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
    >
      <Row className='mt-4' gutter={16}>
        <Col span={16}>
          <Tabs
            type="card"
            defaultActiveKey="2"
            items={TABS}
          />
        </Col>
        <Col span={8} className="mt-6">
          <div className="flex justify-end items-center">
            <Space>
              <Popconfirm
                title={!params.id ? 'Xác nhận để tạo bài viết' : 'Xác nhận để chỉnh sửa bài viết'}
                okText="Yes"
                cancelText="No"
                onConfirm={() => submit()}
              >
                <Button type="primary" loading={loading}>
                  <SaveOutlined />
                  Xác nhận
                </Button>
              </Popconfirm>
              <Button danger onClick={() => navigate('/post')}>
                <EnterOutlined />
                Quay lại</Button>
            </Space>
          </div>
          <Form.Item
            name={'urlImage'}
            label={"Hình đại diện"}
          >
            <Image
              width={'100%'}
              height={'100%'}
              src={urlImage ? process.env.REACT_APP_API_ENDPOINT_DEV + urlImage : ImageDefault }
            />
            <Space>
              <Button onClick={() => handleMedia({ key: 'single', type: 'image', field: 'urlImage' })} icon={<DownloadOutlined />} >
                Upload
              </Button>
              <Button danger onClick={() => handleDeleteMedia({ key: 'single', type: 'image', field: 'urlImage' })} icon={<DeleteOutlined />} >
                  Remove
              </Button>
            </Space>
          </Form.Item>
          <Form.Item
            name="name"
            label={"Tên"}
            rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
          >
            <Input placeholder={"Nhập tên"} />
          </Form.Item>
          <Form.Item
            name="ordering"
            label={"Sắp xếp"}
            style={{ width: "100%" }}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Chọn chuyên mục"
            name="categoryProductId"
            rules={[{ message: 'Vui lòng chọn chuyên mục', required: true }]}
          >
            <Select
              placeholder={'Chọn chuyên mục'}
              style={{ width: '100%' }}
              options={categorys}
            />
          </Form.Item>
          <Form.Item
            label="Trạng thái"
            name="actived"
            rules={[{ message: 'Vui lòng chọn trạng thái', required: true }]}
          >
            <Select
              placeholder={'Chọn trạng thái'}
              style={{ width: '100%' }}
              options={[
                {
                  value: 1,
                  label: 'Hiển thị',
                },
                {
                  value: 0,
                  label: 'Ẩn hiển thị',
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <MediaModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} setValue={(value) => handleValueMedia(value)} selectedMedia={selectedMedia}></MediaModal>
    </Form>
  )
}

export default ProductDetail