import { useForm } from "react-hook-form";
import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
//atnd
import { notification } from 'antd'

//imgs
import logo from "../../assets/imgs/logo.svg"
//api
import { LOGIN } from '../../redux/reducers/login';
import { login } from '../../services/loginService';
import { LoadingIcon } from "../../assets/icons/Icons";
import { getListAccessRole } from "../../services/permission";
import { ACCESS_ROLES } from "../../redux/reducers/permission";



function Login() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    formState: { errors },
    formState
  } = useForm({
    defaultValues:{
      email:"",
      password:""
    }
  }
  );
  const onSubmit = async ({ email,password }) => {
    try {
        setLoading(true);
        const dataLogin = {
          identity: email,
          password: password,
        }
        const res = await login(dataLogin)
        if(res.data.code === 200){
          notification.success({ message: res.data.message })
          dispatch({type:LOGIN, payload:res.data.data})
          findAccessRole();
          navigate('/')
        } else {
          notification.error({
                message: res.data.message || 'Đăng nhập thất bại, vui lòng thử lại',})
                setLoading(false)
        }    
    } catch (err) {
      setLoading(false)
    }
  };

  const findAccessRole = async () => {
    try {
      const res = await getListAccessRole()
      if (!res.data || res.data.code !== 200) {
        return notification.warning({ message: res.data.message })
      };
      dispatch({ type: ACCESS_ROLES, payload: res.data.data });
      // return res.data.data;
    } catch (error) {
      return notification.warning({ message: error.message })
    }
  }

 
  return (
    <section id='login' className='flex flex-col md:flex-row bg-white h-screen'>
    <div
        className="top-0 md:flex hidden  md:fixed relative w-[40vw] h-[150px] gap-3  left-0   md:bottom-0 overflow-hidden  md:h-screen flex flex-col   "
        style={{
          boxShadow:
            "0 1px 1px rgba(0, 0, 0, 0.24), 0 4px 4px rgba(0, 0, 0, 0.12)",
        }}
      >
        
        <img className="object-cover h-full w-full" src="https://avpgroup.vn/tmp/1682582985600_pexels-martin-pÃ©chy-2078271.jpg" />
        <div className="text-center w-full py-5 h-16 px-0  bg-[#2d2926] text-white absolute left-[50%] bottom-0 -translate-x-1/2">
          <h4 className="text-sm font-extrabold tracking-wider">
            JOIN ANVIETPHAT® COMPANY
          </h4>
        </div>
    </div>
      
      <div className=" grid md:ml-[40vw] h-full  w-screen md:w-[60vw] overflow-hidden bg-gray-200 py-5">
        <form className="min-w-[500px] mx-auto my-auto flex items-center justify-center md:w-3/5 md:mx-auto min-h-[420px] px-20 flex flex-col gap-0 py-10 pt-16"
          onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4 w-full flex justify-center">
            <img src="https://avpgroup.vn/tmp/1683262266659_logo test-01-min.png" />
          </div>
          <div className="mb-4 w-full max-w-[500px]">
            <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">
              Email:
            </label>
            <input
              type="email"
              {...register("email", {
                required: "Vui lòng nhập email",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Email không hợp lệ",
                },
              })}
              id="email"
              placeholder='Nhập địa chỉ email'
              className={`shadow appearance-none border rounded-3xl w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.email ? "border-red-500" : ""
              }`}
            />
            {errors.email && (
              <span className="text-red-500 text-sm">{errors.email.message}</span>
            )}
          </div>

          <div className="w-full max-w-[500px]">
            <label htmlFor="password" className="block text-gray-700 font-bold mb-2">
              Mật khẩu:
            </label>
            <input
              type="password"
              {...register("password", {
                required: "Mật khẩu không được trống",
                minLength: {
                  value: 6,
                  message: "Mật khẩu phải có ít nhất 6 ký tự",
                },
              })}
              id="password"
              placeholder='Nhập mật khẩu'
              className={`shadow appearance-none border rounded-3xl w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${
                errors.password ? "border-red-500" : ""
              }`}
            />
            {errors.password && (
              <span className="text-red-500 text-sm">{errors.password.message}</span>
            )}
          </div>

          <div className="w-full max-w-[500px]">
            <Link
              className="block leading-10 font-medium text-base hover:opacity-50 hover:brightness-110   "
              to="/forgot-password"
            >
               Quên mật khẩu?
            </Link>
          </div>
         
          <button
            type="submit"
            disabled={formState.isSubmitting}
            className="bg-yellow hover:bg-amber-200 w-full max-w-[300px] flex justify-center text-white font-bold py-2 px-4 rounded-3xl focus:outline-none focus:shadow-outline"
          >
           {loading?  <LoadingIcon className="animate-spin w-6 h-6 "></LoadingIcon> : <span>Đăng nhập</span> } 
          </button>
        </form>
        
      </div>
    </section>
  )
}


export default Login