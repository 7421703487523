import { configureStore } from '@reduxjs/toolkit'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import login from './reducers/login'
import permission from './reducers/permission'

export default configureStore({
  reducer: {
    login,
    permission,
  },
  middleware: [thunk, logger]
})