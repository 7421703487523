import { DeleteOutlined, DownloadOutlined, EnterOutlined, PictureOutlined, SaveOutlined } from '@ant-design/icons'
import { Form, Input, notification, Popconfirm, Space, Button, Row, Col, Tabs, Card, InputNumber, Select, Image } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams,useLocation } from 'react-router-dom'
import { processedSlug, translate } from '../../../helper'
import './detail.scss'
import { createEmail } from '../../../services/emailRegister'


function EmailRegisterCreate() {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const location = useLocation();
  const params = useParams();
  const submit = async () => {
    try {
      await form.validateFields();
      const { email, actived, type } = form.getFieldsValue();
      const body = {
        email,
        actived,
        type
      };
      
      body.type = location?.pathname === '/email-recruitment/create' ? 'RECRUITMENT' : 'REGISTER'
      if (!params.id) {
        const res = await createEmail(body)
        if (!res.data || res.data.code !== 200) {
          return notification.warning({
            message: res.data.message || 'Tạo email thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
      }
      navigate(location?.pathname.split('/create')[0])
    } catch (err) {
      if (err.errorFields?.length > 0) {
        return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
      }
      console.log(err)
      notification.error({ message: err.message })
    } finally {
    }
  }

  const ContentTab = ({ langCode }) => {
    return (
      <>
        <div className='flex gap-[16px]'>
          <Form.Item
            name='email'
            label='Email'
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Vui lòng nhập Email'
              },
            ]}
            className='w-2/3'
          >
            <Input size='large' placeholder={translate("Nhập email", langCode)} />
          </Form.Item>
          <Form.Item
                name="actived"
                label="Trạng thái"
                rules={[{ required: true, }]}
                className='w-2/3'
              >
                <Select size='large' placeholder="Chọn trạng thái">
                  <Select.Option value={1}>Hoạt động</Select.Option>
                  <Select.Option value={0}>Hủy hoạt động</Select.Option>
                </Select>
          </Form.Item>
        </div>
      </>
    )
  }

  const TABS = [
    {
      label: 'Thông tin',
      key: 'vi',
      children: <ContentTab langCode="vi"></ContentTab>
    }
  ]
  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
    >
      <Row className='mt-4' gutter={16}>
        <Col span={16}>
          <Tabs
            type="card"
            defaultActiveKey="2"
            items={TABS}
          ></Tabs>
        </Col>
        <Col span={8} className="mt-6">
          <div className="flex justify-end items-center">
            <Space>
              <Popconfirm
                title={!params.id ? 'Xác nhận để tạo bài viết' : 'Xác nhận để chỉnh sửa bài viết'}
                okText="Yes"
                cancelText="No"
                onConfirm={() => submit()}
              >
                <Button type="primary" loading={loading}>
                  <SaveOutlined />
                  Xác nhận
                </Button>
              </Popconfirm>
              <Button danger onClick={() => navigate(location?.pathname.split('/create')[0])}>
                <EnterOutlined />
                Quay lại</Button>
            </Space>
          </div>
        </Col>
      </Row>
      
    </Form>
  )
}

export default EmailRegisterCreate