import React, { useEffect, useState } from 'react'
import moment from 'moment'

//antd
import {   DeleteFilled, EditFilled, HeartFilled, IdcardOutlined, KeyOutlined, MailOutlined, PhoneOutlined, PlusOutlined, UserAddOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons'
import {  Button, DatePicker, Form, Input, Modal, notification, Popconfirm, Radio, Select, Space, Table, Tag, Tooltip } from 'antd'

//static
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../config/pageSize'
import CreateUserModal from '../../components/Modal/ModalUser/index'
import { listGroupRole } from '../../services/permission'

//service
import {  createUser, deleteUser, detailUser, getUsers, updateUser } from '../../services/userService'
import { useSelector } from 'react-redux'
import { checkAccessRole } from '../../helper'
import { ACTION, MODULES } from '../../config/const'
const dayjs = require('dayjs')

function User() {

//state
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState({})
  const [dataForm, setDataForm] = useState({})
  const [form] = Form.useForm()
  const [paramsFilter, setParamsFilter] = useState({ page:1, amount:PAGE_SIZE })
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [permission,setPermission] = useState([])
  const roles = useSelector((state) => state.permission).data;

//action
  const onCreate = async (values) => {
    try {
      setLoading(true)
      const body = {
        password: values.password,
        code:values.code,
        username:values.username,
        email:values.email,
        firstName:values.firstName,
        lastName:values.lastName,
        phoneNumber:values.phoneNumber,
        status:values.status,
        gender:values.gender,
        addressLine1:values.addressLine1,
        birthday:moment(values.birthday).format('DD/MM/YYYY'),
        groupRoleId:values.groupRoleId
      }
      const res = await createUser(body)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message || 'Tạo người dùng thất bại!',
        })
      }
      notification.success({ message: "Tạo người dùng thành công " })
      setOpenCreateModal(false);
      _getUsers()
      setLoading(false)
    }catch (err) {
      if (err.errorFields?.length > 0) {
        return notification.warning({ message: 'Hãy thử kiểm tra lại thông tin!' })
      }
      notification.error({ message: err.message })
      setLoading(false)
    }
  };

  const onUpdate = async () => {
    try{
      await form.validateFields()
      const { phoneNumber,lastName,firstName,status, birthday, addressLine1,groupRoleId,password  } = form.getFieldValue()
      const body = {
        phoneNumber,
        groupRoleId,
        provinceId:0,
        districtId:0,
        communeId:0,
        lastName,
        firstName,
        status,
        birthday:moment(birthday).format('DD/MM/YYYY'),
        addressLine1,
      }
      body.id = dataForm.id
      if(password?.length > 0){
        body.password = password;
      }
      const res = await updateUser(body)
          if (!res.data || res.data.code !== 200) {
            return notification.warning({
              message: res.data.message || 'Chỉnh sửa người dùng bại vui lòng thử lại!',
            })
          }
          notification.success({ message: res.data.message })
      _getUsers()
      setOpenUpdateModal(false)
    }
    catch(err){
      if (err.errorFields?.length > 0) {
        return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
      }
      notification.error({ message: err.message })
    }
  }

  const onActive = async (_id) =>{
    if (!checkAccessRole(roles, MODULES.USER, ACTION.UPDATE)) {
      return notification.warning({
        message: 'Bạn không có quyền chỉnh sửa bài thông tin người dùng này',
      })
    }
    const detailData = await detailUser(_id);
      if (!detailData.data || detailData.data.code !== 200) {
        return notification.warning({
          message: detailData.data.message,
        })
      }
    const {id,lastName,firstName,phoneNumber,status} = detailData.data.data
    if (status === "ACTIVATE"){
      return notification.warning({
        message: "Người dùng này đã được kích hoạt",
      })
    }
    const body ={
      id:id,
      groupRoleId:2,
      lastName:lastName,
      firstName:firstName,
      phoneNumber:phoneNumber,
      status:"ACTIVATE",
    }
    const res = await updateUser(body)
          if (!res.data || res.data.code !== 200) {
            return notification.warning({
              message: res.data.message || 'Chỉnh sửa định mức thất bại vui lòng thử lại!',
            })
          }
          notification.success({ message: res.data.message })
      _getUsers()
  }

  // search
   let timeOut = 500
   const searchPaging = async (search = false) => {
     clearTimeout(timeOut)
     timeOut = setTimeout(() => {
       _getUsers(search.target.value)
     }, timeOut)
   }
   /* eslint-disable no-template-curly-in-string */
 const validateMessages = {
   required: '${label} không được để trống',
   types: {
     email: '${label} phải là một email!',
   }
 };
 /* eslint-enable no-template-curly-in-string */

//service
  const _getUsers = async (filter = false) => {
    try {
      setLoading(true)
      paramsFilter.search = filter ? filter : ""
      setParamsFilter(paramsFilter)
      const res = await getUsers(paramsFilter)
      if (res.status === 200) {
        setUsers(res.data.data)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  const _seenUser = async (id) => {
    try {
      setLoading(true)
      const res = await detailUser(id);
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      const {username,phoneNumber,email,code,birthday,groupRoleId,addressLine1,status,lastName,firstName,gender,description} = res.data.data
      let dataform = {
        id :id ,
        username,
        phoneNumber,
        email,
        code,
        addressLine1,
        status,
        lastName,
        firstName,
        gender,
        description,
        birthday:dayjs(birthday),
        groupRoleId:groupRoleId
      }
      setDataForm(dataform)
      form.setFieldsValue(dataform)
      setLoading(false)

    } catch (err) {
      notification.error({ message: "Lấy thông tin thất bại" })
      setLoading(false)
    }
  }

  const _deleteUser = async (id) => {
    try {
      if (!checkAccessRole(roles, MODULES.USER, ACTION.DELETE)) {
        return notification.warning({
          message: 'Bạn không có quyền chỉnh sửa bài thông tin người dùng này',
        })
      }
      const res = await deleteUser({ id: id[0] })
      if (res.status === 200) {
        if (res.data.code ===200) {
          _getUsers()
          notification.success({ message: 'Hủy kích hoạt người dùng thành công' })
        } else
          notification.error({
            message: res.data.message || 'Hủy kích hoạt người dùng thất bại',
          })
      } else
        notification.error({
          message: res.data.message || 'Hủy kích hoạt người dùng thất bại',
        })
    } catch (err) {
      console.log(err)
    }
  }

//effect
  useEffect(() => {
    _getUsers()
  }, [paramsFilter]);

  useEffect(() => {
    listPermission()
  }, []);


//permission
  const listPermission = async () => {
    try {
      setLoading(true)
         const res = await listGroupRole()
      if (!res.data || res.data.code !== 200) {
        return notification.warning({ message: res.data.message })
      }
      setPermission(res.data.data.map((item) => {
        return {
          label:item.name,
          value:item.id
    }}))
    } catch (error) {
      notification.error({ message: error.message })
    } finally {
      setLoading(false)
    }
  }

  const columnsUsers = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      align: 'center',
      key:'index'
    },
    {
        title: 'Tên người dùng',
        align: 'center',
        dataIndex: 'username',
        key: 'username',
        render: (text) => text,
    },
    {
        title: 'Email',
        align: 'center',
        dataIndex: 'email',
        key: 'email',
        render: (text) => text,
    },
    {
        title: 'Số điện thoại',
        align: 'center',
        dataIndex: 'phoneNumber',
        key: 'phoneNumber',
        render: (text) => text,
    },
    {
        title: 'Ngày sinh',
        align: 'center',
        dataIndex: 'birthday',
        key: 'birthday',
        render: (text) => text && moment(text).format('YYYY-MM-DD'),
    },
    {
        title: 'Trạng thái',
        align: 'center',
        dataIndex: 'status',
        key: 'status',
        render: (text,record) =>{
          return (
            <Tag color={ text ==="ACTIVATE" ? 'success' : 'error'}   >
                Kích hoạt
            </Tag>
          )    
        }
    },
    {
      title: 'Ngày tạo',
      align: 'center',
      key: 'createdAt',
      dataIndex: 'createdAt',
      render: (text) => text && moment(text).format('YYYY-MM-DD hh:mm'),
    },
    {
      title: 'Hành động',
      align: 'center',
      render: (text, record) => {
          return (
            <Space>
            <Tooltip placement="top" title={'Sửa'}>
              <Button onClick={()=> {_seenUser(record.id); setOpenUpdateModal(true);} } className='px-2 '>
               <EditFilled ></EditFilled>
              </Button>
            </Tooltip>
            
            {record.status === "ACTIVATE" ?
             <Popconfirm
             title="Bạn muốn hủy kích hoạt người dùng này?"
             okText="Có"
             cancelText="Không"
             onConfirm={() => _deleteUser([record.id])}
           >
             <Button className='px-2' danger>
                <DeleteFilled />
              </Button>
           </Popconfirm>
            :
            <Popconfirm
              title="Bạn muốn kích hoạt lại người dùng này?"
              okText="Có"
              cancelText="Không"
              onConfirm={() =>onActive([record.id])}
            >
               <Button className='px-2 text-green-500 border-green-500' >
                <HeartFilled ></HeartFilled>
                </Button>
            </Popconfirm>
            }
            
            </Space>
          )
      }
    }
]
  return (
      <>
        <div className='flex justify-between mt-2 '>
          {
            checkAccessRole(roles, MODULES.USER, ACTION.CREATE) ? <Button onClick={() => {setOpenCreateModal(true); }}  className='mt-1 lg:mb-2  flex justify-center items-center px-2' >
            <PlusOutlined></PlusOutlined>
        </Button> : ""
          }
          
            <Input.Search
            placeholder="Tìm kiếm..."
            allowClear
            onChange={searchPaging}
            className='w-52 md:w-64'
            loading={loading}
          />
        </div>
        <Table
          dataSource={users && users.list && users.list.length > 0 ? users.list : []}
          pagination={{
            position: ['bottomCenter'],
            current: paramsFilter.page,
            pageSize: paramsFilter.amount,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
            },
            total: users && users.total > 0 ? users.total : 0
            }}
            loading={loading}
            columns={columnsUsers}
            className="mt-4 lg:mt-1"
            bordered
            scroll={{ x: 640 }}
            rowKey={record => record.id}
        ></Table>
        <CreateUserModal
          open={openCreateModal}
          onCreate={onCreate}
          onCancel={() => {
            setOpenCreateModal(false);
          }}
          loading={loading}
          permission={permission}
        />
        <Modal
                open={openUpdateModal}
                title="Thông tin người dùng"
                okText="Cập nhật"
                cancelText="Hủy"
                onCancel={() => {
                  setOpenUpdateModal(false);}
                }
                loading ={loading}
                onOk={onUpdate}
                width={900}
                className="top-5"
            >
                <Form
                  form={form}
                  layout="vertical"
                  name="form_in_modal"
                  validateMessages={validateMessages}
                  className="flex  gap-5"
                >
                  <div></div>
                  <div className='w-1/2'>
                      <Form.Item
                        name="username"
                        label="Tên người dùng"
                        rules={[{ required: true, }]}
                      >
                      <Input size="large" disabled placeholder="Điền tên người dùng" prefix={<UserOutlined />} />
                      </Form.Item>
                      <Form.Item
                        name="code"
                        label="Mã nhân viên"
                        rules={[{ required: true, }]}
                      >
                      <Input size="large" disabled  placeholder="Điền mã nhân viên" prefix={<UserSwitchOutlined />} />
                      </Form.Item>
                      <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                          {
                            required: true,
                            type: 'email'
                          },
                        ]}
                      >
                      <Input size="large" disabled placeholder="Điền địa chỉ email" prefix={<MailOutlined />} />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        label="Mật khẩu"
                        rules={[{
                                  pattern:new RegExp(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/),
                                  message:"Mật khẩu phải ít nhất 8 kí tự bao gồm 1 chữ hoa, 1 kí tự đặt biệt và 1 số"
                        }]}
                      >
                      <Input.Password size="large" placeholder="Điền mật khẩu" prefix={<KeyOutlined />} />
                      </Form.Item>
                    <Form.Item name="gender">
                        <Radio.Group>
                          <Radio value={0}>Nam</Radio>
                          <Radio value={1}>Nữ</Radio>
                        </Radio.Group>
                    </Form.Item>
                  </div>
                  <div className='w-1/2'>
                    <Form.Item
                          name="lastName"
                          label="Họ"
                          rules={[{ required: true, }]}
                        >
                          <Input size="large" placeholder="Điền họ" prefix={<UserAddOutlined />} />
                    </Form.Item>
                    <Form.Item
                          name="firstName"
                          label="Tên"
                          rules={[{ required: true, }]}
                        >
                          <Input size="large" placeholder="Điền tên" prefix={<UserAddOutlined />} />
                    </Form.Item>
                    <Form.Item
                          name="addressLine1"
                          label="Địa chỉ nhà"
                          
                        >
                          <Input size="large" placeholder="Điền địa chỉ nhà" prefix={<IdcardOutlined />} />
                    </Form.Item>
                    <Form.Item
                        name="phoneNumber"
                        label="Số điện thoại"
                        rules={[{ required: true, }]}
                    >
                        <Input size="large" placeholder="Điền số điện thoại" prefix={<PhoneOutlined />} />
                        </Form.Item>
                        <Form.Item name="birthday" label="Ngày sinh" rules={[{ required: true, }]}>
                        <DatePicker    className='w-full'  placeholder='Chọn ngày sinh' size='large' />
                      </Form.Item>
                    <Form.Item
                      name="status"
                      label="Trạng thái"
                    >
                      <Select size='large'  placeholder="Chọn trạng thái">
                        <Select.Option value="ACTIVATE">Kích hoạt</Select.Option>
                        <Select.Option value="DEACTIVATE">Hủy kích hoạt</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name="groupRoleId"
                      label="Nhóm quyền"
                    >
                      <Select size='large' options={permission} placeholder="Chọn nhóm phân quyền" >                       
                      </Select>
                    </Form.Item>
                  </div>
                </Form>
        </Modal>

    </>
  )
}

export default User