import React, { useState } from 'react'
//antd
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Form, notification, Upload } from 'antd';
//service
import { createMedia } from '../../../../../services/fileService';

function UploadMedia() {
  const [fileList, setFileList] = useState([])
  const [loading, setLoading] = useState([])
     
  const props = {
    name: 'file',
    multiple: true,
    fileList,
    listType: 'picture',
    beforeUpload: () => {
     return false;
    },
    onChange({ fileList }) {
      setFileList(fileList.filter(file => file.status !== "error"));
      
    },
    onRemove(file) {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    progress: {
      strokeWidth: 5,
      showInfo: true,
    },

  };
 //action
  const submit = async () => {
    try {
      setLoading(true)
      let formData = new FormData();
      fileList.forEach(item  =>{
        formData.append('files', item.originFileObj)
      })
      const res = await createMedia(formData);
      if (!res || res.code !== 200) {
        return notification.warning({
          message: res.message,
        })
      }
      setFileList([])
      notification.success({ message: res.message })
      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Form onFinish={submit}>  
        <Upload.Dragger {...props}     >
            <p className="ant-upload-drag-icon">
            <InboxOutlined />
            </p>
            <p className="ant-upload-text">Kéo và thả file đến thư mục này để tải media</p>
            <p className="ant-upload-hint">
            Hỗ trợ tải lên một lần hoặc hàng loạt. Nghiêm cấm tải lên dữ liệu mật công ty hoặc dữ liệu khác
            tập tin bị cấm.
            </p>
        </Upload.Dragger>    
        <Form.Item>
          <div className='flex justify-end'>
            <Button  disabled={ fileList.length === 0 ? true : false} className='my-3  px-10' htmlType="submit" loading={loading} icon={<UploadOutlined />}> {loading ? "Uploading" : "Upload"}</Button>
          </div>
        </Form.Item>
      </Form>
    </>
  )
}

export default UploadMedia
