import { DeleteOutlined, DownloadOutlined, EditOutlined, EllipsisOutlined, EnterOutlined, SaveOutlined } from '@ant-design/icons'
import { Form, Input, notification, Popconfirm, Space, Button, Row, Col, Tabs, Select, Dropdown, Modal, Image } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createSlider, detailSlider, updateSlider } from '../../../services/sliderService'
import { ReactSortable } from "react-sortablejs";
import { v4 as uuidv4 } from 'uuid';
import './detail.scss';
import { translate } from '../../../helper'
import MediaModal from '../../../components/Modal/ModalMedia'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { ImageDefault, editorConfiguration, itemCKeditor } from '../../../config/const'
import { createCollection, detailCollection, updateCollection } from '../../../services/collectionService'

function SliderDetail() {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [dataForm, setDataForm] = useState({})
    const [loading, setLoading] = useState(false)
    const [items, setItem] = useState();
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState();
    const [isModalVisible, setModalVisible] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);

    const params = useParams();

    const _detail = async (id) => {
        try {
            setLoading(true)
            const res = await detailCollection(id)
            if (!res.data || res.data.code !== 200) {
                return notification.warning({
                    message: res.data.message,
                })
            }
            const { actived, name,collectionMedias } = res.data.data;
            const dataform = {
                actived,
                name,
                collectionMedias:collectionMedias.map(item => { return {urlImage:item.urlImage}})
            };
            setItem(dataform.collectionMedias);
            setDataForm(dataform)
            form.setFieldsValue(dataform)
        } catch (err) {
            console.log(err)
            notification.error({ message: err.message })
        } finally {
            setLoading(false)
        }
    }

    const submit = async () => {
        try {
            await form.validateFields();
            const { name, actived } = form.getFieldsValue();
            const body = {
                name,
                actived,
                collectionMedias: []
            };
            body.collectionMedias = items;
            if (!params.id) {
                const res = await createCollection(body)
                if (!res.data || res.data.code !== 200) {
                    return notification.warning({
                        message: res.data.message || 'Tạo bộ sưu tập thất bại vui lòng thử lại!',
                    })
                }
                notification.success({ message: res.data.message })
            }
            if (params.id) {
                body.id = parseInt(params.id)
                const res = await updateCollection(body)
                if (!res.data || res.data.code !== 200) {
                    return notification.warning({
                        message: res.data.message || 'Chỉnh sửa bộ sưu tập thất bại vui lòng thử lại!',
                    })
                }
                notification.success({ message: res.data.message })
            }
            navigate('/collection')
        } catch (err) {
            if (err.errorFields?.length > 0) {
                return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
            }
            console.log(err)
            notification.error({ message: err.message })
        } finally {
        }
    }

    const _setItems = (value) => {
        if (value) {
            setItem(value)
        }
    }

    const _sortItems = (value) => {
        let itemsTmp = items;
        itemsTmp = value;
        if (itemsTmp) {
            setItem([...itemsTmp])
        }
    }

    const _addItems = (value) => {
        let parse = [];
        for (let i = 0; i < value.length; i++) {
            parse.unshift({urlImage:value[i].url})
        }
        _setItems([...items,...parse])
    }

    const handleMedia = (value) => {
        setSelectedMedia(value);
        setModalVisible(true)
    };

    const deleteMedia = (index) => {
        let itemsTmp = items;
        itemsTmp.splice(index, 1)
        setItem(itemsTmp)
    }

    useEffect(() => {
        if (params.id) {
            _detail(params.id);
        }
    }, [params.id]);

    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
        >
            <Row className='mt-4' gutter={16}>
                <Col span={16}>
                    <Button type="primary" loading={loading} onClick={() => handleMedia({ key: 'mutiple', type: 'image', field: 'urlImage' })}>
                        <SaveOutlined />
                        Thêm hình ảnh
                    </Button>
                    <ReactSortable
                        className="mt-4"
                        animation={200}
                        delayOnTouchStart={true}
                        delay={2}
                        list={items ? items : []}
                        setList={(value) => _sortItems(value)}
                    >
                        {items && items.length > 0 && items.map((item, index) => (
                            <div className="p-grid flex" key={index}>
                                <div className="draggableItem function-item" >
                                    <div className="btn-group w-100">
                                        <div
                                            className="btn inner-item dropdown-toggle func-item"
                                        >
                                            <div className='flex justify-center items-center'>
                                                <div className='index-slider'>{index + 1}</div>
                                                <div className='ml-2'>
                                                    <Image src={item.urlImage ? process.env.REACT_APP_API_ENDPOINT_DEV + item.urlImage : ImageDefault} style={{ width: "50px", height: "50px" }} />
                                                </div>
                                                <div className='flex justify-center items-center ml-2'>
                                                </div>
                                            </div>
                                            <Dropdown
                                                menu={{
                                                    items: [
                                                        {
                                                            key: '1',
                                                            label: (
                                                                <div className="action" onClick={() => deleteMedia(index)}>
                                                                    <Space>
                                                                        <DeleteOutlined />
                                                                        <span>Xóa</span>
                                                                    </Space>

                                                                </div>
                                                            ),
                                                        },
                                                    ]
                                                }}
                                                placement="bottom"
                                                arrow
                                                trigger={'click'}
                                            >
                                                <EllipsisOutlined />
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </ReactSortable>
                </Col>
                <Col span={8}>
                    <div className="flex justify-end items-center">
                        <Space>
                            <Popconfirm
                                title={!params.id ? 'Xác nhận để tạo bộ sưu tập' : 'Xác nhận để chỉnh sửa bộ sưu tập'}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => submit()}
                            >
                                <Button type="primary" loading={loading}>
                                    <SaveOutlined />
                                    Xác nhận
                                </Button>
                            </Popconfirm>
                            <Button danger onClick={() => navigate('/collection')}>
                                <EnterOutlined />
                                Quay lại</Button>
                        </Space>
                    </div>
                    <Form.Item
                        name="name"
                        label={"Tên"}
                        rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
                    >
                        <Input placeholder={"Nhập tên"} />
                    </Form.Item>
                    <Form.Item
                        label="Trạng thái"
                        name="actived"
                        rules={[{ message: 'Vui lòng chọn trạng thái', required: true }]}
                    >
                        <Select
                            placeholder={'Chọn trạng thái'}
                            style={{ width: '100%' }}
                            options={[
                                {
                                    value: 1,
                                    label: 'Hiển thị',
                                },
                                {
                                    value: 0,
                                    label: 'Ẩn hiển thị',
                                },
                            ]}
                        />
                    </Form.Item>

                </Col>
            </Row>
            <MediaModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} setValue={(value) => _addItems(value)} selectedMedia={selectedMedia}></MediaModal>
        </Form>
    )
}

export default SliderDetail