import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

//icon
import user from '../../assets/icons/user.png'
import logo from '../../assets/imgs/logo__noWord.png'
import { BellIcon, ComponentsIcon, ContactIcon, DashboardIcon, DeleteIcon, FileIcon, FixIcon, HomeIcon, LogoutIcon, MenuIcon, PermissionIcon, ProductIcon, RecruitmentIcon, SearchIcon, UserIcon, PasswordIcon, IntroduceIcon, EmailIcon } from '../../assets/icons/Icons'
import { logout, refresh } from '../../services/loginService'

//ant
import { notification, Menu, Modal, Form, Input, DatePicker, Select } from 'antd'
import routes from '../../config/routesConfig'
import { ACTION, MODULES } from '../../config/const'
import PermissionMenu from '../../components/Permission/index';
import { getListAccessRole } from '../../services/permission'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment/moment'
import { updateProfile } from '../../services/userService'
import { updatePassword } from '../../services/loginService'
const dayjs = require('dayjs')

function Header() {
  const isCollapsed = localStorage.getItem('collapsed')
    ? JSON.parse(localStorage.getItem('collapsed'))
    : false;
  const user = useSelector((state) => state.login).dataUser
  const dispatch = useDispatch()
  const [form] = Form.useForm()

  const [displayInfo, setDisplayInfor] = useState(false)
  const [IsOpenSlidebar, setSlidebar] = useState(false)
  const [collapsed, setCollapsed] = useState(isCollapsed)
  const [key, setKey] = useState([])
  const [isModalProfile, setModalProfile] = useState(false)
  const [isChangePassword, setChangePassword] = useState(false)
  const [dataForm, setDataForm] = useState({})

  const navigate = useNavigate();
  const [roles, setAccessRoles] = useState([])

  const containerRef = useRef(null);
  const slideBarRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setDisplayInfor(false);
      }
      if (slideBarRef.current && !slideBarRef.current.contains(event.target)) {
        setSlidebar(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);


  const MENUS = [
    {
      path: routes.user,
      title: 'Tài khoản quản trị',
      action: ACTION.READ,
      moduleName: MODULES.USER,
      icon: <UserIcon className='h-6 w-6 text-white'></UserIcon>,
    },
    {
      path: routes.permission,
      title: 'Phân quyền',
      action: ACTION.READ,
      moduleName: MODULES.PERMISSION,
      icon: <PermissionIcon className='h-6 w-6 text-white'></PermissionIcon>,
    },
    {
      path: routes.areaFactory,
      title: 'Nhà máy',
      action: ACTION.READ,
      moduleName: MODULES.EDITOR,
      icon: <HomeIcon className='h-6 w-6 text-white'></HomeIcon>,
      menuItems: [
        {
          path: routes.areaFactory,
          title: 'Khu vực',
          action: ACTION.READ,
          moduleName: MODULES.EDITOR,
          icon: <HomeIcon className='h-6 w-6 text-white'></HomeIcon>,
        },
        {
          path: routes.factory,
          title: 'Danh sách nhà máy',
          action: ACTION.READ,
          moduleName: MODULES.EDITOR,
          icon: <HomeIcon className='h-6 w-6 text-white'></HomeIcon>,
        }
      ],
    },
    {
      path: routes.introduce,
      title: 'Giới thiệu',
      action: ACTION.READ,
      moduleName: MODULES.EDITOR,
      icon: <IntroduceIcon className='h-6 w-6 text-white'></IntroduceIcon>
    },
    {
      path: routes.product,
      title: 'Sản phẩm',
      action: ACTION.READ,
      moduleName: MODULES.EDITOR,
      icon: <ProductIcon className='h-6 w-6 text-white'></ProductIcon>,
      menuItems: [
        {
          path: routes.product,
          title: 'Danh sách sản phẩm',
          action: ACTION.READ,
          moduleName: MODULES.EDITOR,
          icon: <HomeIcon className='h-6 w-6 text-white'></HomeIcon>,
        },
        {
          path: routes.categoryProduct,
          title: 'Danh mục',
          action: ACTION.READ,
          moduleName: MODULES.EDITOR,
          icon: <HomeIcon className='h-6 w-6 text-white'></HomeIcon>,
        }
      ],
    },
    {
      path: routes.recruitment,
      title: 'Tuyển dụng',
      action: ACTION.READ,
      moduleName: MODULES.RECRUITMENT,
      icon: <RecruitmentIcon className='h-6 w-6 text-white'></RecruitmentIcon>,
      menuItems: [
        {
          path: routes.recruitment,
          title: 'Vị trí tuyển dụng',
          action: ACTION.READ,
          moduleName: MODULES.RECRUITMENT,
          icon: <HomeIcon className='h-6 w-6 text-white'></HomeIcon>,
        },
        {
          path: routes.candidate,
          title: 'Ứng viên',
          action: ACTION.READ,
          moduleName: MODULES.RECRUITMENT,
          icon: <HomeIcon className='h-6 w-6 text-white'></HomeIcon>,
        },
        {
          path: routes.emailRecruitment,
          title: 'Email nhận tin',
          action: ACTION.READ,
          moduleName: MODULES.RECRUITMENT,
          icon: <HomeIcon className='h-6 w-6 text-white'></HomeIcon>,
        }
      ],
    },
    {
      path: routes.contact,
      title: 'Khách hàng liên hệ',
      action: ACTION.READ,
      moduleName: MODULES.EDITOR,
      icon: <ContactIcon className='h-6 w-6 text-white'></ContactIcon>,
      menuItems: [
        {
          path: routes.contact,
          title: 'Danh sách liên hệ',
          action: ACTION.READ,
          moduleName: MODULES.EDITOR,
          icon: <HomeIcon className='h-6 w-6 text-white'></HomeIcon>,
        },
        {
          path: routes.emailRegister,
          title: 'Email nhận tin',
          action: ACTION.READ,
          moduleName: MODULES.RECRUITMENT,
          icon: <HomeIcon className='h-6 w-6 text-white'></HomeIcon>,
        }
      ],
    },
    {
      path: routes.statistic,
      title: 'Thống kê',
      action: ACTION.READ,
      moduleName: MODULES.EDITOR,
      icon: <DashboardIcon className='h-6 w-6 text-white'></DashboardIcon>,
    },
    {
      path: routes.menu,
      title: 'Thành phần trang',
      action: ACTION.READ,
      moduleName: MODULES.EDITOR,
      icon: <ComponentsIcon className='w-6 h-6'></ComponentsIcon>,
      menuItems: [
        {
          path: routes.menu,
          title: 'Menu',
          action: ACTION.READ,
          moduleName: MODULES.EDITOR,
        },
        {
          path: routes.banner,
          title: 'Banner',
          action: ACTION.READ,
          moduleName: MODULES.EDITOR,
        },
        {
          path: routes.slider,
          title: 'Slider',
          action: ACTION.READ,
          moduleName: MODULES.EDITOR,
        },
        {
          path: routes.page,
          title: 'Bố cục trang',
          action: ACTION.READ,
          moduleName: MODULES.EDITOR,
        },
        {
          path: routes.post,
          title: 'Bài viết',
          action: ACTION.READ,
          moduleName: MODULES.EDITOR,
        },
        {
          path: routes.category,
          title: 'Chuyên mục bài viết',
          action: ACTION.READ,
          moduleName: MODULES.EDITOR,
        },
        {
          path: routes.collection,
          title: 'Bộ sưu tập',
          action: ACTION.READ,
          moduleName: MODULES.EDITOR,
        },
        {
          path: routes.block,
          title: 'Widget',
          action: ACTION.READ,
          moduleName: MODULES.EDITOR,
        },
      ],
    },
    {
      path: routes.file,
      title: 'Quản lý tập tin',
      action: ACTION.READ,
      moduleName: MODULES.EDITOR,
      icon: <FileIcon className='h-6 w-6 text-white'></FileIcon>,
    },
    {
      path: routes.setting,
      title: 'Cấu hình',
      action: ACTION.READ,
      moduleName: MODULES.SETTING,
      icon: <FixIcon className='h-6 w-6 text-white'></FixIcon>,
    },
  ]

  const onOpenChange = (data) => {
    localStorage.setItem('key', JSON.stringify(data))
    setKey(data)
  }

  // permission
  const permissionAccessRole = async () => {
    try {
      const res = await getListAccessRole()
      if (!res.data || res.data.code !== 200) {
        return notification.warning({ message: res.data.message })
      }
      setAccessRoles(res.data.data)
    } catch (err) {
      notification.error({ message: err.message })
    }
  }
  const renderMenuItem = (_menu, index) => (
    <>
      {_menu.menuItems ? (
        <PermissionMenu key={index + _menu.path} permissions={{ roles, _menu }}>
          <Menu.SubMenu
            key={_menu.path}
            title={_menu.title}
            icon={_menu.icon}
          >
            {_menu.menuItems.map((e, index) =>
            (
              <PermissionMenu key={index + e.path} permissions={{ roles, _menu }}>
                <Menu.Item
                  key={e.path}
                >
                  <Link to={e.path} style={{ fontSize: 13 }}>
                    <span class="text-[15px] font-bold"> {e.title}</span>
                  </Link>
                </Menu.Item>
              </PermissionMenu>
            )
            )}
          </Menu.SubMenu>
        </PermissionMenu>
      ) : (
        <PermissionMenu key={index + _menu.path} permissions={{ roles, _menu }}>
          <Menu.Item
            key={_menu.path}
            style={{
              fontSize: '0.8rem',
              paddingLeft: collapsed ? 10 : '',
              paddingRight: collapsed ? 10 : '',
              margin: '15px 0',
            }}
            icon={_menu.icon}
          >
            <Link style={{ color: collapsed ? '#fff' : '', fontSize: 13 }} to={_menu.path}>
              <span class="text-[15px] font-bold"> {_menu.title}</span>
            </Link>
          </Menu.Item>
        </PermissionMenu>
      )}
    </>
  )
  //action
  function openSlidebar() {
    setSlidebar(!IsOpenSlidebar);
  }

  function display() {
    setDisplayInfor(!displayInfo);
  }

  useEffect(() => {
    permissionAccessRole()
  }, []);

  const openModal = async () => {
    try {
      const {
        code,
        username,
        email,
        lastName,
        firstName,
        phoneNumber,
        description,
        birthday,
        addressLine1,
        addressLine2,
        gender,
        avatar,
      } = user
      let dataform = {
        code,
        username,
        email,
        lastName,
        firstName,
        phoneNumber,
        description,
        birthday: dayjs(birthday),
        addressLine1,
        addressLine2,
        gender,
        avatar,
      }
      setDataForm(dataform)
      form.setFieldsValue(dataform)
      setModalProfile(true)
    } catch (err) {
      console.log(err)
      notification.error({ message: err.message })
    } finally {
    }
  }

  const getData = async () => {
    const res = await refresh()
    if (!res.data || res.data.code !== 200) {
      return notification.error({ message: res.data.message })
    }
    dispatch({ type: 'REFECT', data: res.data.data })
  }

  const ModalProfile = () => {
    const submitForm = async () => {
      try {
        await form.validateFields()
        const {
          lastName,
          firstName,
          phoneNumber,
          description,
          birthday,
          addressLine1,
          addressLine2,
          gender,
        } = form.getFieldsValue()
        const body = {
          description: !description?.length ? ' ' : description,
          lastName,
          firstName,
          phoneNumber,
          addressLine1,
          addressLine2,
          gender,
        }
        if (birthday) {
          body.birthday = moment(birthday, 'DD/MM/YYYY').format('DD/MM/YYYY')
        }
        delete body.username
        delete body.email
        delete body.code
        const res = await updateProfile(body)
        if (!res.data || res.data.code !== 200) {
          return notification.warning({
            message: res.data.message || 'Chỉnh sửa thông tin thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
        setModalProfile(false)
        getData()
      } catch (err) {
        if (err.errorFields?.length > 0) {
          return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
        }
        notification.error({ message: err.message })
      } finally {
      }
    }

    return (
      <>
        <Modal
          onOk={submitForm}
          width={'800px'}
          style={{ top: 20 }}
          okText="Xác nhận"
          cancelText="Đóng"
          open={isModalProfile}
          onCancel={() => {
            setModalProfile(false)
            form.resetFields()
          }}
          title={'Thông tin cá nhân'}
        >
          <Form form={form} layout="vertical">
            <div style={{ display: 'flex' }}>
              <div style={{ width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Item
                    label="Tên người dùng"
                    name="username"
                    rules={[{ message: 'Vui lòng nhập tên người dùng', required: true }]}
                    style={{ width: '100%' }}
                  >
                    <Input
                      onPressEnter={submitForm}
                      placeholder="Nhập tên người dùng"
                      readOnly={true}
                    />
                  </Form.Item>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ message: 'Vui lòng nhập email', required: true, type: 'email' }]}
                    style={{ width: '100%', marginLeft: 10 }}
                  >
                    <Input
                      type="email"
                      onPressEnter={submitForm}
                      placeholder="Nhập email"
                      readOnly={true}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  label="Mã nhân viên"
                  name="code"
                  rules={[{ message: 'Vui lòng nhập mã nhân viên', required: true }]}
                >
                  <Input
                    onPressEnter={submitForm}
                    placeholder="Nhập mã nhân viên"
                    readOnly={true}
                  />
                </Form.Item>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Form.Item
                    style={{ width: '100%' }}
                    label="Họ"
                    name="firstName"
                    rules={[{ message: 'Vui lòng nhập', required: true, email: true }]}
                  >
                    <Input onPressEnter={submitForm} placeholder="Nhập họ" />
                  </Form.Item>
                  <Form.Item
                    label="Tên"
                    name="lastName"
                    rules={[{ message: 'Vui lòng tên', required: true, email: true }]}
                    style={{ width: '100%', marginLeft: 10 }}
                  >
                    <Input onPressEnter={submitForm} placeholder="Nhập tên" />
                  </Form.Item>
                </div>
                <div style={{ display: 'flex' }}>
                  <Form.Item
                    label="Ngày sinh"
                    name="birthday"
                    rules={[{ message: 'Vui lòng chọn ngày sinh', required: true }]}
                    style={{ width: '100%' }}
                  >
                    <DatePicker format={'DD/MM/YYYY'}  style={{ width: '100%' }}/>
                  </Form.Item>
                  <Form.Item
                    label="Giới tính"
                    name="gender"
                    rules={[{ message: 'Vui lòng chọn giới tính', required: true }]}
                    style={{ width: '100%', marginLeft: 10 }}
                  >
                    <Select
                      style={{ width: '100%' }}
                      placeholder="Chọn giới tính"
                      options={[
                        {
                          value: 0,
                          label: 'Nam',
                        },
                        {
                          value: 1,
                          label: 'Nữ',
                        },
                      ]}
                    />
                  </Form.Item>
                </div>
                <Form.Item
                  label="Số điện thoại"
                  name="phoneNumber"
                  rules={[{ message: 'Vui lòng nhập số điện thoại', required: true }]}
                >
                  <Input onPressEnter={submitForm} placeholder="Nhập số điện thoại" />
                </Form.Item>
                <Form.Item
                  label="Địa chỉ"
                  name="addressLine1"
                  rules={[{ message: 'Vui lòng nhập địa chỉ', required: true }]}
                >
                  <Input onPressEnter={submitForm} placeholder="Nhập nhập địa chỉ" />
                </Form.Item>
                <Form.Item label="Mô tả" name="description" rules={[{ required: false }]}>
                  <Input.TextArea showCount maxLength={300} autoSize={{ minRows: 3, maxRows: 5 }} />
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>
      </>
    )
  }
  const ModalChangePassword = () => {
    const submitForm = async () => {
      try {
        await form.validateFields()
        const {
          oldPassword,
          newPassword
        } = form.getFieldsValue()
        const body = {
          "passwordOld":oldPassword,
          "passwordNew":newPassword
        }
        const res = await updatePassword(body)
        if (!res.data || res.data.code !== 200) {
          res.data.error?.length && res.data.error.forEach((item) => {
            if(item.key === "passwordNew") {
              return notification.warning({
                message: item.message || 'Mật khẩu phải ít nhất 8 kí tự bao gồm 1 chữ hoa, 1 kí tự đặt biệt và 1 số',
              })
            } else if (item.key === "passwordOld") {
              return notification.warning({
                message: item.message || 'Vui lòng kiểm tra lại mật khẩu cũ',
              })
            } else {
              return notification.warning({
                message: item.message || 'Vui lòng kiểm tra lại mật khẩu',
              })
            }
          }); 
          return notification.warning({
            message: res.data.message || 'Thay đổi mật thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
        setChangePassword(false)
        getData()
      } catch (err) {
        if (err.errorFields?.length > 0) {
          return notification.warning({ message: 'Vui lòng kiểm tra lại mật khẩu' })
        }
        notification.error({ message: err.message })
      } finally {
      }
    }

    return (
      <>
        <Modal
          onOk={submitForm}
          width={'400px'}
          style={{ top: 20 }}
          okText="Xác nhận"
          cancelText="Đóng"
          open={isChangePassword}
          onCancel={() => {
            setChangePassword(false)
            form.resetFields()
          }}
          title={'Thay đổi mật khẩu'}
        >
          <Form form={form} layout="vertical">
            <div style={{ display: 'flex' }}>
              <div style={{ width: '100%' }}>
                
                <Form.Item
                  label="Mật khẩu cũ"
                  name="oldPassword"
                  rules={[{ required: true,
                    pattern:new RegExp(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/),
                    message:"Mật khẩu phải ít nhất 8 kí tự bao gồm 1 chữ hoa, 1 kí tự đặt biệt và 1 số"
                  }]}
                >
                   <Input.Password placeholder="Nhập mật khẩu cũ" />
                </Form.Item>
                <Form.Item
                  label="Mật khẩu mới"
                  name="newPassword"
                  rules={[{ required: true,
                    pattern:new RegExp(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/),
                    message:"Mật khẩu phải ít nhất 8 kí tự bao gồm 1 chữ hoa, 1 kí tự đặt biệt và 1 số"
                  }]}
                >
                   <Input.Password placeholder="Nhập mật khẩu mới" />
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>
      </>
    )
  }
  //service
  function _logOut() {
    logout().then((res) => {
      if (res.data && res.data.code === 200) {
        notification.success({ message: res.data.message })
        localStorage.removeItem('accessToken')
        navigate("/login")

      } else {
        notification.error({ message: res.data.message })
      }
    })
      .catch((error) => console.log(error))
  }

  return (
    <>
      <header className='h-16 m-2 rounded-md fixed md:relative bg-white top-0 left-0   drop-shadow-md flex justify-between items-center px-4  z-40 right-0 md:ml-[310px]'>
        <div className='flex gap-2'>
          <div onClick={openSlidebar} className='bg-light px-1.5 py-1.5  w-9 h-9 md:hidden rounded-full hover:bg-slate-300 cursor-pointer transition duration-300 ease'>
            <MenuIcon className='w-6 h-6'></MenuIcon>
          </div>
          <Link to="/" className='bg-light px-1.5 py-1.5  w-9 h-9 rounded-full hover:bg-slate-300 cursor-pointer transition duration-300 ease'>
            <HomeIcon className='h-6 w-6 '></HomeIcon>
          </Link>
        </div>
        <div className='flex gap-2'>
          <div onClick={display} ref={containerRef} className='cursor-pointer hover:brightness-110 group relative'>
            <div className='flex items-center font-semibold opacity-75'>{user.firstName + ' ' + user.lastName}</div>
            <ul className={` ${displayInfo ? "" : "hidden"} absolute top-[120%]     right-1 rounded-lg bg-white w-60 p-1 shadow-xl after:content-[""] after:h-8 after:absolute after:-top-5 after:right-0 after:w-3/4 after:block '`} style={{ boxShadow: "0 0px 5px rgba(0, 0, 0, 0.2)", animation: "headerGrowth ease-in 0.4s", transformOrigin: 'calc(100% - 32px) top', willChange: 'opacity, transform' }} >
              <li className='flex justify-start items-center  gap-2 hover:bg-zinc-300' onClick={() => openModal()}>
                <div className='ml-2'>
                  <UserIcon className='h-6 w-6 '></UserIcon>
                </div>
                <span className='leading-10'>Thông tin tài khoản</span>
              </li>
              <li className='flex justify-start items-center  gap-2 hover:bg-zinc-300' onClick={() => setChangePassword(true)}>
                <div className='ml-2'>
                  <PasswordIcon className='h-6 w-6 '/>
                </div>
                <span className='leading-10'>Đổi mật khẩu</span>
              </li>
              <li>
                <Link className='flex  justify-start items-center gap-2 hover:bg-zinc-300' onClick={_logOut}>
                  <div className='ml-2'>
                    <LogoutIcon className=' h-6 w-6'></LogoutIcon>
                  </div>
                  <span className='leading-10'  >Đăng xuất</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <div ref={slideBarRef} className={`${IsOpenSlidebar ? "" : "hidden md:block"} z-50 fixed overflow-y-hidden   top-0 bottom-0 lg:left-0 p-2 w-[300px]  text-center bg-gray-900`} >
        <div className="text-gray-100 text-xl">
          <div className="p-2.5 mt-1 flex items-center justify-between">
            <div className='flex'>
              <div className='h-20 -mt-4 -ml-12 -mb-8'>
                <img className='w-full h-full object-contain' src={logo} alt='An Viet Phat'></img>
              </div>
              <h1 className="font-bold text-gray-200 -ml-10 text-[15px] ">An Việt Phát</h1>
            </div>
            <div onClick={openSlidebar} className="block md:hidden cursor-pointer hover:bg-gray-700 rounded-full p-0.5" >
              <DeleteIcon className='w-5 h-5 text-white cursor-pointer '></DeleteIcon>
            </div>
          </div>
          <div className="my-2 bg-gray-600 h-[1px]"></div>
        </div>
        <div>
          <Menu
            className='main-menu'
            theme="light"
            style={{
              height: `calc(100vh - 70px)`,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
            onOpenChange={(openKeys) => onOpenChange(openKeys)}
            openKeys={
              key
                ? key.map((values, index) => {
                  if (index === key.length - 1) {
                    return values
                  }
                })
                : ''
            }
            mode="inline"
          >
            {MENUS.map(renderMenuItem)}
            <div className="my-4 bg-gray-600 h-[1px]"></div>
            <Menu.Item
              onClick={_logOut}
              icon={<LogoutIcon className='h-6 w-6 text-white'></LogoutIcon>}
              style={{ paddingLeft: collapsed ? 10 : 16 }}
            >
              <span class="text-[15px] ml-4 text-gray-200 font-bold">  Đăng xuất</span>
            </Menu.Item>
          </Menu>
        </div>
      </div>
      <ModalProfile></ModalProfile>
      <ModalChangePassword/>
    </>
  )
}

export default Header