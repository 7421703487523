import routes from "../config/routesConfig";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import NotFound from "../pages/NotFound/NotFound";
import Permission from "../pages/Permission/Permission";
import User from "../pages/User/User";
import PageLayout from "../pages/PageLayout";
import BodyLayout from "../pages/PageLayout/Body";
import DetailLayout from "../pages/PageLayout/DetailPage";
import Blog from "../pages/Blog";
import BlogDetail from "../pages/Blog/Detail";
import Banner from "../pages/Banner/index";
import DetailBanner from "../pages/Banner/Detail/index";
import Category from "../pages/Category";
import CategoryDetail from "../pages/Category/Detail";
import File from "../pages/File";
import Setting from "../pages/Setting";
import Block from "../pages/Block";
import BlockDetail from "../pages/Block/Detail";
import Menu from "../pages/Menu";
import MenuDetail from "../pages/Menu/Detail";
import Slider from "../pages/Slider";
import SliderDetail from "../pages/Slider/Detail";
import Area from "../pages/AreaFactory";
import AreaDetail from "../pages/AreaFactory/detail/index";
import Factory from "../pages/Factory";
import FactoryDetail from "../pages/Factory/detail/index";
import Recruitment from "../pages/Recruitment";
import RecruitmentDetail from "../pages/Recruitment/Detail/index";
import Contact from "../pages/Contact";
import ContactDetail from "../pages/Contact/Detail/index";
import Candidate from "../pages/Candidate";
import Statistics from "../pages/Statistics";
import StatisticDetail from "../pages/Statistics/Detail/index";
import Product from "../pages/Product";
import ProductDetail from "../pages/Product/Detail/index";
import CategoryProduct from "../pages/CategoryProduct";
import CategoryProductDetail from "../pages/CategoryProduct/Detail/index";
import Collection from "../pages/Collection";
import CollectionDetail from "../pages/Collection/Detail/index";
import ForgotPassword from "../pages/ForgotPassword/ForgotPassword";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import Introduce from "../pages/Introduce";
import IntroduceDetail from "../pages/Introduce/Detail";
import EmailRegister from "../pages/EmailRegister";
import EmailRegisterCreate from "../pages/EmailRegister/Detail";

const privateRoutes = [
    {path: routes.home, component:Home},
    //User
    {path:routes.user, component: User},
    //Permission
    {path:routes.permission, component: Permission},
    //page
    {path:routes.page, component: PageLayout},
    {path:routes.pageCreate, component: DetailLayout},
    {path:routes.pageDetail, component: DetailLayout},
    {path:routes.body, component: BodyLayout},
    //post
    {path:routes.post, component: Blog},
    {path:routes.postUpdate, component: BlogDetail},
    {path:routes.postCreate, component: BlogDetail},
    //introduce
    {path:routes.introduce, component: Introduce},
    {path:routes.introduceCreate, component: IntroduceDetail},
    {path:routes.introduceUpdate, component: IntroduceDetail},
    //banner
    {path:routes.banner,component:Banner},
    {path:routes.bannerCreate ,component:DetailBanner},
    {path:routes.banerUpdate ,component:DetailBanner},
    //category
    {path:routes.category, component: Category},
    {path:routes.categoryCreate, component: CategoryDetail},
    {path:routes.categoryUpdate, component: CategoryDetail},
    //file
    {path:routes.file,component:File},
    //setting
    {path:routes.setting,component:Setting},
    //Block
    {path:routes.block, component: Block},
    {path:routes.blockCreate, component: BlockDetail},
    {path:routes.blockUpdate, component: BlockDetail},
    //Menu
    {path:routes.menu, component: Menu},
    {path:routes.menuCreate, component: MenuDetail},
    {path:routes.menuUpdate, component: MenuDetail},
    //Slider
    {path:routes.slider, component: Slider},
    {path:routes.sliderCreate, component: SliderDetail},
    {path:routes.sliderUpdate, component: SliderDetail},
    //Area
    {path:routes.areaFactory, component: Area},
    {path:routes.areaFactoryCreate, component: AreaDetail},
    {path:routes.areaFactoryUpdate, component: AreaDetail},
    //Factory
    {path:routes.factory, component: Factory},
    {path:routes.factoryCreate, component: FactoryDetail},
    {path:routes.factoryyUpdate, component: FactoryDetail},
    //Recruitment
    {path:routes.recruitment, component: Recruitment},
    {path:routes.recruitmentCreate, component: RecruitmentDetail},
    {path:routes.recruitmentUpdate, component: RecruitmentDetail},
    //Contact
    {path:routes.contact, component: Contact},
    {path:routes.contactUpdate, component: ContactDetail},
    //Candidate
    {path:routes.candidate, component: Candidate},
    //Statistic
    {path:routes.statistic, component: Statistics},
    {path:routes.statisticCreate, component: StatisticDetail},
    {path:routes.statisticUpdate, component: StatisticDetail},
    //Product
    {path:routes.product, component: Product},
    {path:routes.productCreate, component: ProductDetail},
    {path:routes.productUpdate, component: ProductDetail},
    //Product
    {path:routes.categoryProduct, component: CategoryProduct},
    {path:routes.categoryProductCreate, component: CategoryProductDetail},
    {path:routes.categoryProductUpdate, component: CategoryProductDetail},
     //Collection
     {path:routes.collection, component: Collection},
     {path:routes.collectionCreate, component: CollectionDetail},
     {path:routes.collectionUpdate, component: CollectionDetail},
     //Email
     {path:routes.emailRecruitment, component: EmailRegister},
     {path:routes.emailRecruitmentUpdate, component: EmailRegisterCreate},
     {path:routes.emailRegister, component: EmailRegister},
     {path:routes.emailRegisterUpdate, component: EmailRegisterCreate},
];

const publicRoutes = [
    {path:routes.login, component:Login},
    {path:routes.resetPassword, component:ResetPassword},
    {path:routes.forgotPassword, component:ForgotPassword},
    {path:routes.notFound, component:NotFound}
]

const breadcrumbNameMap = {
    '/':'Trang chủ',
    '/introduce':'Giới thiệu',
    '/introduce/create':'Tạo danh sách cột mốc',
    '/introduce/update':'Chỉnh sửa danh sách cột mốc',
    '/user':'Quản lí người dùng',
    '/permission':'Quản lí phân quyền',
    '/page':'Danh sách trang',
    '/page/body':'Bố cục trang',
    '/page/detail':'Chi tiết trang',
    '/page/create':'Tạo trang',
    '/post':'Quản lý bài viết',
    '/post/update':'Chỉnh sửa',
    '/post/create':'Tạo',
    '/banner':'Banner',
    '/banner/create':'Tạo',
    '/banner/update':'Chỉnh sửa',
    '/category':'Chuyên mục',
    '/category/update':'Chỉnh sửa',
    '/category/create':'Tạo',
    '/file':'Quản lí tập tin',
    '/setting':'Cấu hình',
    '/block':'Quản lý block',
    '/block/update':'Chỉnh sửa',
    '/block/create':'Tạo',
    '/menu':'Quản lý menu',
    '/menu/update':'Chỉnh sửa',
    '/menu/create':'Tạo',
    '/slider':'Quản lý slider',
    '/slider/update':'Chỉnh sửa',
    '/slider/create':'Tạo',
    '/area':'Quản lý khu vực nhà máy',
    '/area/update':'Chỉnh sửa',
    '/area/create':'Tạo',
    '/factory':'Quản lý nhà máy',
    '/factory/update':'Chỉnh sửa',
    '/factory/create':'Tạo',
    '/recruitment':'Tuyển dụng',
    '/recruitment/update':'Chỉnh sửa',
    '/recruitment/create':'Tạo',
    '/contact':'Quản lý',
    '/contact/update':'Xem',
    '/candidate':'Quản lý ứng viên',
    '/statistic':'Quản lý thông kê',
    '/statistic/update':'Chỉnh sửa',
    '/statistic/create':'Tạo',
    '/product':'Quản lý sản phẩm',
    '/product/update':'Chỉnh sửa',
    '/product/create':'Tạo',
    '/category-product':'Quản lý danh mục',
    '/category-product/update':'Chỉnh sửa',
    '/category-product/create':'Tạo',
    '/collection':'Quản lý bộ sưu tập',
    '/collection/create':'Tạo',
    '/collection/update':'Chỉnh sửa',
    '/email-register':'Quản lý khách hàng',
    '/email-register/create':'Thêm',
    '/email-recruitment':'Quản lý tuyển dụng',
    '/email-recruitment/create':'Thêm',
}

export {privateRoutes,publicRoutes, breadcrumbNameMap}




