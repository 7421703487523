import { destroy, get, getOne, post, put } from "../apis/httpClient";

export const listAllRole=()=>get('/api/v1/permission/listAll')
export const listGroupRole=()=>get('/api/v1/permission/listGroup')
export const pagingGroupRole=(params)=>get('/api/v1/permission/pagingGroup',params)
export const detailRole=(query)=>getOne(`/api/v1/permission/detailGroup/${query}`)
export const updateRole=(body)=>put(`/api/v1/permission/updateGroup`,body)
export const createRole=(body)=>post(`/api/v1/permission/createGroup`,body)
export const deleteRole=(body)=>destroy(`/api/v1/permission/deleteGroup`,body)
export const getListAccessRole=()=>get(`/api/v1/permission/listAccessRole`)
