import { DownloadOutlined, EnterOutlined, SaveOutlined } from '@ant-design/icons'
import { Form, Input, notification, Popconfirm, Space, Button, Row, Col, Tabs,Select, Image, InputNumber } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { translate } from '../../../helper'
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { createFactory, detailFactory, updateFactory } from '../../../services/factoryService'
import MediaModal from '../../../components/Modal/ModalMedia'
import { listArea } from '../../../services/areaService'
import { ImageDefault, editorConfiguration } from '../../../config/const'

function FactoryDetail() {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [dataForm, setDataForm] = useState({})
  const [loading, setLoading] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [urlImage, setUrlImage] = useState("");
  const [categorys, setCategorys] = useState([])

  const params = useParams();
  const _detail = async (id) => {
    try {
      setLoading(true)
      const res = await detailFactory(id)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      const { actived, name, factoryLangs, createdAt,ordering,areaFactoryId,urlImage } = res.data.data;
      const dataform = {
        actived,
        name,
        createdAt,
        ordering,
        areaFactoryId,
        urlImage,
        factoryLangs: {}
      }
      setUrlImage(urlImage);
      for (let i = 0; i < factoryLangs.length; i++) {
        const { langCode } = factoryLangs[i];
        dataform.factoryLangs[langCode] = factoryLangs[i];
      }
      setDataForm(dataform)
      form.setFieldsValue(dataform)
    } catch (err) {
      console.log(err)
      notification.error({ message: err.message })
    } finally {
      setLoading(false)
    }
  }

  const submit = async () => {
    try {
      await form.validateFields();
      const { name, factoryLangs, actived,ordering,areaFactoryId } = form.getFieldsValue();
      const body = {
        name,
        actived,
        ordering,
        areaFactoryId,
        urlImage:'',
        factoryLangs: []
      };
      let isCheckTab = true;
      ['en', 'vi'].forEach(item => {
        if (!factoryLangs[item]) {
          isCheckTab = false
        }
      });
      if(!params.id && !isCheckTab){
        return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
      }
      if(urlImage){
        body.urlImage = urlImage;
      }
      for (const item in factoryLangs) {
        factoryLangs[item].langCode = item;
        factoryLangs[item].urlLink = factoryLangs['vi'].urlLink;
        if (params.id) { // type Update
            factoryLangs[item].id = dataForm.factoryLangs[item].id;
        }
        body.factoryLangs.push(factoryLangs[item])
      }
      if (!params.id) {
        const res = await createFactory(body)
        if (!res.data || res.data.code !== 200) {
          return notification.warning({
            message: res.data.message || 'Tạo nhà máy thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
      }
      if (params.id) {
        body.id = parseInt(params.id)
        const res = await updateFactory(body)
        if (!res.data || res.data.code !== 200) {
          return notification.warning({
            message: res.data.message || 'Chỉnh sửa nhà máy thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
      }
      navigate('/factory')
    } catch (err) {
      if (err.errorFields?.length > 0) {
        return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
      }
      console.log(err)
      notification.error({ message: err.message })
    } finally {
    }
  }

  const handleMedia = (value) => {
    setSelectedMedia(value);
    setModalVisible(true)
  };

  const handleValueMedia = (value) => {
    if(selectedMedia.field  === 'urlImage'){
      setUrlImage(value[0].url)
    }
  };

   // getArea
   const getArea = async () => {
    try {
      const res = await listArea()
      if (!res.data || res.data.code !== 200) {
        return notification.warning({ message: res.data.message })
      }
      setCategorys(
        res.data.data.list.map((item) => {
          return {
            value: item.id,
            label: item.name,
          }
        })
      )
    } catch (error) {
      notification.error({ message: error.message })
    } finally {
    }
  }

  useEffect(() => {
    if (params.id) {
      _detail(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    getArea()
  }, []);

  const ContentTab = ({ langCode }) => {
    return (
      <>
        <div>
          <Form.Item
            name={['factoryLangs', langCode, 'heading']}
            label={translate("Tiêu đề", langCode)}
            rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}
          >
            <Input placeholder={translate("Nhập tiêu đề", langCode)} />
          </Form.Item>
          <Form.Item
            name={['factoryLangs', langCode, 'subHeading']}
            label={translate("Phụ đề", langCode)}
          >
            <Input placeholder={translate("Nhập phụ đề", langCode)} />
          </Form.Item>
          <Form.Item
            name={['factoryLangs', 'vi', 'urlLink']}
            label={translate("Đường dẫn", langCode)}
          >
            <Input placeholder={translate("Nhập đường dẫn", langCode)} />
          </Form.Item>
          <Form.Item
            name={['factoryLangs', langCode, 'description']}
            label={translate("Mô tả", langCode)}
            rules={[{ required: false }]}
          >
            <CKEditor
              editor={Editor}
              config={ editorConfiguration }
              data={params && params.id && dataForm.factoryLangs && dataForm.factoryLangs[langCode] ? dataForm.factoryLangs[langCode].description : ''}
              onChange={(event, editor) => {
                const data = editor.getData();
                form.setFieldValue(['factoryLangs', langCode, 'description'], data);
              }}
            />
          </Form.Item>
        </div>
      </>
    )
  }

  const TABS = [
    {
      label: 'Tiếng Việt',
      key: 'vi',
      children: <ContentTab langCode="vi"></ContentTab>
    },
    {
      label: 'Tiếng Anh',
      key: 'en',
      children: <ContentTab langCode="en"></ContentTab>
    }
  ]
  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
    >
      <Row className='mt-4' gutter={16}>
        <Col span={16}>
          <Tabs
            type="card"
            defaultActiveKey="2"
            items={TABS}
          />
        </Col>
        <Col span={8} className="mt-6">
          <div className="flex justify-end items-center">
            <Space>
              <Popconfirm
                title={!params.id ? 'Xác nhận để tạo bài viết' : 'Xác nhận để chỉnh sửa bài viết'}
                okText="Yes"
                cancelText="No"
                onConfirm={() => submit()}
              >
                <Button type="primary" loading={loading}>
                  <SaveOutlined />
                  Xác nhận
                </Button>
              </Popconfirm>
              <Button danger onClick={() => navigate('/factory')}>
                <EnterOutlined />
                Quay lại</Button>
            </Space>
          </div>
          <Form.Item
            name={'urlImage'}
            label={"Hình đại diện"}
          >
            <Image
              width={'100%'}
              height={'100%'}
              src={urlImage  ? process.env.REACT_APP_API_ENDPOINT_DEV + urlImage : ImageDefault }
            />
            <Button onClick={() => handleMedia({ key: 'single', type: 'image', field: 'urlImage' })} icon={<DownloadOutlined />} >
              Upload
            </Button>
          </Form.Item>
          <Form.Item
            name="name"
            label={"Tên"}
            rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
          >
            <Input placeholder={"Nhập tên"} />
          </Form.Item>
          <Form.Item
            name="ordering"
            label={"Sắp xếp"}
            style={{ width: "100%" }}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            label="Trạng thái"
            name="actived"
            rules={[{ message: 'Vui lòng chọn trạng thái', required: true }]}
          >
            <Select
              placeholder={'Chọn trạng thái'}
              style={{ width: '100%' }}
              options={[
                {
                  value: 1,
                  label: 'Hiển thị',
                },
                {
                  value: 0,
                  label: 'Ẩn hiển thị',
                },
              ]}
            />
          </Form.Item> 
          <Form.Item
            label="Chọn khu vực"
            name="areaFactoryId"
            rules={[{ message: 'Vui lòng chọn khu vực', required: true }]}
          >
            <Select
              placeholder={'Chọn khu vực'}
              style={{ width: '100%' }}
              options={categorys}
            />
          </Form.Item>
        </Col>
      </Row>
      <MediaModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} setValue={(value) => handleValueMedia(value)} selectedMedia={selectedMedia}></MediaModal>
    </Form>
  )
}

export default FactoryDetail