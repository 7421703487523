import React from 'react'
import { checkAccessRole } from '../../helper'

const Permission = ({ permissions, children, ...props }) => {
  try {
    if(!checkAccessRole(permissions.roles, permissions._menu.moduleName, permissions._menu.action)){
        return null
    }
    return React.cloneElement(children, props)
  } catch (error) {
    return null
  }
}

export default Permission