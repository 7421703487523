import { createSlice } from "@reduxjs/toolkit";

const initialState = { dataUser: {},totalCandidate:0, loading: false }

export const login = createSlice({
    name:'login',
    initialState:initialState,
    reducers:{
    LOGIN: (state,action) => {
        const {user,tokens} = action.payload;
        localStorage.setItem('accessToken', tokens.token)
        return {
            ...state,
            dataUser:user
        }
    },
    LOGOUT:(state)=>{
        return {
            ...state,
            dataUser:{}
        }
    },

    REFECT:(state,action)=>{
        return{
            ...state, 
            dataUser:action.payload
        }
    },
    }
})

const { actions,reducer } = login;
export const { LOGIN,LOGOUT,REFECT } = actions

export default reducer