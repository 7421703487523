import { DownloadOutlined, SaveOutlined } from '@ant-design/icons'
import { Form, Input, notification, Popconfirm, Space, Button, Row, Col, Select, Image, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { detailSetting, updateSetting } from '../../services/settingService'
import MediaModal from '../../components/Modal/ModalMedia'
import { ImageDefault } from '../../config/const'

function BlogDetail() {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [isModalVisible, setModalVisible] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [logo, setLogo] = useState("");
    const [logoMobile, setLogoMobile] = useState("");
    const [subLogo, setSubLogo] = useState("");
    const [favicon, setFavicon] = useState("");
    const [metaImage, setMetaImage] = useState("");
    const [logoFooter, setLogoFooter] = useState("");
    const [lang, setLang] = useState("vi");

    const _getSettings = async () => {
        try {
            setLoading(true)
            const res = await detailSetting();
            if (!res.data || res.data.code !== 200) {
                return notification.warning({
                    message: res.data.message,
                })
            }
            const { logoDesk, logoMobile, subLogoDesktop, favicon,metaImage,logoFooter } = res.data.data;
            if (logoDesk) {
                setLogo(logoDesk)
            }
            if (logoMobile) {
                setLogoMobile(logoMobile)
            }
            if (subLogoDesktop) {
                console.log(subLogoDesktop)
                setSubLogo(subLogoDesktop)
            }
            if (favicon) {
                setFavicon(favicon)
            }
            if(metaImage){
                setMetaImage(metaImage)
            }
            if(logoFooter){
                setLogoFooter(logoFooter)
            }
            form.setFieldsValue(res.data.data);
            setLoading(false)
        } catch (err) {
            notification.error({ message: "Lấy thông tin thất bại" })
            setLoading(false)
        }
    }

    const submit = async () => {
        try {
            const body = form.getFieldsValue();
            if (logo) {
                body.logoDesk = logo
            }
            if (logoMobile) {
                body.logoMobile = logoMobile
            }
            if (subLogo) {
                body.subLogoDesktop = subLogo
            }
            if (favicon) {
                body.favicon = favicon
            }
            if(metaImage){
                body.metaImage = metaImage
            }
            if(logoFooter){
                body.logoFooter = logoFooter
            }
            const res = await updateSetting(body)
            if (!res.data || res.data.code !== 200) {
                return notification.warning({
                    message: res.data.message || 'Chỉnh sửa trang thất bại vui lòng thử lại!',
                })
            }
            notification.success({ message: res.data.message })
        } catch (err) {
            if (err.errorFields?.length > 0) {
                return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
            }
            notification.error({ message: err.message })
        } finally {
        }
    }

    const handleValueMedia = (value) => {
        if (selectedMedia.field === 'logo-desk') {
            setLogo(value[0].url)
        }
        if (selectedMedia.field === 'logo-mobile') {
            setLogoMobile(value[0].url)
        }
        if (selectedMedia.field === "sub-logo") {
            setSubLogo(value[0].url)
        }
        if (selectedMedia.field === "favicon") {
            setFavicon(value[0].url)
        }
        if (selectedMedia.field === "meta_image") {
            setMetaImage(value[0].url)
        }
        if (selectedMedia.field === "logoFooter") {
            setLogoFooter(value[0].url)
        }
    };

    const TABS = [
        {
          label: 'Tiếng Việt',
          key: 'vi',
        },
        {
          label: 'Tiếng Anh',
          key: 'en',
        }
      ]
    const handleMedia = (value) => {
        setSelectedMedia(value);
        setModalVisible(true)
    };

    useEffect(() => {
        _getSettings()
    }, []);

    return (
        <Form
            className='mt-4'
            form={form}
            layout="vertical"
        >
            <div className="flex justify-end items-center mb-2">
                <Space>
                    <Popconfirm
                        title={'Xác nhận để cập nhật cấu hình website'}
                        okText="Yes"
                        cancelText="No"
                        onConfirm={() => submit()}
                    >
                        <Button type="primary" loading={loading}>
                            <SaveOutlined />
                            Xác nhận
                        </Button>
                    </Popconfirm>
                </Space>
            </div>
            <Row gutter={16}>
                <Col span={12}>
                    <Tabs
                        type="card"
                        defaultActiveKey="2"
                        items={TABS}
                        onChange={(value)=>{setLang(value)}}
                    />
                    {
                        lang == 'vi' ? <>
                        <Form.Item name="title" label={"Tên website"}>
                        <Input placeholder={"Nhập tên website"} />
                    </Form.Item>
                    <Form.Item name="description" label={"Mô tả website"}>
                        <Input.TextArea placeholder={"Nhập mô tả website"} />
                    </Form.Item>
                    <Form.Item
                        label="Địa chỉ"
                        name="address"
                        className='mt-4'
                    >
                        <Input placeholder={"Địa chỉ"} />
                    </Form.Item>
                    <Form.Item
                        label="Từ khóa mặc định"
                        name="keywords"
                        className='mt-4'
                    >
                        <Input placeholder={"Từ khóa mặc định"} />
                    </Form.Item>
                   </> : <>
                    <Form.Item name="titleEn" label={"Website name"}>
                        <Input placeholder={"Enter website name"} />
                    </Form.Item>
                    <Form.Item name="descriptionEn" label={"Website description"}>
                        <Input.TextArea placeholder={"Enter website description"} />
                    </Form.Item>
                    <Form.Item
                        label="Address"
                        name="addressEn"
                        className='mt-4'
                    >
                        <Input placeholder={"Address"} />
                    </Form.Item>
                    <Form.Item
                        label="Default keyword"
                        name="keywordsEn"
                        className='mt-4'
                    >
                        <Input placeholder={"Default keyword"} />
                    </Form.Item>
                    </>
                    }
                    <div style={{borderTop: "3px solid #d2d6de"}}></div>
                    <Form.Item
                        label="Email"
                        name="email"
                        className='mt-4'
                    >
                        <Input placeholder={"Email"} />
                    </Form.Item>
                    <Form.Item
                        label="Số điện thoại"
                        name="phoneNumber"
                        className='mt-4'
                    >
                        <Input placeholder={"Số điện thoại"} />
                    </Form.Item>
                    <Form.Item
                        label="Fax"
                        name="fax"
                        className='mt-4'
                    >
                        <Input placeholder={"Fax"} />
                    </Form.Item>
                    <div style={{borderTop: "3px solid #d2d6de"}}></div>
                    <Form.Item className='mt-2' name="version" label={"Phiên bản"}>
                        <Input placeholder={"Nhập phiên bản"} />
                    </Form.Item>
                    <Form.Item
                        label="Ngôn ngữ của trang"
                        name="langDefault"
                    >
                        <Select
                            placeholder={'Chọn ngôn ngữ'}
                            style={{ width: '100%' }}
                            options={[
                                {
                                    value: 'vi',
                                    label: 'Tiếng việt',
                                },
                                {
                                    value: 'en',
                                    label: 'Tiếng anh',
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Mã Google Analytics"
                        name="GA"
                    >
                        <Input placeholder={"Dán đoạn mã hoặc mã tài khoản GA được cung cấp bởi Google."} />
                    </Form.Item>
                    <Form.Item
                        label="Mã Facebook"
                        name="facebook"
                    >
                        <Input placeholder={"Facebook Pixel ID"} />
                    </Form.Item>
                    <Form.Item
                        label="Đường dẫn facebook"
                        name="urlFb"
                    >
                        <Input placeholder={"Đường dẫn facebook"} />
                    </Form.Item>
                    <Form.Item
                        label="Đường dẫn youtube"
                        name="urlYoutube"
                    >
                        <Input placeholder={"Đường dẫn youtube"} />
                    </Form.Item>
                    <Form.Item
                                label="Meta hình ảnh mặc định (Kích thước tối thiểu 200px x 200px)"
                                name="meta_image"
                            >
                        <Image src={metaImage ? process.env.REACT_APP_API_ENDPOINT_DEV + metaImage : ImageDefault} style={{width:'200px',height:'200px',background:'rgb(230, 230, 230)'}}/>
                    </Form.Item>
                        <Button className='' onClick={() => handleMedia({ key: 'single', type: 'image', field: 'meta_image' })} icon={<DownloadOutlined />} >
                            Upload
                        </Button>
                    <Form.Item
                        label="Meta alt hình ảnh mặc định"
                        name="metaImageAlt"
                        className='mt-4'
                    >
                        <Input placeholder={"Meta alt hình ảnh mặc định"} />
                    </Form.Item>
                    
                </Col>
                <Col span={12}>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                label="Logo Desktop"
                                name="logoDesk"
                            >
                                <Image src={logo && logo.length > 0 ? process.env.REACT_APP_API_ENDPOINT_DEV + logo : ImageDefault} style={{width:'200px',height:'200px',background:'rgb(230, 230, 230)'}}/>
                            </Form.Item>
                            <Button onClick={() => handleMedia({ key: 'single', type: 'image', field: 'logo-desk' })} icon={<DownloadOutlined />} >
                                    Upload
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Logo Mobile"
                                name="logoMobile"
                            >
                                <Image src={logoMobile && logoMobile.length > 0 ? process.env.REACT_APP_API_ENDPOINT_DEV + logoMobile : ImageDefault} style={{width:'200px',height:'200px',background:'rgb(230, 230, 230)'}}/>
                            </Form.Item>
                            <Button onClick={() => handleMedia({ key: 'single', type: 'image', field: 'logo-mobile' })} icon={<DownloadOutlined />} >
                                    Upload
                            </Button>
                        </Col>
                    </Row>
                    <Row gutter={16} className='mt-4'>
                        <Col span={12}>
                            <Form.Item
                                label="Sub logo Desktop"
                                name="subLogoDesktop"
                            >
                                <Image src={subLogo && subLogo.length > 0 ? process.env.REACT_APP_API_ENDPOINT_DEV + subLogo : ImageDefault} style={{width:'200px',height:'200px',background:'rgb(230, 230, 230)'}}/>
                            </Form.Item>
                            <Button onClick={() => handleMedia({ key: 'single', type: 'image', field: 'sub-logo' })} icon={<DownloadOutlined />} >
                                    Upload
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Favicon"
                                name="favicon"
                            >
                                <Image src={favicon ? process.env.REACT_APP_API_ENDPOINT_DEV + favicon : ImageDefault} style={{width:'200px',height:'200px',background:'rgb(230, 230, 230)'}}/>
                            </Form.Item>
                            <Button className='' onClick={() => handleMedia({ key: 'single', type: 'image', field: 'favicon' })} icon={<DownloadOutlined />} >
                                    Upload
                                </Button>
                        </Col>
                    </Row>
                    <Row gutter={16} className='mt-4'>
                        <Col span={12}>
                            <Form.Item
                                label="Logo Footer"
                                name="logoFooter"
                            >
                                <Image src={logoFooter && logoFooter.length > 0 ? process.env.REACT_APP_API_ENDPOINT_DEV + logoFooter : ImageDefault} style={{width:'200px',height:'200px',background:'rgb(230, 230, 230)'}}/>
                            </Form.Item>
                            <Button onClick={() => handleMedia({ key: 'single', type: 'image', field: 'logoFooter' })} icon={<DownloadOutlined />} >
                                    Upload
                            </Button>
                        </Col>
                        <Col span={12}>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <MediaModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} setValue={(value) => handleValueMedia(value)} selectedMedia={selectedMedia}></MediaModal>
        </Form>
    )
}

export default BlogDetail