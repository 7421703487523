import { DeleteOutlined, DownloadOutlined, EnterOutlined, SaveOutlined } from '@ant-design/icons'
import { Form, Input, notification, Popconfirm, Space, Button, Row, Col, Tabs,Select, Card, DatePicker, InputNumber, Image } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { checkAccessRole, processedSlug, translate } from '../../../helper'
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { createRecruitment, detailRecruitment, updateRecruitment } from '../../../services/recruitmentService'
import { listArea } from '../../../services/areaService'
import { getProvince } from '../../../services/countryService'
import moment from 'moment'
import { ACTION, ImageDefault, MODULES, editorConfiguration } from '../../../config/const'
import MediaModal from '../../../components/Modal/ModalMedia'
import { useSelector } from 'react-redux'
const dayjs = require('dayjs')

function RecruitmentDetail() {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [dataForm, setDataForm] = useState({
    recruitmentLangs:{
      vi:[],
      en:[]
    }
  })
  const [areas, setArea] = useState([])
  const [province, setProvince] = useState([])
  const [loading, setLoading] = useState(false)
  const [hostName, setHostName] = useState('')
  const [seo, setSeo] = useState({})
  const [urlImage, setUrlImage] = useState("");
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [isModalVisible, setModalVisible] = useState(false);
  const roles = useSelector((state) => state.permission).data;

  const params = useParams();

  const _detail = async (id) => {
    try {
      setLoading(true)
      const res = await detailRecruitment(id)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      const { actived, name, recruitmentLangs, createdAt,provinceId,areaFactoryId,deadlineAt,ordering,urlImage } = res.data.data;
      const dataform = {
        actived,
        name,
        createdAt,
        provinceId,
        areaFactoryId,
        ordering,
        urlImage,
        deadlineAt:dayjs(deadlineAt),
        recruitmentLangs: {}
      }
      for (let i = 0; i < recruitmentLangs.length; i++) {
        const { langCode } = recruitmentLangs[i];
        dataform.recruitmentLangs[langCode] = recruitmentLangs[i];
      }
      setUrlImage(urlImage)
      setDataForm(dataform)
      form.setFieldsValue(dataform)
      
    } catch (err) {
      console.log(err)
      notification.error({ message: err.message })
    } finally {
      setLoading(false)
    }
  }

  const submit = async () => {
    try {
      await form.validateFields();
      const { name, recruitmentLangs, actived, provinceId,areaFactoryId,deadlineAt,ordering } = form.getFieldsValue();
      const body = {
        name,
        actived,
        provinceId,
        areaFactoryId,
        deadlineAt,
        ordering,
        recruitmentLangs: []
      };
      let isCheckTab = true;
      ['en', 'vi'].forEach(item => {
        if (!recruitmentLangs[item]) {
          isCheckTab = false
        }
      });
      if(!params.id && !isCheckTab){
        return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
      }
      for (const item in recruitmentLangs) {
        recruitmentLangs[item].langCode = item;
        recruitmentLangs[item].urlLink = recruitmentLangs['vi'].urlLink;
        if (params.id) { // type Update
            recruitmentLangs[item].id = dataForm.recruitmentLangs[item].id;
        }
        body.recruitmentLangs.push(recruitmentLangs[item])
      }
      if(urlImage && urlImage.length > 0){
        body.urlImage = urlImage
      }
      if (!params.id) {
        const res = await createRecruitment(body)
        if (!res.data || res.data.code !== 200) {
          return notification.warning({
            message: res.data.message || 'Tạo bài tuyển dụng thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
      }
      if (params.id) {
        if (!checkAccessRole(roles, MODULES.RECRUITMENT, ACTION.UPDATE)) {
          return notification.warning({
            message: 'Bạn không có quyền chỉnh sửa bài tuyển dụng này',
          })
        }
        body.id = parseInt(params.id)
        const res = await updateRecruitment(body)
        if (!res.data || res.data.code !== 200) {
          return notification.warning({
            message: res.data.message || 'Chỉnh sửa bài tuyển dụng thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
      }
      navigate('/recruitment')
    } catch (err) {
      if (err.errorFields?.length > 0) {
        return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
      }
      console.log(err)
      notification.error({ message: err.message })
    } finally {
    }
  }

  const handleMedia = (value) => {
    setSelectedMedia(value);
    setModalVisible(true)
  };

  const handleDeleteMedia = (value) => {
    if (value.field === "urlImage") {
        setUrlImage("")
    }
  };
  // getArea
  const getArea = async () => {
    try {
      const res = await listArea()
      if (!res.data || res.data.code !== 200) {
        return notification.warning({ message: res.data.message })
      }
      setArea(
        res.data.data.list.map((item) => {
          return {
            value: item.id,
            label: item.name,
          }
        })
      )
    } catch (error) {
      notification.error({ message: error.message })
    } finally {
    }
  }

  // getArea
  const _getProvince = async () => {
    try {
      const res = await getProvince();
      if (!res.data || res.data.code !== 200) {
        return notification.warning({ message: res.data.message })
      };
      setProvince(
        res.data.data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          }
        })
      )
    } catch (error) {
      notification.error({ message: error.message })
    } finally {
    }
  }

  const changeHeading = (value, langCode) => {
    form.setFieldValue(['recruitmentLangs', langCode, 'urlLink'], processedSlug(value.target.value));
  }

  const blurHeading = (value, langCode) => {
    let recruitmentLangs = {
      vi: {},
      en: {}
    }
    recruitmentLangs[langCode].heading = value.target.value;
    setSeo(recruitmentLangs)
  }
  
  useEffect(() => {
    if (params.id) {
      _detail(params.id);
    }
  }, [params.id]);

  useEffect(() => {
    setHostName(process.env.REACT_APP_DOMAIN)
    getArea()
    _getProvince()
  }, []);

  const ContentTab = ({ langCode }) => {
    return (
      <>
        <div>
          <Form.Item
            name={['recruitmentLangs', langCode, 'title']}
            label={translate("Tiêu đề", langCode)}
            rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}
          >
            <Input placeholder={translate("Nhập tiêu đề", langCode)} onInput={(value) => { changeHeading(value, langCode) }} onBlur={(value) => blurHeading(value, langCode)} />
          </Form.Item>
          <Form.Item
            name={['recruitmentLangs', langCode, 'wage']}
            label={translate("Lương", langCode)}
            rules={[{ required: true, message: 'Vui lòng nhập lương' }]}
          >
            <Input placeholder={translate("Nhập lương", langCode)} />
          </Form.Item>
          <Form.Item
            name={['recruitmentLangs', langCode, 'description']}
            label={translate("Mô tả công việc", langCode)}
            rules={[{ required: false }]}
          >
            <CKEditor
              editor={Editor}
              config={ editorConfiguration }
              data={params && params.id && dataForm.recruitmentLangs && dataForm.recruitmentLangs[langCode] ? dataForm.recruitmentLangs[langCode].description : form.getFieldValue(['recruitmentLangs', langCode, 'description'])}
              onChange={(event, editor) => {
                const data = editor.getData();
                form.setFieldValue(['recruitmentLangs', langCode, 'description'], data);
                dataForm.recruitmentLangs[langCode].description = data;
                setDataForm(dataForm)
              }}
            />
          </Form.Item>
          <Form.Item
            name={['recruitmentLangs', langCode, 'request']}
            label={translate("Yêu cầu công việc", langCode)}
            rules={[{ required: false }]}
          >
            <CKEditor
               editor={Editor}
               config={ editorConfiguration }
              data={params && params.id && dataForm.recruitmentLangs && dataForm.recruitmentLangs[langCode] ? dataForm.recruitmentLangs[langCode].request : ''}
              onChange={(event, editor) => {
                const data = editor.getData();
                form.setFieldValue(['recruitmentLangs', langCode, 'request'], data);
                dataForm.recruitmentLangs[langCode].request = data;
                setDataForm(dataForm)
              }}
            />
          </Form.Item>
          <Form.Item
            name={['recruitmentLangs', langCode, 'information']}
            label={translate("Thông tin khác", langCode)}
            rules={[{ required: false }]}
          >
            <CKEditor
               editor={Editor}
               config={ editorConfiguration }
              data={params && params.id && dataForm.recruitmentLangs && dataForm.recruitmentLangs[langCode] ? dataForm.recruitmentLangs[langCode].information : ''}
              onChange={(event, editor) => {
                const data = editor.getData();
                form.setFieldValue(['recruitmentLangs', langCode, 'information'], data);
                dataForm.recruitmentLangs[langCode].request = data;
                setDataForm(dataForm)
              }}
            />
          </Form.Item>
          <Card type="inner" title="Tối ưu SEO" bordered={false}>
          <div>
            {translate("Thiết lập các thẻ mô tả giúp khách hàng dễ dàng tìm thấy danh mục này trên công cụ tìm kiếm như Google.", langCode)}
          </div>
          <div>
            Xem trước:
            <div className='yoast-seo'>
              <div className="sc-bSqbRM dlCkVf">
                <span className="screen-reader-text" >
                  Xem trước URL:</span>
                <div className="sc-tAEdG joWiup">
                  <div className="sc-tAEdG sc-dSfclH joWiup hgXshB">
                    <div className="sc-JkiRB hHABJO">
                      <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABs0lEQVR4AWL4//8/RRjO8Iucx+noO0MWUDo16FYABMGP6ZfUcRnWtm27jVPbtm3bttuH2t3eFPcY9pLz7NxiLjCyVd87pKnHyqXyxtCs8APd0rnyxiu4qSeA3QEDrAwBDrT1s1Rc/OrjLZwqVmOSu6+Lamcpp2KKMA9PH1BYXMe1mUP5qotvXTywsOEEYHXxrY+3cqk6TMkYpNr2FeoY3KIr0RPtn9wQ2unlA+GMkRw6+9TFw4YTwDUzx/JVvARj9KaedXRO8P5B1Du2S32smzqUrcKGEyA+uAgQjKX7zf0boWHGfn71jIKj2689gxp7OAGShNcBUmLMPVjZuiKcA2vuWHHDCQxMCz629kXAIU4ApY15QwggAFbfOP9DhgBJ+nWVJ1AZAfICAj1pAlY6hCADZnveQf7bQIwzVONGJonhLIlS9gr5mFg44Xd+4S3XHoGNPdJl1INIwKyEgHckEhgTe1bGiFY9GSFBYUwLh1IkiJUbY407E7syBSFxKTszEoiE/YdrgCEayDmtaJwCI9uu8TKMuZSVfSa4BpGgzvomBR/INhLGzrqDotp01ZR8pn/1L0JN9d9XNyx0AAAAAElFTkSuQmCC' alt="" className="sc-hYQoDq fTqTJk" />
                    </div>
                    <span className="sc-hZpJuv feGpeb">
                      <div className="sc-jvvkMf gYNhjI">{hostName}
                      </div><span className="sc-cHzrye bZphhk">{window.location.origin}</span> › recruitment › {params.id} › {seo && seo.recruitmentLangs && seo.recruitmentLangs[langCode] ? seo.recruitmentLangs[langCode].title : form.getFieldValue(['recruitmentLangs', 'vi', 'urlLink'])}</span>
                  </div>
                </div>
                <span className="screen-reader-text">
                  Xem trước tiêu đề SEO:</span>
                <div className="sc-gIBoTZ gMwUgP">
                  <div className="sc-fyrnIy sc-iWVLQz dwZvnc igrcjV">
                    <span className="sc-bUbQrF fbVpha">{seo && seo.recruitmentLangs && seo.recruitmentLangs[langCode] ? seo.recruitmentLangs[langCode].title : form.getFieldValue(['recruitmentLangs', langCode, 'title'])} - {hostName}</span>
                  </div>
                </div>
              </div>
              <div className="sc-bSqbRM dlCkVf">
                <span className="screen-reader-text" >Xem trước mô tả:</span>
                <div className="sc-dkQkSb gbobMG"><div className="sc-dkQkSb gbobMG">
                  <div className="sc-jivCuG hcnbXe">
                  </div><span className="sc-iWBMBB hThKVW">{params.id ? moment(dataForm.createdAt).format('MMM DD, YYYY') : moment().format('MMM DD, YYYY')} － </span>
                  {seo && seo.recruitmentLangs && seo.recruitmentLangs[langCode] ? seo.recruitmentLangs[langCode].seoDescription : form.getFieldValue(['recruitmentLangs', langCode, 'seoDescription'])}
                </div>
                </div>
              </div>
            </div>
          </div>
          <Form.Item
            name={['recruitmentLangs', 'vi', 'urlLink']}
            label={translate("Đường dẫn", langCode)}
            rules={[{ required: false }]}
          >
            <Input addonBefore="/recruitment/" />
          </Form.Item>
          <Form.Item
            name={['recruitmentLangs', langCode, 'seoTitle']}
            label={translate("Tiêu đề trang", langCode)}
            rules={[{ required: false }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name={['recruitmentLangs', langCode, 'seoDescription']}
            label={translate("Mô tả trang", langCode)}
            rules={[{ required: false }]}
          >
            <Input.TextArea
              showCount
              maxLength={250}
              style={{
                height: 70,
                resize: 'none',
              }}
              onBlur={(value) => blurHeading(value, langCode)}
            />
          </Form.Item>
          <Form.Item
                        name={['recruitmentLangs', langCode, 'seoKeywords']}
                        label={translate("Seo từ khóa", langCode)}
                        rules={[{ required: false }]}
                    >
                        <Input />
                    </Form.Item>
        </Card>
        </div>
      </>
    )
  }

  const TABS = [
    {
      label: 'Tiếng Việt',
      key: 'vi',
      children: <ContentTab langCode="vi"></ContentTab>
    },
    {
      label: 'Tiếng Anh',
      key: 'en',
      children: <ContentTab langCode="en"></ContentTab>
    }
  ]
  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
    >
      <Row className='mt-4' gutter={16}>
        <Col span={16}>
          <Tabs
            type="card"
            defaultActiveKey="2"
            items={TABS}
          />
        </Col>
        <Col span={8} className="mt-6">
          <div className="flex justify-end items-center">
            <Space>
              <Popconfirm
                title={!params.id ? 'Xác nhận để tạo tin tuyển dụng' : 'Xác nhận để chỉnh sửa tin tuyển dụng'}
                okText="Yes"
                cancelText="No"
                onConfirm={() => submit()}
              >
                <Button type="primary" loading={loading}>
                  <SaveOutlined />
                  Xác nhận
                </Button>
              </Popconfirm>
              <Button danger onClick={() => navigate('/recruitment')}>
                <EnterOutlined />
                Quay lại</Button>
            </Space>
          </div>
          <Form.Item
            name={'urlImage'}
            label={"Hình đại diện"}
          >
            <Image
              width={'100%'}
              height={'100%'}
              src={urlImage ? process.env.REACT_APP_API_ENDPOINT_DEV + urlImage : ImageDefault }
            />
            <Space>
            <Button onClick={() => handleMedia({ key: 'single', type: 'image', field: 'urlImage' })} icon={<DownloadOutlined />} >
              Upload
            </Button>
            <Button danger onClick={() => handleDeleteMedia({ key: 'single', type: 'image', field: 'urlImage' })} icon={<DeleteOutlined />} >
              Remove
            </Button>
          </Space>
          </Form.Item>
          <Form.Item
            name="name"
            label={"Tên"}
            rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
          >
            <Input placeholder={"Nhập tên"} />
          </Form.Item>
          <Form.Item
            name="ordering"
            label={"Sắp xếp"}
            style={{ width: "100%" }}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="deadlineAt"
            label={"Hạn nộp hồ sơ"}
            rules={[{ required: true, message: 'Vui lòng chọn hạn nộp hồ sơ' }]}
          >
           <DatePicker style={{width:'100%'}} readOnly={false} format={'DD/MM/YYYY'}/>
          </Form.Item>
          <Form.Item
            label="Khu vực"
            name="areaFactoryId"
            rules={[{ message: 'Vui lòng chọn khu vực', required: true }]}
          >
            <Select
              placeholder={'Chọn khu vực'}
              style={{ width: '100%' }}
              options={areas}
            />
          </Form.Item>
          <Form.Item
            label="Tỉnh"
            name="provinceId"
            rules={[{ message: 'Vui lòng chọn tỉnh', required: true }]}
          >
            <Select
              placeholder={'Chọn tỉnh'}
              style={{ width: '100%' }}
              options={province}
            />
          </Form.Item>
          <Form.Item
            label="Trạng thái"
            name="actived"
            rules={[{ message: 'Vui lòng chọn trạng thái', required: true }]}
          >
            <Select
              placeholder={'Chọn trạng thái'}
              style={{ width: '100%' }}
              options={[
                {
                  value: 1,
                  label: 'Hiển thị',
                },
                {
                  value: 0,
                  label: 'Ẩn hiển thị',
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
      <MediaModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} setValue={(value) => setUrlImage(value[0].url)} selectedMedia={selectedMedia}></MediaModal>
    </Form>
  )
}

export default RecruitmentDetail