import axios from 'axios'
import { notification } from 'antd'
import { stringify } from 'querystring'
import { InfoCircleOutlined } from '@ant-design/icons'


export const FetchAPI = async (
  path,
  method,
  headers,
  body,
  endpoint = process.env.REACT_APP_API_ENDPOINT_DEV 
  
) => {
  const defaultHeaders = {
    'Content-type': 'application/json',
    Authorization: localStorage.getItem('accessToken'),
  }
  if (typeof headers === 'object') {
    Object.assign(defaultHeaders, headers)
  }
  try {
    return await axios({
      url: endpoint + path,
      method,
      headers: defaultHeaders,
      data: body,
    })
  } catch (error) {
    if (error.response && error.response.status !== 401) {
      return error.response
    }

    notification.open({
      icon: <InfoCircleOutlined style={{ color: 'red' }} />,
      message: 'Hệ thống đang bảo trì trong ít phút, vui lòng đợi chút',
      description: <a onClick={() => window.location.reload()} href>Tải lại trang</a>,
      duration: 0,
      key: 'notify error',
    })

    return {
      status: 401,
    }
  }
}

export const get = (path, query = {}, headers = {}, endpoint) => {
  return FetchAPI(`${path}?${stringify(query)}`, 'GET', headers, null, endpoint)
}
export const getOne = (path, query = {}, headers = {}, endpoint) => {
  return FetchAPI(`${path}`, 'GET', headers, null, endpoint)
}
export const post = (path, body, headers, endpoint) =>
  FetchAPI(path, 'POST', headers, body, endpoint)
export const patch = (path, body, headers, endpoint) =>
  FetchAPI(path, 'PATCH', headers, body, endpoint)
export const put = (path, body, headers, endpoint) =>
  FetchAPI(path, 'PUT', headers, body, endpoint)
export const destroy = (path, body, headers, endpoint) =>
  FetchAPI(path, 'DELETE', headers, body, endpoint)

export const uploadMutipleFile = async (path,formData) => {
  let headers = new Headers();
  headers.append("Authorization", `Bearer ${localStorage.getItem('accessToken')}`);
  const response = await fetch(process.env.REACT_APP_API_ENDPOINT_DEV + path, {
    method: 'POST',
    headers,
    body: formData // sending our formdata in body of post request
  })
  return response.json();
}