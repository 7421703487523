import { get,post, put, destroy,getOne } from '../apis/httpClient'

export const getPagingPage = (params) => get('/api/v1/page/paging', params)
export const updatePage = (body) => put('/api/v1/page/update', body)
export const detailPage = (id) => getOne(`/api/v1/page/detail/${id}`)
export const deletePage = (body) => destroy('/api/v1/page/delete', body)
export const createPage = (body) => post('/api/v1/page/create',body)
export const listPage = () => get('/api/v1/page/list')

// Layout
export const getArrangement = () => get('/api/v1/layout/arrangement')
export const getDetail = (id) => get(`/api/v1/layout/detail/${id}`)
export const updateLayout = (body) => put(`/api/v1/layout/update`,body)
export const backupLayout = (body) => post(`/api/v1/layout/backup`,body)
export const resetLayout = (body) => put(`/api/v1/layout/reset`,body)
export const pagingHistoryLayout = (params) => get(`/api/v1/layout/pagingHistory`,params)
export const deleteHistoryLayout = (params) => destroy(`/api/v1/layout/deleteHistory`,params)

//Module
export const getListModule = () => get(`/api/v1/layout/module/list`)
//Function
export const getListFunction = (id) => get(`/api/v1/layout/func/list/${id}`)
