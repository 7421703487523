import { useState, useEffect } from "react";
//atnd
import { notification, Form, Input } from 'antd'

//api
import { forgotPassword } from '../../services/loginService';
import { LoadingIcon } from "../../assets/icons/Icons";



function ForgotPassword() {
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setSubmit] = useState(false)
  const [sent, setSent] = useState(false)
  const [form] = Form.useForm()
  const [clicked, setClick] = useState(false)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(10)
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])


  const onSubmit = async (type) => {
    await form.validateFields()

    const { email } = form.getFieldValue()

    setSubmit(true)
    type === 'old' && setClick(true)
    try {
      setLoading(true);
      const dataReset = {
        identity: email,
      }
      const res = await forgotPassword(dataReset)
      if (res.data.code === 200) {
        notification.success({ message: res.data.message })
        setLoading(false)
        setSent(true)
      } else {
        notification.error({
          message: res.data.message || 'Email này chưa có tài khoản, cảm ơn!',
        })
        setLoading(false)
        setSubmit(false)
        type === 'old' && setClick(false)
      }
    } catch (err) {
      setLoading(false)
    }
  };

  return (
    <section id='login' className='flex flex-col md:flex-row bg-white h-screen'>
      <div
        className="top-0 md:flex hidden   md:fixed relative w-[40vw] h-[150px] gap-3  left-0   md:bottom-0 overflow-hidden  md:h-screen flex flex-col   "
        style={{
          boxShadow:
            "0 1px 1px rgba(0, 0, 0, 0.24), 0 4px 4px rgba(0, 0, 0, 0.12)",
        }}
      >
        <img className="object-cover h-full w-full" src="https://avpgroup.vn/tmp/1682582985600_pexels-martin-pÃ©chy-2078271.jpg" />
        <div className="text-center w-full py-5 h-16 px-0  bg-[#2d2926] text-white absolute left-[50%] bottom-0 -translate-x-1/2">
          <h4 className="text-sm font-extrabold tracking-wider">
            JOIN ANVIETPHAT® COMPANY
          </h4>
        </div>
      </div>

      <div className=" grid md:ml-[40vw] h-full w-screen md:w-[60vw] overflow-hidden bg-gray-200 py-5">
        {sent ?
          <div className="mx-auto my-auto w-full flex flex-col items-center justify-center">
            <div className="flex flex-col  w-[90%] px-[32px] mb-[16px] py-[16px] bg-[#f6ffed] rounded-[8px] border-solid border-[1px] border-[#b7eb8f]">
              <div className="flex text-[#0000009e] items-center mb-[8px]">
                <span className="text-[#52c41a] block mr-[20px]">
                  <svg viewBox="64 64 896 896" focusable="false" data-icon="check-circle" width="1.5em" height="1.5em" fill="currentColor" aria-hidden="true"><path d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"></path><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>
                </span>
                <h4 className="text-[24px] leading-[28px]">Thành công</h4>
              </div>
              <p className="text-[20px] leading-[24px] text-[#0000009e] ">Vui lòng kiểm tra email!</p>
            </div>
            <div className="w-[90%] mx-auto">
              <div className="w-full mb-[16px]">
                {seconds > 0 || minutes > 0 ? (
                  <p className="text-[20px] text-center font-bold leading-[24px]">
                    Thời gian còn lại: {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p className="text-[20px] text-center font-bold leading-[24px]">Bạn chưa nhận được mã ?</p>
                )}
              </div>
              {
                seconds === 0 && minutes === 0 &&
                <button
                  type="button"
                  disabled={clicked}
                  onClick={() => onSubmit('old')}
                  className={` mx-auto ${!clicked ? 'hover:bg-amber-200 bg-yellow' : 'bg-amber-200'} w-full max-w-[300px] flex justify-center text-white font-bold py-2 px-4 rounded-3xl focus:outline-none focus:shadow-outline`}
                >
                  {loading ? <LoadingIcon className="animate-spin w-6 h-6 "></LoadingIcon> : <span>Gửi lại</span>}
                </button>

              }

            </div>
          </div>
          :
          <Form form={form} className="min-w-[500px] gap-0 mx-auto my-auto flex items-center justify-center md:w-3/5 md:mx-auto min-h-[420px] px-20 flex flex-col gap-0 py-10 pt-16"
          >
            <div className="mb-4 w-full flex justify-center">
              <img src="https://avpgroup.vn/tmp/1682044652419_logo.png" />
            </div>
            <div className="mb-4 w-full max-w-[500px]">
              <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">
                Email:
              </label>
              <Form.Item className="mb-0" name="email"
                 rules={[
                  {
                    type: 'email',
                    message: 'Email không hợp lệ!',
                  },
                  {
                    required: true,
                    message: 'Vui lòng nhập email!',
                  },
                ]}
              >
                <Input
                  type="email"
                  placeholder="Nhập email"
                  className="shadow appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
              </Form.Item>
            </div>
            <></>
            <button
              onClick={() => onSubmit('new')}
              disabled={isSubmitting}
              className="bg-yellow hover:bg-amber-200 w-full max-w-[300px] flex justify-center text-white font-bold py-2 px-4 rounded-3xl focus:outline-none focus:shadow-outline"
              >
              {loading ? <LoadingIcon className="animate-spin w-6 h-6 "></LoadingIcon> : <span>Gửi</span>}
            </button>
          </Form>
        }
      </div>
    </section>
  )
}


export default ForgotPassword