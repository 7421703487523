
const lang = {
    en: require('../config/lang.json')
}
const handleTranslate = {
    title: 'translate',
    func: (value, langCode) => {
        if (!value) {
            return value;
        }
        if (langCode === 'vi') {
            return value;
        }
        if (langCode && lang[langCode]) {
            const item = lang[langCode][value];
            if (item) {
                return item;
            } 
        }
        return value;
    }
}

export const translate = (value, langCode) => {
    return handleTranslate.func(value, langCode);
}

export const processedSlug =  (value) => {
    if (value && value.length > 0) {
        value = value.toLowerCase();     
        value = value
            .normalize('NFD') 
            .replace(/[\u0300-\u036f]/g, ''); 
            value = value.replace(/[đĐ]/g, 'd');
            value = value.replace(/([^0-9a-z-\s])/g, '');
            value = value.replace(/(\s+)/g, '-');
            value = value.replace(/-+/g, '-');
            value = value.replace(/^-+|-+$/g, '');
        return value;
    }
    return "";
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

export function isEmpty(obj){
    return obj === undefined || obj === null ? true : Object.keys(obj).length === 0
}

export function adminRole(value){
    if (!['ADMIN','SUPER_ADMIN'].includes(value)) {
      return false;
    }
    return true;
}
  
export function checkAccessRole(roles,module,action){
    if(!roles){
      return false;
    }
    const isCheck = roles.find(item => item.path === action && module === item.moduleName)
    if(isEmpty(isCheck)){
      return false;
    }
    return true;
  }
  