
import { destroy, get, getOne, post, put } from "../apis/httpClient"

export const pagingCategoryProduct=(params)=>get('/api/v1/categoryProduct/paging',params)
export const detailCategoryProduct=(query)=>getOne(`/api/v1/categoryProduct/detail/${query}`)
export const updateCategoryProduct=(body)=>put(`/api/v1/categoryProduct/update`,body)
export const createCategoryProduct=(body)=>post(`/api/v1/categoryProduct/create`,body)
export const deleteCategoryProduct=(body)=>destroy(`/api/v1/categoryProduct/delete`,body)
export const listCategoryProduct = () => get('/api/v1/categoryProduct/list')

