import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons'
import { notification, Popconfirm, Space, Table, Button, Tooltip, Input, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../config/pageSize'
import { deleteMenu, pagingMenu } from '../../services/menuService'

function Menu() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [pages, setPages] = useState([])
  const [countPage, setcountPage] = useState(0)
  const [paramsFilter, setParamsFilter] = useState({ amount: PAGE_SIZE, page: 1, status: 'ALL' })
  let timeOut = 300

  const _getPagings = async () => {
    try {
      setLoading(true)
      const res = await pagingMenu(paramsFilter)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      const { list, total } = res.data.data;
      setPages(list)
      setcountPage(total)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  // search
  const searchPaging = async (search = false) => {
    clearTimeout(timeOut)
    timeOut = setTimeout(() => {
      paramsFilter.search = search.target.value
      setParamsFilter(paramsFilter)
      _getPagings()
    }, timeOut)
  }

  const _deletePage = async (id) => {
    try {
      const res = await deleteMenu({ id })
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      _getPagings()
      notification.success({ message: res.data.message })
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
  }, []);

  useEffect(() => {
    _getPagings()
  }, [paramsFilter]);

  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      align: 'center',
      key: 'index'
    },
    {
      title: 'Tên',
      align: 'center',
      dataIndex: 'name',
      render: (text) => text,
    },
    {
      title: 'Trạng thái',
      align: 'center',
      render: (text, record) => (
          <div style={{ fontWeight: 'bold' }}>
              <Tag color={record.actived ? 'success' : 'default'}>
                  {record.actived ? 'Hiển thị' : 'Ẩn hiển thị'}
              </Tag>
          </div>
      ),
  },
    {
      title: 'Hành động',
      align: 'center',
      render: (text, record) => (
        <Space className="action">
          <Tooltip placement="top" title={'Chi tiết'}>
            <Button onClick={() => { navigate(`/menu/update/${record.id}`) }}>
              <EditFilled />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={'Xóa'}>
            <Popconfirm
              title="Bạn có muốn xóa nhóm menu này?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => _deletePage(record.id)}
            >
              <Button danger>
                <DeleteFilled />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      )
    },
  ]

  return (
    <div className='mt-4'>
      <div className="flex justify-between items-center">
        <Button onClick={() => { navigate(`/menu/create`) }}>
          <PlusOutlined />
        </Button>
        <Input.Search
          placeholder="Tìm kiếm..."
          allowClear
          onChange={searchPaging}
          style={{ width: 200 }}
          loading={loading}
        />
      </div>
      <Table
        dataSource={pages}
        pagination={{
          position: ['bottomCenter'],
          current: paramsFilter.page,
          pageSize: paramsFilter.amount,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
          total: countPage,
        }}
        loading={loading}
        columns={columns}
        className="mt-4"
        bordered
        scroll={{ x: 640 }}
        rowKey={record => record.id}

      ></Table>
    </div>
  )
}

export default Menu