import { DeleteOutlined, DownloadOutlined, EditOutlined, EllipsisOutlined, EnterOutlined, SaveOutlined } from '@ant-design/icons'
import { Form, Input, notification, Popconfirm, Space, Button, Row, Col, Tabs, Select, Dropdown, Modal, Image } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ReactSortable } from "react-sortablejs";
import { v4 as uuidv4 } from 'uuid';
import { translate } from '../../../helper'
import MediaModal from '../../../components/Modal/ModalMedia'
import { createStatistic, detailStatistic, updateStatistic } from '../../../services/statisticService';
import { ImageDefault } from '../../../config/const';

function StatisticDetail() {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [dataForm, setDataForm] = useState({})
    const [loading, setLoading] = useState(false)
    const [items, setItem] = useState({
        vi: [],
        en: []
    });
    const [lang, setLang] = useState('vi');
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState();
    const [isModalVisible, setModalVisible] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [urlImage, setUrlImage] = useState("");

    const [form2] = Form.useForm()

    const params = useParams();

    const _detail = async (id) => {
        try {
            setLoading(true)
            const res = await detailStatistic(id)
            if (!res.data || res.data.code !== 200) {
                return notification.warning({
                    message: res.data.message,
                })
            }
            const { actived, name, statisticLangs,urlImage } = res.data.data;
            const dataform = {
                actived,
                name,
                statisticLangs:{
                    vi:[],
                    en:[]
                }
            };
            for (let i = 0; i < statisticLangs.length; i++) {
                const { langCode } = statisticLangs[i];
                dataform.statisticLangs[langCode].unshift(statisticLangs[i]);
            }
            setUrlImage(urlImage);
            setItem(dataform.statisticLangs);
            setDataForm(dataform)
            form.setFieldsValue(dataform)
        } catch (err) {
            console.log(err)
            notification.error({ message: err.message })
        } finally {
            setLoading(false)
        }
    }

    const submit = async () => {
        try {
            await form.validateFields();
            const { name,actived } = form.getFieldsValue();
            const body = {
                name,
                actived,
                statisticLangs: []
            };
            let isCheckTab = true;
            ['en', 'vi'].forEach(item => {
                if (!items[item]) {
                    isCheckTab = false
                }
            })
            if(urlImage){
                body.urlImage = urlImage;
            }
            if (!params.id && !isCheckTab) {
                return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
            }
            body.statisticLangs = items;
            if (!params.id) {
                const res = await createStatistic(body)
                if (!res.data || res.data.code !== 200) {
                    return notification.warning({
                        message: res.data.message || 'Tạo thống kê thất bại vui lòng thử lại!',
                    })
                }
                notification.success({ message: res.data.message })
            }
            if (params.id) {
                body.id = parseInt(params.id)
                const res = await updateStatistic(body)
                if (!res.data || res.data.code !== 200) {
                    return notification.warning({
                        message: res.data.message || 'Chỉnh sửa thống kê thất bại vui lòng thử lại!',
                    })
                }
                notification.success({ message: res.data.message })
            }
            navigate('/statistic')
        } catch (err) {
            if (err.errorFields?.length > 0) {
                return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
            }
            console.log(err)
            notification.error({ message: err.message })
        } finally {
        }
    }

    const _setItems = (value) => {
        if (value) {
            setItem(value)
        }
    }

    const _sortItems = (value) => {
        let itemsTmp = items;
        itemsTmp[lang] = value;
        if (itemsTmp) {
           setItem({ ...itemsTmp })
        }
    }

    const _addItems = () => {
        items[lang].push({
            id: uuidv4(),
            title: '0',
            description: '',
            langCode: lang,
        });
        _setItems({ ...items })
    }

    const handleOpen = (index) => {
        setIndex(index);
        form2.setFieldsValue(items[lang][index]);
        setOpen(true)
    }

    const handleMedia = (value) => {
        setSelectedMedia(value);
        setModalVisible(true)
    };

    const deleteMedia  = (index) => {
        let itemsTmp = items;
        itemsTmp[lang].splice(index,1)
        setItem({...items,...itemsTmp})
      }

    const submitEdit = () => {
        form2.validateFields().then(res => {
        const { title, description } = form2.getFieldValue();
        items[lang][index].title = title;
        items[lang][index].description = description;
        _setItems({ ...items });
          setOpen(false)
          form2.resetFields()
        })
    }

    useEffect(() => {
        if (params.id) {
            _detail(params.id);
        }
    }, [params.id]);

    const TABS = [
        {
            label: 'Tiếng Việt',
            key: 'vi',
        },
        {
            label: 'Tiếng Anh',
            key: 'en',
        }
    ]
    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
        >
            <Row className='mt-4' gutter={16}>
                <Col span={16}>
                    <Tabs
                        className="mt-4"
                        type="card"
                        defaultActiveKey="2"
                        items={TABS}
                        onChange={(value) => setLang(value)}
                    />
                    <Button type="primary" loading={loading} onClick={() => _addItems()}>
                        <SaveOutlined />
                        Thêm cột
                    </Button>
                    <ReactSortable
                        className="mt-4"
                        animation={200}
                        delayOnTouchStart={true}
                        delay={2}
                        list={items && items[lang] ? items[lang] : []}
                        setList={(value) => _sortItems(value)}
                    >
                        {items && items[lang] && items[lang].map((item, index) => (
                            <div className="p-grid flex" key={index}>
                                <div className="draggableItem function-item" >
                                    <div className="btn-group w-100">
                                        <div
                                            className="btn inner-item dropdown-toggle func-item"
                                        >
                                            <div className='flex justify-center items-center'>
                                                <div className='index-slider'>{index + 1}</div>
                                                <div className='flex justify-center items-center ml-2'>
                                                    <div>
                                                        <div>{item.title}</div>
                                                        <div>{item.description}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Dropdown
                                                menu={{
                                                    items: [
                                                        {
                                                            key: '1',
                                                            label: (
                                                                <div className="action" onClick={() => { handleOpen(index) }}>
                                                                    <Space>
                                                                        <EditOutlined />
                                                                        <span>Tùy chỉnh</span>
                                                                    </Space>
                                                                </div>
                                                            ),
                                                        },
                                                        {
                                                            key: '2',
                                                            label: (
                                                                <div className="action" onClick={() => deleteMedia(index)}>
                                                                    <Space>
                                                                        <DeleteOutlined />
                                                                        <span>Xóa</span>
                                                                    </Space>

                                                                </div>
                                                            ),
                                                        },
                                                    ]
                                                }}
                                                placement="bottom"
                                                arrow
                                                trigger={'click'}
                                            >
                                                <EllipsisOutlined />
                                            </Dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </ReactSortable>
                </Col>
                <Col span={8} className="mt-6">
                    <div className="flex justify-end items-center">
                        <Space>
                            <Popconfirm
                                title={!params.id ? 'Xác nhận để tạo chuyên mục' : 'Xác nhận để chỉnh sửa chuyên mục'}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => submit()}
                            >
                                <Button type="primary" loading={loading}>
                                    <SaveOutlined />
                                    Xác nhận
                                </Button>
                            </Popconfirm>
                            <Button danger onClick={() => navigate('/statistic')}>
                                <EnterOutlined />
                                Quay lại</Button>
                        </Space>
                    </div>
                    <Form.Item
                        name={'urlImage'}
                        label={"Ảnh nền"}
                    >
                        <Image
                        width={'100%'}
                        height={'100%'}
                        src={urlImage ? process.env.REACT_APP_API_ENDPOINT_DEV + urlImage : ImageDefault }
                        />
                        <Button onClick={() => handleMedia({ key: 'single', type: 'image', field: 'urlImage' })} icon={<DownloadOutlined />} >
                        Upload
                        </Button>
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label={"Tên"}
                        rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
                    >
                        <Input placeholder={"Nhập tên"} />
                    </Form.Item>
                    <Form.Item
                        label="Trạng thái"
                        name="actived"
                        rules={[{ message: 'Vui lòng chọn trạng thái', required: true }]}
                    >
                        <Select
                            placeholder={'Chọn trạng thái'}
                            style={{ width: '100%' }}
                            options={[
                                {
                                    value: 1,
                                    label: 'Hiển thị',
                                },
                                {
                                    value: 0,
                                    label: 'Ẩn hiển thị',
                                },
                            ]}
                        />
                    </Form.Item>
                    
                </Col>
            </Row>
            <Modal
                open={open}
                title={translate("Chỉnh sửa", lang)}
                okText={translate("Xác nhận", lang)}
                cancelText={translate("Hủy", lang)}
                onCancel={() => {
                    setOpen(false);
                    form2.resetFields()
                 }
                }
                onOk={() => submitEdit()}
                width={"30%"}
                className="top-5"
            >
                <Form
                    form={form2}
                    layout="vertical"
                    name="form"
                    className="gap-5"
                >
                    <Form.Item
                        name={'title'}
                        label={translate("Mô tả", lang)}
                        rules={[{ required: true, message: translate("Vui lòng nhập mô tả", lang) }]}
                    >
                        <Input placeholder={translate("Nhập mô tả", lang)} />
                    </Form.Item>
                   <Form.Item

                        name={['description']}
                        label={translate("Giá trị", lang)}
                        rules={[{ required: false }]}
                    >
                         <Input placeholder={translate("Nhập giá trị", lang)} />
                    </Form.Item>     
                </Form>
            </Modal>
            <MediaModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} setValue={(value) => setUrlImage(value[0].url)} selectedMedia={selectedMedia}></MediaModal>
        </Form>
    )
}

export default StatisticDetail