import { EnterOutlined, SaveOutlined } from '@ant-design/icons'
import { Form, Input, notification, Popconfirm, Space, Button, Row, Col, Tabs,Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { checkAccessRole, translate } from '../../../helper'
import './detail.scss';

import Editor from "ckeditor5-custom-build/build/ckeditor";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { createBLock, detailBLock, updateBLock } from '../../../services/blockService'
import { ACTION, MODULES, editorConfiguration } from '../../../config/const'
import { useSelector } from 'react-redux'

function BlogDetail() {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [dataForm, setDataForm] = useState({})
  const [loading, setLoading] = useState(false)
  const roles = useSelector((state) => state.permission).data;

  const params = useParams();

  const _detail = async (id) => {
    try {
      setLoading(true)
      const res = await detailBLock(id)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      const { actived, name, blockLangs, createdAt } = res.data.data;
      const dataform = {
        actived,
        name,
        createdAt,
        blockLangs: {}
      }
      for (let i = 0; i < blockLangs.length; i++) {
        const { langCode } = blockLangs[i];
        dataform.blockLangs[langCode] = blockLangs[i];
      }
      setDataForm(dataform)
      form.setFieldsValue(dataform)
    } catch (err) {
      console.log(err)
      notification.error({ message: err.message })
    } finally {
      setLoading(false)
    }
  }

  const submit = async () => {
    try {
      await form.validateFields();
      const { name, blockLangs, actived } = form.getFieldsValue();
      const body = {
        name,
        actived,
        blockLangs: []
      };
      let isCheckTab = true;
      ['en', 'vi'].forEach(item => {
        if (!blockLangs[item]) {
          isCheckTab = false
        }
      });
      if(!params.id && !isCheckTab){
        return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
    }
      for (const item in blockLangs) {
        blockLangs[item].langCode = item;
        if (params.id) { // type Update
          blockLangs[item].id = dataForm.blockLangs[item].id;
        }
        body.blockLangs.push(blockLangs[item])
      }
      if (!params.id) {
        const res = await createBLock(body)
        if (!res.data || res.data.code !== 200) {
          return notification.warning({
            message: res.data.message || 'Tạo block thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
      }
      if (params.id) {
        if (!checkAccessRole(roles, MODULES.EDITOR, ACTION.UPDATE)) {
          return notification.warning({
            message: 'Bạn không có quyền chỉnh sửa block này',
          })
        }
        body.id = parseInt(params.id)
        const res = await updateBLock(body)
        if (!res.data || res.data.code !== 200) {
          return notification.warning({
            message: res.data.message || 'Chỉnh sửa block thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
      }
      navigate('/block')
    } catch (err) {
      if (err.errorFields?.length > 0) {
        return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
      }
      console.log(err)
      notification.error({ message: err.message })
    } finally {
    }
  }

  useEffect(() => {
    if (params.id) {
      _detail(params.id);
    }
  }, [params.id]);

  const ContentTab = ({ langCode }) => {
    return (
      <>
        <div>
          <Form.Item
            name={['blockLangs', langCode, 'title']}
            label={translate("Tiêu đề", langCode)}
            rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}
          >
            <Input placeholder={translate("Nhập tiêu đề", langCode)} />
          </Form.Item>
          <Form.Item
            name={['blockLangs', langCode, 'description']}
            label={translate("Mô tả", langCode)}
            rules={[{ required: false }]}
          >
            <Input.TextArea placeholder={translate("Nhập mô tả", langCode)} style={{minHeight:'600px'}}></Input.TextArea>
          </Form.Item>
        </div>
      </>
    )
  }

  const TABS = [
    {
      label: 'Tiếng Việt',
      key: 'vi',
      children: <ContentTab langCode="vi"></ContentTab>
    },
    {
      label: 'Tiếng Anh',
      key: 'en',
      children: <ContentTab langCode="en"></ContentTab>
    }
  ]
  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
    >
      <Row className='mt-4' gutter={16}>
        <Col span={16}>
          <Tabs
            type="card"
            defaultActiveKey="2"
            items={TABS}
          />
        </Col>
        <Col span={8} className="mt-6">
          <div className="flex justify-end items-center">
            <Space>
              <Popconfirm
                title={!params.id ? 'Xác nhận để tạo bài viết' : 'Xác nhận để chỉnh sửa bài viết'}
                okText="Yes"
                cancelText="No"
                onConfirm={() => submit()}
              >
                <Button type="primary" loading={loading}>
                  <SaveOutlined />
                  Xác nhận
                </Button>
              </Popconfirm>
              <Button danger onClick={() => navigate('/block')}>
                <EnterOutlined />
                Quay lại</Button>
            </Space>
          </div>
          <Form.Item
            name="name"
            label={"Tên"}
            rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
          >
            <Input placeholder={"Nhập tên"} />
          </Form.Item>
          <Form.Item
            label="Trạng thái"
            name="actived"
            rules={[{ message: 'Vui lòng chọn trạng thái', required: true }]}
          >
            <Select
              placeholder={'Chọn trạng thái'}
              style={{ width: '100%' }}
              options={[
                {
                  value: 1,
                  label: 'Hiển thị',
                },
                {
                  value: 0,
                  label: 'Ẩn hiển thị',
                },
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}

export default BlogDetail