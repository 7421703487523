import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useDispatch } from 'react-redux'


import { REFECT } from '../redux/reducers/login';

import Layout from "../layout/Layout"
import Login from '../pages/Login/Login';
import NotFound from '../pages/NotFound/NotFound';
import { refresh } from '../services/loginService';

//utils

import ScrollToTop from '../utils/ScrollToTop';
import { privateRoutes, publicRoutes } from './routes';
import Protected from '../utils/Protected';
import { getListAccessRole } from '../services/permission';
import { notification } from 'antd';
import { ACCESS_ROLES } from '../redux/reducers/permission';

function Router() {

    const [user, updateUser] = useState({});
    const [isLogin, updateStateLogin] = useState(true);
    const dispatch = useDispatch()

   
    const getData = async () => {
        const res = await refresh();
        if(!res.data || res.data.code !== 200){
          localStorage.removeItem('accessToken');
          updateStateLogin(false);
          updateUser({});
          if(window.location.pathname !== "/login"){
            window.location.href= "/login"
          };
          return;
        }    
        updateStateLogin(true);
        findAccessRole();
        dispatch({ type: REFECT, payload: res.data.data });
        return updateUser(res.data.data);
      };

      const findAccessRole = async () => {
        try {
          const res = await getListAccessRole()
          if (!res.data || res.data.code !== 200) {
            return notification.warning({ message: res.data.message })
          };
          dispatch({ type: ACCESS_ROLES, payload: res.data.data });
          // return res.data.data;
        } catch (error) {
          return notification.warning({ message: error.message })
        }
      }

      useEffect(() => {
        let tempcheck = false
        for (let i = 0; i < publicRoutes.length; i++) {
          if(publicRoutes[i]?.path == window.location.pathname) {
            tempcheck = true;
            return;
          }
        }
        if(!tempcheck) {
          getData();
        }
      },[])// eslint-disable-line react-hooks/exhaustive-deps
   
  return (
    <BrowserRouter>
    <ScrollToTop>
        <Routes>
            <Route  element={<Layout user={user} />}>
                {privateRoutes.map((route,index) =>{
                    return (<Route key={index} path={route.path} element={
                    <Protected isLogin={isLogin}>
                        <route.component></route.component>
                    </Protected>}/>)           
                })}
            </Route>       
            {publicRoutes.map((route,index) =>{
                    return (<Route key={index} path={route.path} element={<route.component/>}/>)           
                })}
            <Route path="*" element={<NotFound />} />
        </Routes>
    </ScrollToTop>
    </BrowserRouter>
  )
}

export default Router
