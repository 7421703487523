import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'
//atnd
import { notification, Form, Input } from 'antd'

//api
import { resetPassword } from '../../services/loginService';
import { LoadingIcon } from "../../assets/icons/Icons";



function ResetPassword() {
  const [loading, setLoading] = useState(false)
  const [isSubmitting, setSubmit] = useState(false)
  const [form] = Form.useForm()
  const navigate = useNavigate();

  const onSubmit = async () => {
    await form.validateFields()

    const { password } = form.getFieldValue()
    const query = new URLSearchParams(window.location.search)
    const token = query.get('token')
    try {
      setLoading(true);
      const dataReset = {
        password,
        token,
      }
      const res = await resetPassword(dataReset)
      if (res.data.code === 200) {
        notification.success({ message: res.data.message })
        navigate('login')
        setLoading(false)
      } else {
        notification.error({
          message: res.data.message,
        })
        setLoading(false)
      }
    } catch (err) {
      setLoading(false)
    }
  };

  return (
    <section id='login' className='flex flex-col md:flex-row bg-white h-screen'>
      <div
        className="top-0 md:flex hidden   md:fixed relative w-[40vw] h-[150px] gap-3  left-0   md:bottom-0 overflow-hidden  md:h-screen flex flex-col   "
        style={{
          boxShadow:
            "0 1px 1px rgba(0, 0, 0, 0.24), 0 4px 4px rgba(0, 0, 0, 0.12)",
        }}
      >

        <img className="object-cover h-full w-full" src="https://avpgroup.vn/tmp/1682582985600_pexels-martin-pÃ©chy-2078271.jpg" />
        <div className="text-center w-full py-5 h-16 px-0  bg-[#2d2926] text-white absolute left-[50%] bottom-0 -translate-x-1/2">
          <h4 className="text-sm font-extrabold tracking-wider">
            JOIN ANVIETPHAT® COMPANY
          </h4>
        </div>
      </div>

      <div className=" grid md:ml-[40vw] h-full w-screen md:w-[60vw] overflow-hidden bg-gray-200 py-5">
          <Form form={form} className="min-w-[500px] !gap-0 mx-auto my-auto flex items-center justify-center md:w-3/5 md:mx-auto min-h-[420px] px-20 flex flex-col gap-5 py-10 pt-16"
          >
            <div className="mb-4 w-full flex justify-center">
              <img src="https://avpgroup.vn/tmp/1682044652419_logo.png" />
            </div>
            <div className="mb-4 w-full max-w-[500px]">
              <label htmlFor="password" className="block text-gray-700 font-semibold mb-2">
                Mật khẩu:
              </label>
              <Form.Item className="mb-0" name="password"
                 rules={[
                  {
                    required: true,
                    message: 'Vui lòng nhập mật khẩu mới',
                  },
                ]}
              >
                <Input.Password className="shadow appearance-none border rounded-3xl w-full py-3 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Nhập mật khẩu mới"
                />
              </Form.Item>
            </div>
            <></>
            <button
              onClick={() => onSubmit()}
              disabled={isSubmitting}
              className="bg-yellow hover:bg-amber-200 w-full max-w-[300px] flex justify-center text-white font-bold py-2 px-4 rounded-3xl focus:outline-none focus:shadow-outline"
            >
              {loading ? <LoadingIcon className="animate-spin w-6 h-6 "></LoadingIcon> : <span>Xác nhận</span>}
            </button>
          </Form>
      </div>
    </section>
  )
}


export default ResetPassword