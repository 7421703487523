import { DeleteFilled, DownloadOutlined, EditFilled, PlusOutlined } from '@ant-design/icons'
import { notification, Popconfirm, Space, Table, Tag, Button, Tooltip, Input,Badge, Select } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../config/pageSize'
import { deleteCandidate, pagingCandidate, viewCandidate } from '../../services/candidateService'
import { listRecruitment } from '../../services/recruitmentService'
import { useSelector } from 'react-redux'
import { ACTION, MODULES } from '../../config/const'
import { checkAccessRole } from '../../helper'

function Candidate() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [pages, setPages] = useState([])
    const [countPage, setcountPage] = useState(0)
    const [recruitment, setRecruitment] = useState([])
    const [paramsFilter, setParamsFilter] = useState({ amount: PAGE_SIZE, page: 1, status: 'ALL' })
    const roles = useSelector((state) => state.permission).data;

    let timeOut = 300

    const _getPagings = async () => {
        try {
            setLoading(true)
            const res = await pagingCandidate(paramsFilter)
            if (!res.data || res.data.code !== 200) {
                return notification.warning({
                    message: res.data.message,
                })
            }
            const { list, total } = res.data.data;
            setPages(list)
            setcountPage(total)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    // search
    const searchPaging = async (search = false) => {
        clearTimeout(timeOut)
        timeOut = setTimeout(() => {
            paramsFilter.search = search.target.value
            setParamsFilter(paramsFilter)
            _getPagings()
        }, timeOut)
    }

    const _deletePage = async (id) => {
        try {
            const res = await deleteCandidate({ id })
            if (!res.data || res.data.code !== 200) {
                return notification.warning({
                    message: res.data.message,
                })
            }
            _getPagings()
            notification.success({ message: res.data.message })
        } catch (err) {
            console.log(err)
        }
    }
    // getCandidate
    const getRecruitment = async () => {
        try {
        const res = await listRecruitment()
        if (!res.data || res.data.code !== 200) {
            return notification.warning({ message: res.data.message })
        }
        setRecruitment(
            res.data.data.map((item) => {
            return {
                value: item.id,
                label: item.name,
            }
            })
        )
        } catch (error) {
        notification.error({ message: error.message })
        } finally {
        }
    }

    const changeStore = (value) => {
        paramsFilter.recruitmentId = value
        setParamsFilter(paramsFilter)
        _getPagings()
    }

    const _viewCandidate = async (id) => {
        try {
          const res = await viewCandidate(id)
          if (!res.data || res.data.code !== 200) {
            return notification.warning({
              message: res.data.message,
            })
          }
          _getPagings()
        } catch (err) {
          console.log(err)
          notification.error({ message: err.message })
        } finally {
        }
      }
      
    useEffect(() => {
        _getPagings()
    }, [paramsFilter]);

    useEffect(() => {
        getRecruitment()
    }, []);

    const columns = [
        {
            title: 'STT',
            render: (text, record, index) => index + 1,
            align: 'center',
            key: 'index'
        },
        {
            title: 'Vị trí',
            align: 'center',
            render: (text,record) => 
            <div>
                {record.recruitments.name}
            </div>,
        },
        {
            title: 'Email',
            align: 'center',
            dataIndex: 'email',
            render: (text) => text,
        },
        {
            title: 'Tên',
            align: 'center',
            dataIndex: 'name',
            render: (text) => text,
        },
        {
            title: 'Số điện thoại',
            align: 'center',
            dataIndex: 'phone',
            render: (text) => text,
        },
        {
            title: 'Tải CV',
            align: 'center',
            render: (text, record) => <div style={{cursor:'pointer'}}>
                <a onClick={()=>_viewCandidate(record.id)} href={process.env.REACT_APP_WEBSITE + record.urlFile} target='_blank'>
                    <DownloadOutlined />
                </a>
            </div>
        },
        {
            title: 'Ngày ứng tuyển',
            align: 'center',
            render: (text, record) => <div>
             {moment(record.createdAt).format('HH:mm DD-MM-YYYY')}
            </div>
        },
        {
            title: 'Trạng thái',
            align: 'center',
            render: (text, record) => (
              <div style={{ fontWeight: 'bold' }}>
                <Tag color={record.isCheck ? 'success' : 'default'}>
                  {record.isCheck ? 'Đã xem' : 'Chưa xem'}
                </Tag>
              </div>
            ),
        },
        {
            title: 'Hành động',
            align: 'center',
            render: (text, record) => (
                <Space className="action">
                    {
                        checkAccessRole(roles, MODULES.CANDIDATE, ACTION.DELETE) ? <Tooltip placement="top" title={'Xóa'}>
                        <Popconfirm
                            title="Bạn có muốn ứng viên này?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => _deletePage(record.id)}
                        >
                            <Button danger>
                                <DeleteFilled />
                            </Button>
                        </Popconfirm>
                    </Tooltip> :""
                    }
                    
                </Space>
            )
        },
    ]

    return (
        <div className='mt-4'>
            <div className="flex justify-end items-center">
                <Space>
                    <Select
                        allowClear={() => changeStore(0)}
                        style={{ width: '150px' }}
                        placeholder="Chọn vị trí"
                        options={recruitment}
                        onChange={(value) => changeStore(value)}
                    />
                    <Input.Search
                        placeholder="Tìm kiếm..."
                        allowClear
                        onChange={searchPaging}
                        style={{ width: 200 }}
                        loading={loading}
                    />
                </Space>
            </div>
            <Table
                dataSource={pages}
                pagination={{
                    position: ['bottomCenter'],
                    current: paramsFilter.page,
                    pageSize: paramsFilter.amount,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    showQuickJumper: true,
                    onChange: (page, pageSize) => {
                        setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
                    },
                    total: countPage,
                }}
                loading={loading}
                columns={columns}
                className="mt-4"
                bordered
                scroll={{ x: 640 }}
                rowKey={record => record.id}
            ></Table>
        </div>
    )
}

export default Candidate