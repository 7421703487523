import { Breadcrumb } from 'antd';
import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { breadcrumbNameMap } from '../routes/routes';
import Header from './Header/Header'


function Layout() {

  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return {
      key: url,
      title: <Link to={url}>{breadcrumbNameMap[url]}</Link>,
    };
  });
  const breadcrumbItems = [
    {
      title: <Link to="/" className=''> Trang chủ</Link>,
      key: 'home',
    },
  ].concat(extraBreadcrumbItems);
  return (
    <>
        <Header/>
        <div className=' mt-20  md:mt-0 md:ml-[310px]'>
          <div className='ml-2 mb-2 md:ml-0 rounded-xl mr-2'>
            <div  className='rounded-xl' style={{boxShadow:'0px 0px 4px rgba(0, 0, 0, 0.25)',background:'white',padding:'1rem'}}>
              <Breadcrumb items={breadcrumbItems} /> 
            </div>
            <div  className='rounded-xl mt-2' style={{boxShadow:'0px 0px 4px rgba(0, 0, 0, 0.25)',background:'white',padding:'1rem'}}>
              <Outlet></Outlet> 
            </div>
          </div> 
        </div>         
    </>
  )
}

export default Layout