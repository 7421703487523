import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons'
import { notification, Popconfirm, Space, Table, Tag, Button, Tooltip, Input,Badge } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../config/pageSize'
import { pagingEmail, deleteEmail } from '../../services/emailRegister'


function EmailRegister() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [pages, setPages] = useState([])
    const location = useLocation();
    const [countPage, setcountPage] = useState(0)
    const [paramsFilter, setParamsFilter] = useState({ amount: PAGE_SIZE, page: 1, status: 'ALL', type: location?.pathname === '/email-register'? 'REGISTER' : 'RECRUITMENT' })
    let timeOut = 300

    const _getPagings = async () => {
        try {
            setLoading(true)
            paramsFilter.type = location?.pathname === '/email-register'? 'REGISTER' : 'RECRUITMENT'
            const res = await pagingEmail(paramsFilter)
            if (!res.data || res.data.code !== 200) {
                return notification.warning({
                    message: res.data.message,
                })
            }
            const { list, total } = res.data.data;
            setPages(list)
            setcountPage(total)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }

    // search
    const searchPaging = async (search = false) => {
        clearTimeout(timeOut)
        timeOut = setTimeout(() => {
            paramsFilter.search = search.target.value
            setParamsFilter(paramsFilter)
            _getPagings()
        }, timeOut)
    }

    const _deletePage = async (id) => {
        try {
            const res = await deleteEmail({ id })
            if (!res.data || res.data.code !== 200) {
                return notification.warning({
                    message: res.data.message,
                })
            }
            _getPagings()
            notification.success({ message: res.data.message })
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        _getPagings()
    }, [paramsFilter,location]);    

    const columns = [
        {
            title: 'STT',
            render: (text, data, index) => index + 1,
            align: 'center',
            key: 'index'
        },
        {
            title: 'Email',
            align: 'center',
            render: (text, data) => <div>
                {data.email}
            </div>
        },
        {
            title: 'Trạng thái',
            align: 'center',
            render: (text, data) => (
              <div style={{ fontWeight: 'bold' }}>
                <Tag color={data.actived ? 'success' : 'default'}>
                  {data.actived ? 'Hoạt động' : 'Ngưng hoạt động'}
                </Tag>
              </div>
            ),
        },
        {
            title: 'Loại',
            align: 'center',
            render: (text, data) => <div>
                {data.type}
            </div>
        },
        {
            title: 'Hành động',
            align: 'center',
            render: (text, data) => (
                <Space className="action">
                    
                    <Tooltip placement="top" title={'Xóa'}>
                        <Popconfirm
                            title="Bạn có muốn khách hàng này?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => _deletePage(data.id)}
                        >
                            <Button danger>
                                <DeleteFilled />
                            </Button>
                        </Popconfirm>
                    </Tooltip>
                </Space>
            )
        },
    ]

    return (
        <div className='mt-4'>
            <div className="flex justify-start items-center">
                <Button onClick={() => { navigate( location?.pathname === '/email-register'? `/email-register/create` : `/email-recruitment/create` ) }}>
                <PlusOutlined />
                </Button>
            </div>
            <Table
                dataSource={pages}
                pagination={{
                    position: ['bottomCenter'],
                    current: paramsFilter.page,
                    pageSize: paramsFilter.amount,
                    pageSizeOptions: PAGE_SIZE_OPTIONS,
                    showQuickJumper: true,
                    onChange: (page, pageSize) => {
                        setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
                    },
                    total: countPage,
                }}
                loading={loading}
                columns={columns}
                className="mt-4"
                bordered
                scroll={{ x: 640 }}
                rowKey={data => data.id}
            ></Table>
        </div>
    )
}

export default EmailRegister