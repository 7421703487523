import React, { useEffect, useState } from 'react'
//antd
import { DeleteFilled, EditFilled,  PlusOutlined } from '@ant-design/icons'
import { Button, notification, Popconfirm, Space, Table, Tooltip } from 'antd'
//static
import { ACTION, MODULES } from '../../config/const'
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../config/pageSize'
import RoleModal from '../../components/Modal/ModalPermission/index'
//service
import { deleteRole, detailRole, pagingGroupRole } from '../../services/permission'
import { useSelector } from 'react-redux'
import { checkAccessRole } from '../../helper'

function Permission() {
  //state
  const [loading, setLoading] = useState(false)
  const [openCreateModal,setOpenCreateModal] = useState(false)
  const [detailData,setDetailData] = useState()
  const [data, setData] = useState({})
  const [typeForm, setTypeForm] = useState('CREATE')
  const [paramsFilter, setParamsFilter] = useState({
    page: 1,
    amount: PAGE_SIZE,
  })
  const roles = useSelector((state) => state.permission).data;

  //action
  const onConfirm = async (id)=>{
    try{
      const res = await deleteRole({ id: id })
      if(res.data.code === 200){
        paging()  
        return notification.success({
          message: res.data.message,
        })
      }  
    }
    catch(err){
      notification.error({ message: err.message })
    }
  }

  const onOpen = () =>{
    setOpenCreateModal(true)
    setTypeForm(ACTION.CREATE)
  }

  const onEdit = (id) =>{
    if(id > 0){
      setOpenCreateModal(true)
      setTypeForm(ACTION.UPDATE)
      detailPermission(id)
    }
  }
  // service
  const paging = async (filter = false) => {
    try {
      setLoading(true)
      paramsFilter.search = filter ? filter : ""
      setParamsFilter(paramsFilter)
         const res = await pagingGroupRole(paramsFilter)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({ message: res.data.message })
      }
      setData(res.data.data)
    } catch (error) {
      notification.error({ message: error.message })
    } finally {
      setLoading(false)
    }
  }

  const detailPermission = async(_id) =>{
    try {
      setLoading(true)
      const res = await detailRole(_id)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      setDetailData(res)
    }catch(err){
      console.log(err)
      notification.error({ message: err.message })
    }finally{
      setLoading(false)
    }
  }

  //effect
  useEffect(() => {
    paging()
  }, [paramsFilter])

  const columns = [
    {
      title: 'STT',
      align: 'center',
      render: (text, record, index) => index + 1,
    },
    {
      title: 'Tên nhóm',
      align: 'center',
      dataIndex: 'name',
      render: (text) => text,
    },
    {
      title: 'Mô tả',
      align: 'center',
      dataIndex: 'description',
      render: (text) => text,
    },
    {
      title: 'Hành động',
      align: 'center',
      render: (text, record) =>{
    
     return (
          <Space size="small" className='flex justify-center  items-center' >
            <Tooltip placement="top" title={'Sửa'}>
              <Button onClick={()=> onEdit(record.id)} className='px-2 '>
                <EditFilled ></EditFilled>
              </Button>
            </Tooltip>
            {
              checkAccessRole(roles, MODULES.PERMISSION, ACTION.DELETE) ? <Popconfirm
              title="Bạn muốn xóa đối tượng này?"
              okText="Có"
              cancelText="Không"
              onConfirm={() => onConfirm(record.id)}
            >
               <Button className='px-2' danger>
                <DeleteFilled />
                </Button>
            </Popconfirm> :""
            }
            
          </Space>
        )
      } 
    },
  ]
  return (
    <>
      <div className='md:flex justify-between mt-2 '>
        {
          checkAccessRole(roles, MODULES.PERMISSION, ACTION.CREATE) ? <Button onClick={onOpen}  className='mt-1 lg:mb-2  flex justify-center items-center px-2' >
          <PlusOutlined></PlusOutlined>
        </Button>  :""
        }
         
      </div>
      <Table
        className="mt-4 lg:mt-1"
        pagination={{
          position: ['bottomCenter'],
          current: paramsFilter.page,
          pageSize: paramsFilter.amount,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, amount: pageSize })
          },
          total: data.total,
        }}
        bordered
        loading={loading}
        dataSource={data.list}
        columns={columns}
        size="small"
        rowKey={record => record.id}
        scroll={{ x: 640 }}
      />
      <RoleModal
          open={openCreateModal}
          paging={paging}
          setOpenCreateModal={setOpenCreateModal}
          typeForm={typeForm}
          detailData={detailData ? detailData : null}
      />
    </>
  )
}

export default Permission
