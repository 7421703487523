import { Form, Input, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import { loadFunc } from '../../../services/bannerService';
const paramType = {
    text: 'text',
    number: 'number',
    select: 'select',
    radio: 'radio',
    checkbox: 'checkbox'
}
const SettingModal = ({ open, loading, func, onCancel, addFunc }) => {
    const [form] = Form.useForm();
    const [news, setNews] = useState([])
    const [lang, setLang] = useState('vi')

    const _funcLoad = async () => {
        if (func && func.params) {
            let obj = {};
            let result = [];
            for (const e of func.params) {
                if(e.name == 'title'){
                    obj[e.name] = {
                        vi:e.value.vi ? e.value.vi : func.displayName,
                        en:e.value.en ? e.value.en : func.displayName
                    }
                } else {
                    obj[e.name] = !e.value || e.value == '0' ? func.displayName : e.value;
                }
                if (e.type === 'select') {
                    const res = await loadFunc(e.api)
                    if (res.data && res.data.code === 200) {
                        e.options = res.data.data.map(item => {
                            return {
                                label: item.name,
                                value: item.id.toString(),
                            }
                        });
                        obj[e.name] = e.value == '0' ? '0' :e.value;
                    }
                }
                result.push({
                    name: e.name,
                    title: e.title,
                    type: e.type,
                    value: e.value,
                    api: e.api ? e.api : '',
                    options: e.options ? e.options : [],
                });
            }
            setNews(result)
            form.setFieldsValue(obj)
        }
    }

    const changeValue = async (index, value) => {
        if (func && func.params) {
            func.params[index].value = value;
        }
    }

    const changeTitleValue = async (index, value) => {
        if (func && func.params) {
            func.params[index].value[lang] = value;
        }
    }

    const submit = () => {
        addFunc(func.params)
        onCancel()
    }

    const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select style={{ width: 70 }} defaultValue={lang} onChange={(value) => setLang(value)}>
            <Select.Option value="vi">VI</Select.Option>
            <Select.Option value="en">EN</Select.Option>
          </Select>
        </Form.Item>
      );

    useEffect(() => {
        _funcLoad()
    }, [func]);

    return (
        <Modal
            open={open}
            title={`Tùy chỉnh cho chức năng ${func && func.displayName ? func.displayName : ''}`}
            okText="Xác nhận"
            cancelText="Hủy"
            onCancel={async () => { onCancel(); await form.resetFields() }}
            loading={loading}
            width={400}
            onOk={() => { submit() }}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                className="gap-5"
            >
                {
                    news && news.map((item, index) => (
                        <div key={index}>
                            {item.type === paramType.text && item.name === 'title' ?
                                <Form.Item name={[item.name,lang]} label={item.title} style={{marginBottom:'0px'}} rules={[{ required: true}]}>
                                    <Input addonBefore={prefixSelector} style={{ width: '100%' }} onInput={(e) => { changeTitleValue(index, e.target.value) }}/>
                                </Form.Item>
                                : ""}
                            {item.type === paramType.text && item.name !== 'title' ?
                                    <Form.Item
                                        name={item.name}
                                        label={item.title}
                                        rules={[{ required: true, }]}
                                    >
                                        <Input placeholder={`Nhập ${item.title}`} onInput={(e) => { changeValue(index, e.target.value) }} />
                                    </Form.Item>
                            : ""}
                            {item.type === paramType.select ?
                                <Form.Item
                                    name={item.name}
                                    label={item.title}
                                    rules={[{ required: true, }]}
                                >
                                    <Select
                                        style={{ width: '100%' }}
                                        onChange={(e) => { changeValue(index, e) }}
                                        options={item.options}
                                    />
                                </Form.Item>
                                : ""
                            }
                        </div>
                    ))
                }
            </Form>
        </Modal>
    );
};

export default SettingModal