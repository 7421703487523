
import { Tooltip } from 'antd';
import './theme.scss';

function Theme(props){
    const { data } = props;
    return (<>
        <div className={data && data.themeSelect.id === data.theme.id ? `theme-container active` : 'theme-container'}>
            <Tooltip placement="top" title={data  && data.theme && data.theme.displayName ? data.theme.displayName : data.theme.name}>
                <div className="theme" style={{gridAutoRows: `repeat(${data && data.theme && data.theme.container &&  data.theme.container.rows && data.theme.container.rows.length ? data.theme.container.rows.length : 0}, 1fr)`}}>
                        {
                            data && data.theme && data.theme.container && data.theme.container.rows.map((row,index) => {
                            return (
                                <div className="row no-margin theme-row" key={index}>
                                    {
                                        row.cols.map((col,index) => {
                                            return <div className={`no-padding theme-col ${[`col-${col.col}`]}`} key={index}>
                                            <div className={!col.functions ? 'item no-edit' : 'item'}></div>
                                        </div>
                                        })
                                    }
                                </div>
                            )
                            })
                        }
                </div>
            </Tooltip>
        </div>
    </>)
}
export default Theme