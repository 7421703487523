import {  IdcardOutlined, KeyOutlined, MailOutlined, PhoneOutlined, UserAddOutlined, UserOutlined, UserSwitchOutlined } from '@ant-design/icons';
import { DatePicker, Form, Input, Modal, Radio, Select } from 'antd';
import AntdImgCrop from 'antd-img-crop';
import Upload from 'antd/es/upload/Upload';
import { useState } from 'react';

const CreateUserModal = ({ open, onCreate, onCancel,loading,permission }) => {
  const [form] = Form.useForm();
  
  /* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: '${label} không được để trống',
  types: {
    email: '${label} phải là một email!',
  }
};
/* eslint-enable no-template-curly-in-string */

const [fileList, setFileList] = useState([]);
const onChange = ({ fileList: newFileList }) => {
  setFileList(newFileList);
  console.log(newFileList)
};
const onPreview = async (file) => {
  let src = file.url;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow?.document.write(image.outerHTML);
};

  return (
    <Modal
      open={open}
      title="Tạo người dùng mới"
      okText="Tạo"
      cancelText="Hủy"
      onCancel={onCancel}
      loading ={loading}
      width={900}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            onCreate(values);
          })
          .catch((info) => {
            console.log('Validate Failed:', info);
          });
      }}
    >
      <div className='md:flex md:flex-row flex-col-reverse justify-between gap-5 '>
        <div>
          <AntdImgCrop  rotationSlider>
              <Upload
                listType="picture-circle"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
              >
                {fileList.length < 1 && '+ Upload'}
              </Upload>
          </AntdImgCrop> 
        </div>
        <div className='w-full'>
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            initialValues={{ gender: 0 }}
            validateMessages={validateMessages}
            className="flex  gap-5"
          >
            <div className='w-1/2'>
                <Form.Item
                  name="username"
                  label="Tên người dùng"
                  rules={[{ required: true, }]}
                >
                <Input size="large" placeholder="Điền tên người dùng" prefix={<UserOutlined />} />
                </Form.Item>
                <Form.Item
                  name="code"
                  label="Mã nhân viên"
                  rules={[{ required: true, }]}
                >
                <Input size="large" placeholder="Điền mã nhân viên" prefix={<UserSwitchOutlined />} />
                </Form.Item>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      type: 'email' 
                    },
                  ]}
                >
                <Input size="large" placeholder="Điền địa chỉ email" prefix={<MailOutlined />} />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="Mật khẩu"
                  rules={[{ required: true,
                            pattern:new RegExp(/^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/),
                            message:"Mật khẩu phải ít nhất 8 kí tự bao gồm 1 chữ hoa, 1 kí tự đặt biệt và 1 số"
                  }]}
                >
                <Input.Password size="large" placeholder="Điền mật khẩu" prefix={<KeyOutlined />} />
                </Form.Item>
                <Form.Item
                  name="phoneNumber"
                  label="Số điện thoại"
                  rules={[{ required: true, }]}
                >
                  <Input size="large" placeholder="Điền số điện thoại" prefix={<PhoneOutlined />} />
                  </Form.Item> 
                <Form.Item name="gender" 
                  label="Giới tính" className="collection-create-form_last-form-item">
                    <Radio.Group>
                      <Radio value={0}>Nam</Radio>
                      <Radio value={1}>Nữ</Radio>
                    </Radio.Group>
                </Form.Item>  
            </div>
            <div className='w-1/2'>
              <Form.Item
                    name="lastName"
                    label="Họ"
                    rules={[{ required: true, }]}
                  >
                    <Input size="large" placeholder="Điền họ" prefix={<UserAddOutlined />} />
              </Form.Item> 
              <Form.Item
                    name="firstName"
                    label="Tên"
                    rules={[{ required: true, }]}
                  >
                    <Input size="large" placeholder="Điền tên" prefix={<UserAddOutlined />} />
              </Form.Item> 
              <Form.Item
                    name="addressLine1"
                    label="Địa chỉ"
                    rules={[{ required: true, }]}
                  >
                    <Input size="large" placeholder="Điền địa chỉ" prefix={<IdcardOutlined />} />
              </Form.Item> 
              <Form.Item name="birthday" label="Ngày sinh" rules={[{ required: true, }]}>
                <DatePicker   className='w-full'  placeholder='Chọn ngày sinh' size='large' />
              </Form.Item>
              <Form.Item
                name="status"
                label="Trạng thái"
                rules={[{ required: true, }]}
              >
                <Select size='large' placeholder="Chọn trạng thái">
                  <Select.Option value="ACTIVATE">Kích hoạt</Select.Option>
                  <Select.Option value="DEACTIVATE">Hủy kích hoạt</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                      name="groupRoleId"
                      label="Nhóm quyền"
              >
                      <Select size='large' options={permission} placeholder="Chọn nhóm phân quyền" >   
                      </Select>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
      
    </Modal>
  );
};

export default CreateUserModal