import { DeleteOutlined, EnterOutlined, InfoCircleOutlined, SaveOutlined } from '@ant-design/icons'
import { Form, notification, Popconfirm, Space, Button, Row, Col, Typography, Collapse, Input, Checkbox, Tabs, Modal, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createMenu, detailMenu, updateMenu } from '../../../services/menuService'
import '@nosferatu500/react-sortable-tree/style.css';
import SortableTree, { removeNodeAtPath, changeNodeAtPath } from '@nosferatu500/react-sortable-tree';
import { listPage } from '../../../services/pageService'
import { translate } from '../../../helper'

const options = [
  { label: 'Main Menu', value: 'main' },
  { label: 'Main Menu - Mobile', value: 'main-mobile' },
  { label: 'Footer Menu', value: 'footer' },
  { label: 'Top Bar Menu', value: 'top' },
];

function MenuDetail() {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [form2] = Form.useForm()
  const [form3] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [gData, setGData] = useState([]);
  const [page, setPage] = useState([]);
  const [infoRow, setInfoRow] = useState();
  const [checkedMenu, setCheckedMenu] = useState([]);
  const [checkedPosition, setCheckedPosition] = useState([]);
  const [lang, setLang] = useState('vi');

  const params = useParams();

  const _detail = async (id) => {
    try {
      setLoading(true)
      const res = await detailMenu(id)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      const { name, menus,position,actived } = res.data.data;
      const dataform = {
        name,
        actived,
        menus: {}
      }
      setGData(menus)
      if(position && position.length > 0){
        setCheckedPosition(position.split(","))
      }
      form.setFieldsValue(dataform)
    } catch (err) {
      console.log(err)
      notification.error({ message: err.message })
    } finally {
      setLoading(false)
    }
  }
  const submit = async () => {
    try {
      await form.validateFields();
      const { name,actived } = form.getFieldsValue();
      const body = {
        name,
        actived,
        children: gData,
        position:checkedPosition && checkedPosition.length > 0 ? checkedPosition.toString() : ''
      };
      if (!params.id) {
        const res = await createMenu(body)
        if (!res.data || res.data.code !== 200) {
          return notification.warning({
            message: res.data.message || 'Tạo menu thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
      }
      if (params.id) {
        body.id = parseInt(params.id)
        const res = await updateMenu(body)
        if (!res.data || res.data.code !== 200) {
          return notification.warning({
            message: res.data.message || 'Chỉnh sửa menu thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
      }
      navigate('/menu')
    } catch (err) {
      if (err.errorFields?.length > 0) {
        return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
      }
      console.log(err)
      notification.error({ message: err.message })
    } finally {
    }
  }

  const _listPage = async () => {
    const res = await listPage();
    if (!res.data || res.data.code !== 200) {
      return notification.warning({
        message: res.data.message,
      })
    }
    setPage(res.data.data);
  }

  const onChange = (checkedValues) => {
    setCheckedMenu(checkedValues)
  };

  const onChangePostion = (list) => {
    setCheckedPosition(list);
  };

  const addMenu = (type) => {
    if (type !== 'link') {
      const data = [];
      checkedMenu.forEach((item) => {
        page.some((item2) => {
          if (item2.id === item) {
            item2.title = item2.name
            item2.menuLang = {};
            for (let i = 0; i < ChooseTab.length; i++) {
              item2.menuLang[ChooseTab[i].key] = {
                name: item2.name,
                urlLink: item2.pageLang[ChooseTab[i].key].urlLink,
                urlImage: '',
                langCode: ChooseTab[i].key
              }
            };
            data.push(item2)
          }
        })
      }
      )
      setGData([...gData, ...data])
    }
    if (type === 'link') {
      const { name, urlLink } = form3.getFieldValue()
      let obj = {
        title: name,
        menuLang: {}
      };
      for (let i = 0; i < ChooseTab.length; i++) {
        obj.menuLang[ChooseTab[i].key] = {
          name,
          urlLink,
          urlImage: '',
          langCode: ChooseTab[i].key
        }
      };
      setGData([...gData, ...[obj]])
      form3.resetFields()
    }
  };

  const changeTabs = (value) => {
    setLang(value)
  }

  const getNameLang = (value) => {
    if (value.menuLang && value.menuLang[lang] && value.menuLang[lang]) {
      return value.menuLang[lang].name;
    }
    return value.name;
  }

  const ChooseTab = [
    {
      label: 'Tiếng Việt',
      key: 'vi',
    },
    {
      label: 'Tiếng Anh',
      key: 'en',
    }
  ]

  const infoItemMenu = (value) => {
    const { menuLang } = value.node;
    form2.setFieldsValue(menuLang[lang]);
    setInfoRow(value);
    setOpen(true);
  }

  const submitEdit = () => {
    const { node, path } = infoRow;
    const { children } = node;
    form2.validateFields().then(res => {
      const { name, urlImage,urlLink } = form2.getFieldValue();
      node.menuLang[lang] = {
        name,
        urlLink,
        urlImage,
        langCode: lang
      }
      let newTree = changeNodeAtPath({
        treeData: gData,
        path,
        getNodeKey: ({ treeIndex }) => {
          return treeIndex;
        },
        newNode: {
          children: children ? children : [],
          title: name,
          menuLang: node.menuLang
        }
      });
      setGData(newTree)
      setOpen(false)
      form2.resetFields()
    })

  }

  const removeItemMenu = (rowInfo) => {
    let { path } = rowInfo;
    const data = removeNodeAtPath({
      treeData: gData,
      path: path,
      getNodeKey: ({ treeIndex }) => {
        return treeIndex;
      },
    });
    setGData(data)
  }

  useEffect(() => {
    _listPage();
  }, []);

  useEffect(() => {
    if (params.id) {
      _detail(params.id);
    }
  }, [params.id]);

  return (
    <div>
      <div className="flex justify-end items-center">
        <Space>
          <Popconfirm
            title={!params.id ? 'Xác nhận để tạo bài viết' : 'Xác nhận để chỉnh sửa bài viết'}
            okText="Yes"
            cancelText="No"
            onConfirm={() => submit()}
          >
            <Button type="primary" loading={loading}>
              <SaveOutlined />
              Xác nhận
            </Button>
          </Popconfirm>
          <Button danger onClick={() => navigate('/menu')}>
            <EnterOutlined />
            Quay lại</Button>
        </Space>
      </div>
      <Row gutter={20} className="p-4">
        <Col span={5}>
          <Typography.Title level={3}>Thêm liên kết</Typography.Title>
          <Collapse defaultActiveKey={['1']}>
            <Collapse.Panel header="Trang" key="1">
              <Checkbox.Group style={{ width: '100%', display: 'block' }} onChange={onChange}>
                {
                  page.map((item, index) => {
                    return (
                      <div key={index}>
                        <Checkbox value={item.id}>{item.name}</Checkbox>
                      </div>
                    )
                  })
                }
              </Checkbox.Group>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={() => addMenu('page')}>Thêm vào Menu</Button>
              </div>
            </Collapse.Panel>
            <Collapse.Panel header="Liên kết tự tạo" key="2">
              <Form
                form={form3}
                layout="vertical"
                name="form3"
                className="gap-5"
              >
                <Form.Item
                  name={'name'}
                  label={"Tên hiển thị"}
                  rules={[{ required: true, message: "Vui lòng nhập tên hiển thị" }]}
                >
                  <Input placeholder={"Nhập tên hiển thị"} />
                </Form.Item>
                <Form.Item
                  name={'urlLink'}
                  label={"Đường dẫn"}
                  rules={[{ required: true, message: "Vui lòng nhập đường dẫn" }]}
                >
                  <Input placeholder={"Nhập link"} />
                </Form.Item>
              </Form>
              <div className='mt-2' style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={() => addMenu('link')}>Thêm vào Menu</Button>
              </div>
            </Collapse.Panel>
          </Collapse>
        </Col>
        <Col span={14}>
          <Typography.Title level={3}>Thiết lập Menu</Typography.Title>
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            className="gap-5"
          >
              <Form.Item
                name={'name'}
                label={'Tên'}
                rules={[{ required: true, message: "Vui lòng nhập tên nhóm" }]}
              >
                <Input placeholder={`Nhập tên`} />
              </Form.Item>
              <Form.Item
                label="Trạng thái"
                name="actived"
                rules={[{ message: 'Vui lòng chọn trạng thái', required: true }]}
              >
                <Select
                  placeholder={'Chọn trạng thái'}
                  style={{ width: '100%' }}
                  options={[
                    {
                      value: 1,
                      label: 'Hiển thị',
                    },
                    {
                      value: 0,
                      label: 'Ẩn hiển thị',
                    },
                  ]}
                />
              </Form.Item>
              {
                gData && gData.length > 0 ? <>
                  <Tabs
                    type="card"
                    defaultActiveKey="vi"
                    items={ChooseTab}
                    onChange={(value) => changeTabs(value)}
                  />
                  <div style={{height: "70vh"}}>
                  <SortableTree
                    treeData={gData}
                    onChange={treeData => setGData(treeData)}
                    generateNodeProps={(rowInfo) => ({
                      title: getNameLang(rowInfo.node),
                      buttons: [
                        <Space>
                          <Popconfirm
                            title="Bạn có muốn xóa mục này?"
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => removeItemMenu(rowInfo)}
                          >
                            <Button danger >
                              <DeleteOutlined />
                            </Button>
                          </Popconfirm>

                          <Button onClick={() => infoItemMenu(rowInfo)}>
                            <InfoCircleOutlined />
                          </Button>
                        </Space>
                      ],
                      style: {
                        height: "50px",
                      }
                    })}
                  /></div></> : "Thêm các mục từ cột bên trái."
              }
          </Form>
          <Modal
            open={open}
            title={translate("Chỉnh sửa", lang)}
            okText={translate("Xác nhận", lang)}
            cancelText={translate("Hủy", lang)}
            onCancel={() => {
              setOpen(false);
              form2.resetFields()
            }
            }
            onOk={() => submitEdit()}
            width={400}
            className="top-5"
          >
            <Form
              form={form2}
              layout="vertical"
              name="form"
              className="gap-5"
            >
              <Form.Item
                name={'name'}
                label={translate("Tên hiển thị", lang)}
                rules={[{ required: true, message: translate("Vui lòng nhập tên hiển thị", lang) }]}
              >
                <Input placeholder={translate("Nhập tên hiển thị", lang)} />
              </Form.Item>
              <Form.Item
                name={'urlLink'}
                label={translate("Đường dẫn", lang)}
                rules={[{ required: true, message: translate("Vui lòng nhập đường dẫn", lang) }]}
              >
                <Input placeholder={translate("Nhập link", lang)} />
              </Form.Item>
              {/* <Form.Item
                name={'urlImage'}
                label={translate("Hỉnh ảnh", lang)}
              >
                <Input placeholder={`Nhập tên`} />
              </Form.Item> */}
            </Form>
          </Modal>
        </Col>
        <Col span={5}>
          <Typography.Title level={3}>Hiển thị vị trí</Typography.Title>
          <Checkbox.Group options={options} value={checkedPosition} style={{
              width: '100%',
              display: 'block'
            }} onChange={onChangePostion} />
        </Col>
      </Row>
    </div>
  )
}

export default MenuDetail