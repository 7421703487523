import { DeleteFilled, EditFilled, LayoutFilled, PlusOutlined } from '@ant-design/icons'
import { notification, Popconfirm, Space, Table, Tag, Button, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Theme from '../../components/Theme'
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../config/pageSize'
import { deletePage, getArrangement, getPagingPage } from '../../services/pageService'
import SettingModal from './Setting'

function PageLayout() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [pages, setPages] = useState([])
  const [countPage, setcountPage] = useState(0)
  const [paramsFilter, setParamsFilter] = useState({ amount: PAGE_SIZE, page: 1, status: 'ALL' })
  const [themes, setTheme] = useState([]);

  const _getPagings = async () => {
    try {
      setLoading(true)
      const res = await getPagingPage(paramsFilter)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      const { list, total } = res.data.data;
      setPages(list)
      setcountPage(total)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  const _deletePage = async (id) => {
    try {
      const res = await deletePage({ id: id[0] })
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      _getPagings()
      notification.success({ message: res.data.message })
    } catch (err) {
      console.log(err)
    }
  }

  const _getArrangement = async () => {
    try {
      const res = await getArrangement()
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      setTheme(res.data.data.map(e => {
        e.container = JSON.parse(e.container);
        return e;
      }))
    } catch (err) {
      console.log(err)
    }
  }

  const _getTheme = (id) => {
    const theme = themes.find(e => e.id === id);
    if (theme) {
      return theme;
    }
    return false;
  }

  useEffect(() => {
    _getArrangement()
  }, []);

  useEffect(() => {
    _getPagings()
  }, [paramsFilter]);

  const columns = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      align: 'center',
      key: 'index'
    },
    {
      title: 'Tên',
      align: 'center',
      dataIndex: 'name',
      render: (text) => text,
    },
    {
      title: 'Theme',
      align: 'center',
      dataIndex: 'layoutId',
      render: (text, record, index) => (
        <Theme key={index} data={{ theme: _getTheme(record.layoutId), themeSelect: {} }} />
      ),
    },
    {
      title: 'Trạng thái',
      align: 'center',
      render: (text, record) => (
        <div style={{ fontWeight: 'bold' }}>
          <Tag color={record.actived ? 'success' : 'default'}>
            {record.actived ? 'Kích hoạt' : 'Hủy kích hoạt'}
          </Tag>
        </div>
      ),
    },
    {
      title: 'Trang mặc định',
      align: 'center',
      render: (text, record) => (
        <div style={{ fontWeight: 'bold' }}>
          <Tag color={record.isDefault ? 'success' : 'default'}>
            {record.isDefault ? 'Mặc định' : 'Hủy mặc định'}
          </Tag>
        </div>
      ),
    },
    {
      title: 'Hành động',
      align: 'center',
      render: (text, record) => (
        <Space className="action">
          <Tooltip placement="top" title={'Bố cục trang'}>
            <Button onClick={() => navigate(`/page/body/${record.id}`)}>
              <LayoutFilled />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={'Chi tiết trang'}>
            <Button onClick={() => { navigate(`/page/update/${record.id}`) }}>
              <EditFilled />
            </Button>
          </Tooltip>
          <Tooltip placement="top" title={'Xóa'}>
            <Popconfirm
              title="Bạn có muốn xóa trang này?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => _deletePage([record.id])}
            >
              <Button danger>
                <DeleteFilled />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      )
    },
  ]

  return (
    <div className='mt-4'>
      <Button onClick={() => { navigate('/page/create') }}>
        <PlusOutlined />
      </Button>
      <Table
        dataSource={pages}
        pagination={{
          position: ['bottomCenter'],
          current: paramsFilter.page,
          pageSize: paramsFilter.amount,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, page_size: pageSize })
          },
          total: countPage,
        }}
        loading={loading}
        columns={columns}
        className="mt-4"
        bordered
        scroll={{ x: 640 }}
        rowKey={record => record.id}

      ></Table>
    </div>
  )
}

export default PageLayout