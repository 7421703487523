import { get, post, destroy,put } from '../apis/httpClient';
const ROUTER = {
    LOGIN:'/api/v1/auth/login',
    LOGOUT:'/api/v1/auth/logout',
    UPDATE_PASSWORD:'/api/v1/auth/update-password',
    RESET_PASSWORD:'/api/v1/auth/reset-password',
    FORGOT_PASSWORD: '/api/v1/auth/forgot-password',
    REFRESH:'/api/v1/user/me',
}
export const login = (body) => post(ROUTER.LOGIN, body);
export const logout = () => destroy(ROUTER.LOGOUT);
export const updatePassword = (body) => put(ROUTER.UPDATE_PASSWORD, body);
export const resetPassword = (body) => put(ROUTER.RESET_PASSWORD, body)
export const forgotPassword = (body) => post(ROUTER.FORGOT_PASSWORD, body)
export const refresh = () => get(ROUTER.REFRESH);