import { Button, Col, Dropdown, Modal, notification, Popconfirm, Row, Space, Table, Tabs, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Theme from '../../../components/Theme'
import { getArrangement, getListFunction, getListModule, getDetail, updateLayout, backupLayout, resetLayout, pagingHistoryLayout, deleteHistoryLayout } from '../../../services/pageService'
import './body.scss';
import { ReactSortable } from "react-sortablejs";
import { CopyOutlined, DeleteFilled, DeleteOutlined, EditOutlined, EllipsisOutlined, EnterOutlined, RedoOutlined, SaveOutlined } from '@ant-design/icons'
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../../config/pageSize'
import moment from 'moment'
import SettingModal from '../Setting'

function BodyLayout() {
  const navigate = useNavigate()
  const [themes, setTheme] = useState([]);
  const [themeSelect, setThemeSelect] = useState({});
  const [colData, setColData] = useState({});
  const [selectModuleIndex, setSelectModuleIndex] = useState(-1);
  const [selectModName, setSelectModName] = useState('');
  const [modules, setModules] = useState([]);
  const [funcs, setFuncs] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [dataDetail, setDataDetail] = useState({});
  const [open, setOpenModal] = useState(false);
  const params = useParams();
  const [paramsFilter, setParamsFilter] = useState({ page: 1, amount: PAGE_SIZE })
  const [dataHistory, setDataHistory] = useState([])
  const [openModal, setOpenModalSetting] = useState(false)
  const [funcsModal, setFuncsModal] = useState(null);
  const [indexs, setIndexParams] = useState({});
  const [lang, setLang] = useState('vi');

  const _getPartial = async (data) => {
    try {
      const res = await getDetail(params.id)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      const dataRes = res.data.data;
      setDataDetail(dataRes);
      let temp = data.find((e) => e.id === dataRes.layoutId);
      if (!temp || temp === undefined || temp === "undefined") {
        temp = JSON.parse(JSON.stringify(data[0]));
      } else {
        temp = JSON.parse(
          JSON.stringify(data.find((e) => e.id === dataRes.layoutId))
        );
      }
      temp.container.rows = temp.container.rows.map(row => {
        if (row.cols && row.cols.length) {
          row.cols = row.cols.map(col => {
            if (dataRes.data[col.id]) {
              col.functions = dataRes.data[col.id];
            }
            return col;
          });
        }
        return row;
      });
      setThemeSelect(temp)
      _changedFunction(temp);
    } catch (err) {
      console.log(err)
    }
  }

  const _getArrangement = async () => {
    try {
      const res = await getArrangement()
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      let data = []
      await Promise.all(data = res.data.data.map(e => {
        e.container = JSON.parse(e.container);
        return e;
      }));
      setTheme(data);
      _getPartial(data)
    } catch (err) {
      console.log(err)
    }
  }

  const _selectTheme = (value) => {
    setThemeSelect(_copyModuleData(value));
  }

  const _copyModuleData = (index) => {
    const newData = JSON.parse(JSON.stringify(themes[index]));
    newData.container.rows.forEach(row => {
      row.cols.forEach(col => {
        if (col.functions && colData[col.id]) {
          col.functions = JSON.parse(JSON.stringify(colData[col.id]));
        }
      });
    });
    return newData;
  }

  const _changedFunction = (value) => {
    setColData({ ...colData, ..._makeData(value) });
  }

  const _changedFunction2 = (value, indexRow, indexCol) => {
    let data = themeSelect;
    if (data) {
      data.container.rows[indexRow].cols[indexCol].functions = value;
      setThemeSelect(data)
      _changedFunction(data);
    }
  }

  const getName = (item) => {
    const par = item.params ? item.params.find((e) => e.name === "title") : undefined;
    if (par && par.value && par.value[lang].length > 0) {
      return par.value[lang];
    }
    return item.displayName ? item.displayName : item.name;
  }

  const searchValueModule = () => {
    if (searchQuery && searchQuery.length > 0) {
      return modules.filter((item) => {
        return searchQuery
          .toLowerCase()
          .split(" ")
          .every((v) => item.name.toLowerCase().includes(v));
      });
    } else {
      return [...[], ...modules];
    }
  }

  const changedShowModule = (index) => {
    if (selectModuleIndex === index) {
      setSelectModuleIndex(-1)
      return;
    }
    setSelectModuleIndex(index)
    if (modules[index]) {
      setSelectModName(modules[index].name)
      _getFunctions(modules[index].id);
    }
  }

  const _getFunctions = async (id) => {
    const res = await getListFunction(id)
    if (!res.data || res.data.code !== 200) {
      return notification.warning({
        message: res.data.message,
      })
    }
    res.data.data = res.data.data.map((e) => {
      e.functionName = e.name;
      e.functionId = e.id;
      if (e.params && e.params.length && JSON.parse(e.params) && JSON.parse(e.params).length > 0) {
        e.params = JSON.parse(e.params).map((par) => {
          if(par.name === 'title'){
            par.value = {
              vi:e.displayName,
              en:e.displayName
            };
          } 
          if(par.name !== 'title'){
            par.value = parseFloat(par.value);
          }   
          return par;
        });
      }
      return e;
    });
    setFuncs(res.data.data)
    searchValueModule();
  }

  const _makeData = (value) => {
    const temp = {};
    if (value && value.container && value.container.rows && value.container.rows.length > 0) {
      value.container.rows.forEach(row => {
        row.cols.forEach(col => {
          if (col && col.functions) {
            temp[col.id] = col.functions.map(func => {
              if (func && func.params && func.params.length) {
                func.params = func.params.map(par => {
                  return par;
                });
              }
              return func;
            });
          }
        });
      });
      return temp;
    }
  };

  const _makeDataSubmit = (value) => {
    let temp = [];
    if (value && value.container && value.container.rows && value.container.rows.length > 0) {
      for (let i = 0; i < value.container.rows.length; i++) {
          const cols = value.container.rows[i].cols;
          for (let j = 0; j < cols.length; j++) {
            if(cols && cols[j] && cols[j].functions && cols[j].functions.length > 0){
              temp.push(...cols[j].functions.map((func,index) => {
                if (func && func.params && func.params.length) {
                  func.params = func.params.map(par => {
                    if(par.name === 'title'){
                    }else{
                      par.value = `${par.value}`;
                    }
                    return par;
                  });
                };
                return {
                    position:cols[j].id,
                    functionName : func.functionName,
                    displayName : func.displayName,
                    functionId : func.functionId,
                    isTotalItem : func.isTotalItem,
                    keyName : func.keyName,
                    layoutId : func.layoutId,
                    moduleId: func.moduleId,
                    moduleName : func.moduleName &&  func.moduleName.length ? func.moduleName : func.modules.name,
                    pageId : func.pageId,
                    pageName : func.pageName,
                    params : func.params,
                    totalItem : func.totalItem
                };
            }));
            }
          }
      }
      return temp;
    }
  }

  const _submit = async () => {
    const arrayData = [];
    const makeData = _makeDataSubmit(themeSelect);
    makeData.forEach(item => {
      let recordId = 0;
      let total = 0;
      let name = '';
      let keyName = '';
      if(item.params && item.params.length > 0){
        item.params.forEach(itemChill => {
          if (itemChill.type === "select" && itemChill.name === "id" && parseInt(itemChill.value) !== 0) {
            recordId = parseInt(itemChill.value);
          }
          if (itemChill.type === "text" && itemChill.name === "total") {
            total = parseInt(itemChill.value);
          }
          if (itemChill.type === "text" && itemChill.name === "title") {
            name = itemChill.value;
          }
          if (itemChill.type === "select" && (itemChill.name === "key" || itemChill.name === "layout")) {
            keyName = itemChill.value
          };
        });
      }
      const obj = {
        displayName: item.displayName,
        moduleName: item.moduleName,
        functionName: item.functionName ? item.functionName : item.name,
        position: item.position,
        pageId: dataDetail.id,
        moduleId: item.moduleId,
        functionId: item.functionId ? item.functionId : item.id,
        layoutId: themeSelect.id,
        recordId,
        isTotalItem: true,
        totalItem: total,
        params: item.params ? JSON.stringify(item.params) : '',
      }
      if(keyName && keyName.length > 0){
        obj.keyName = keyName;
      }
      arrayData.push(obj)
    })
    const body = {
      positionDisplay: arrayData,
      layoutId: themeSelect.id,
      id: dataDetail.id
    };
    const res = await updateLayout(body);
    if (!res.data || res.data.code !== 200) {
      return notification.warning({
        message: res.data.message,
      })
    }
    notification.success({
      message: res.data.message,
    })
  }

  const _backupLayout = async () => {
    try {
      const res = await backupLayout({ id: dataDetail.id })
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      notification.success({
        message: res.data.message,
      })
      _pagingHistory()
    } catch (err) {
      console.log(err)
    }
  }

  const _resetLayout = async () => {
    try {
      const res = await resetLayout({ id: selectedRowKeys[0] })
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      notification.success({
        message: res.data.message,
      })
      setOpenModal(false);
      window.location.reload();
    } catch (err) {
      console.log(err)
    }
  }

  const _getModules = async () => {
    try {
      const res = await getListModule()
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      setModules(res.data.data)
    } catch (err) {
      console.log(err)
    }
  }

  const removeFunc = async(index, row, col) => {
    let themTmp = themeSelect;
    themTmp.container.rows[row].cols[col].functions = themeSelect.container.rows[row].cols[col].functions.filter((e,i) => i !== index);
    setThemeSelect(themTmp);
    _changedFunction(themTmp)
  }

  const processShowSetting = async(index, row, col) => {
    const indexs = {
      row,
      col,
      index,
    }
    setIndexParams(indexs)
    addFunc(indexs);
  }

  const addFunc = async(obj) => {
    if (
      themeSelect.container.rows[obj.row].cols[obj.col].functions[
        obj.index
      ].params
    ) {
      const data = themeSelect.container.rows[obj.row].cols[obj.col].functions[obj.index];
      setFuncsModal(data)
      setOpenModalSetting(true);
    }
  }

  const changeDataModal = async(obj,params) => {
    if (
      themeSelect.container.rows[obj.row].cols[obj.col].functions[
        obj.index
      ].params
    ) {
      let themTmp = themeSelect;
      themTmp.container.rows[obj.row].cols[obj.col].functions[obj.index].params = params;
      setThemeSelect(themTmp);
      _changedFunction(themTmp)
    }
  }
  // Init table
  const [selectedRowKeys, setSelectedRowKeys] = useState(0)
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
    },
  };

  // Paging
  const _pagingHistory = async () => {
    try {
      paramsFilter.pageId = params.id
      setParamsFilter(paramsFilter)
      const res = await pagingHistoryLayout(paramsFilter)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({ message: res.data.message })
      }
      setDataHistory(res.data.data)
    } catch (error) {
      notification.error({ message: error.message })
    } finally {
    }
  }

  const _deleteHistoryLayout = async (id) => {
    try {
      const res = await deleteHistoryLayout({ id })
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      notification.success({
        message: res.data.message,
      })
      _pagingHistory();
    } catch (err) {
      console.log(err)
    }
  }

  const columnsTable = [
    {
      title: 'STT',
      render: (text, record, index) => index + 1,
      align: 'center',
    },
    {
      title: 'Tên',
      align: 'center',
      dataIndex: 'urlLink',
      render: (text) => text,
    },
    {
      title: 'Ngày sao lưu',
      align: 'center',
      dataIndex: 'createdAt',
      render: (text) => text && moment(text).format('YYYY-MM-DD hh:mm'),
    },
    {
      title: 'Hành động',
      align: 'center',
      render: (text, record) => (
        <Space className="action">
          <Tooltip placement="top" title={'Xóa'}>
            <Popconfirm
              title="Bạn có muốn xóa tệp này?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => _deleteHistoryLayout(record.id)}
            >
              <Button danger>
                <DeleteFilled />
              </Button>
            </Popconfirm>
          </Tooltip>
        </Space>
      )
    },
  ]
  
  const TABS = [
    {
      label: 'Tiếng Việt',
      key: 'vi',
    },
    {
      label: 'Tiếng Anh',
      key: 'en',
    }
  ]

  useEffect(() => {
    _getArrangement()
    _getModules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    _pagingHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsFilter]);

  return (
    <>
      <div className="flex justify-end items-center px-2">
        <Space>
          <Popconfirm
            title="Xác nhận để sao lưu?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => _backupLayout()}
          >
            <Button type="primary"><CopyOutlined />Sao lưu</Button>
          </Popconfirm>
          <Button onClick={() => setOpenModal(true)} type="primary">
          <RedoOutlined />
            Khôi khục</Button>
          <Popconfirm
            title="Xác nhận để cập nhật trang?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => _submit()}
          >
            <Button type="primary">
            <SaveOutlined />
              Xác nhận</Button>
          </Popconfirm>
          <Button danger onClick={() => navigate('/page')}>
                <EnterOutlined />
                Quay lại</Button>
        </Space>
      </div>
      <Row>
        <Col span={18} push={6}>
        <Tabs
            onChange={(value) => setLang(value)}
            type="card"
            defaultActiveKey="1"
            items={TABS}
            className="mb-0"
          />
          <div className='bg-white md:ml-0 rounded-xl p-4' style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}>
            <h4 className="text-center my-4 font-bold">{dataDetail.name}</h4>
            <div className="themes-container">
              {
                themes.map((item, index) => {
                  return <div onClick={() => _selectTheme(index)} key={item.id}><Theme key={item.id} data={{ theme: item, themeSelect }}></Theme></div>
                })
              }
            </div>
            <div className="col-custom mt-4">
              <div className="left">
                {
                  themeSelect && themeSelect.container && themeSelect.container.rows &&
                  themeSelect.container.rows.map((item, indexRow) => {
                    return (
                      <div className="p-grid flex" key={indexRow}>
                        {
                          item.cols.map((col, indexCol) => {
                            return (
                              <div className={col.col ? 'p-col-' + col.col : 'p-col'} key={indexCol}>
                                {
                                  col.functions ?
                                    <div style={{ height: '100%' }}>
                                      <div className="mt-1 fw-600">{col.title}({col.id})</div>
                                      <div className="d-flex jc-center w-100 mt-1 drop-content">
                                        <div className="content-here w-100">
                                          <div className='list-group w-full h-full'>
                                            <ReactSortable
                                              className='group w-full h-full'
                                              group='shared'
                                              animation={200}
                                              delayOnTouchStart={true}
                                              delay={2}
                                              list={col.functions ? col.functions : []}
                                              setList={(value) => { _changedFunction2(value, indexRow, indexCol) }}>
                                              {col.functions.map((item, index) => (
                                                <div className="p-grid flex" key={index}>
                                                  <div className="draggableItem function-item" >
                                                    <div className="btn-group w-100">
                                                      <div
                                                        className="btn btn-secondary dropdown-toggle func-item"
                                                      >
                                                        <div>
                                                          <div>{getName(item) == '0' ? item.displayName  : getName(item)}</div>
                                                        </div>
                                                        <Dropdown
                                                          menu={{items:[
                                                            {
                                                              key: '1',
                                                              label: (
                                                                <div className="action" onClick={ () => { processShowSetting(index, indexRow, indexCol) }}>
                                                                  <Space>
                                                                    <EditOutlined />
                                                                    <span>Tùy chỉnh</span>
                                                                  </Space>
                                                                </div>
                                                              ),
                                                            },
                                                            {
                                                              key: '2',
                                                              label: (
                                                                <div className="action" onClick={() => removeFunc(index, indexRow, indexCol)}>
                                                                  <Space>
                                                                    <DeleteOutlined />
                                                                    <span>Xóa</span>
                                                                  </Space>
                                                          
                                                                </div>
                                                              ),
                                                            },
                                                          ]}}
                                                          placement="bottom"
                                                          arrow
                                                          trigger={'click'}
                                                        >
                                                          <EllipsisOutlined />
                                                        </Dropdown>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                            </ReactSortable>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    : ''
                                }
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </Col>
        <Col span={6} pull={18}>
          <div className='bg-white ml-2 md:ml-0 mt-2  rounded-xl mr-2 p-4' style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}>
            <h4 className="text-center">
              Danh sách Module
            </h4>
            <div className="list-module-group">
              <div>
                {
                  searchValueModule().map((item, index) => {
                    return (
                      <div key={index} className={index === selectModuleIndex ? 'list-module cp active' : 'list-module cp'} onClick={() => changedShowModule(index)}>
                        <button
                          type="button"
                          className="btn btn-secondary waves-effect waves-light cp"
                        >
                          {item.displayName ? item.displayName : item.name}
                        </button>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </div>
          {
            selectModuleIndex >= 0 ?
              <div className='bg-white ml-2 md:ml-0 mt-2  rounded-xl mr-2 p-4' style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}>
                <div >
                  <h4 className="mt-15px text-center">
                    Chức năng của {selectModName}
                  </h4>
                  <ReactSortable
                    className="list-module-group"
                    animation={200}
                    delayOnTouchStart={true}
                    delay={2}
                    list={funcs}
                    setList={setFuncs}
                    group={{
                      name: "shared",
                      pull: "clone",
                      put: false
                    }}
                  >
                      {funcs.map((item, index) => (
                        <div className="list-module" key={index}>
                          <button
                            type="button"
                            className="btn btn-secondary waves-effect waves-light"
                            style={{ width: '100%', height: '28px', display: 'flex', alignItems: 'center', cursor: 'move' }}
                          >
                            {item.displayName ? item.displayName : item.name}
                          </button>
                        </div>
                      ))}
                  </ReactSortable>
                </div>
              </div>
              : ''
          }
        </Col>
      </Row>
      <SettingModal open={openModal} onCancel={() => {setOpenModalSetting(false)}} func={funcsModal ? funcsModal : null} addFunc={(value)=> changeDataModal(indexs,value)}></SettingModal>
      <Modal
        open={open}
        title="Lịch sử sao sưu"
        okText="Xác nhận"
        cancelText="Hủy"
        onCancel={() => {
          setOpenModal(false);
        }
        }
        onOk={() => _resetLayout()}
        width={900}
        className="top-5"
      >
        <Table
          pagination={{
            position: ['bottomLeft'],
            current: paramsFilter.page,
            pageSize: paramsFilter.amount,
            pageSizeOptions: PAGE_SIZE_OPTIONS,
            showQuickJumper: true,
            onChange: (page, pageSize) => {
              setParamsFilter({ ...paramsFilter, page: page, amount: pageSize })
            },
            total: dataHistory.total,
          }}
          rowKey={(record) => record.id}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
          dataSource={dataHistory.list}
          columns={columnsTable}
          size="small"
        />
      </Modal>
    </>
  )
}

export default BodyLayout