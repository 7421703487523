const routes ={
    home:'/',
    login:'/login',
    resetPassword:'/reset-password',
    forgotPassword:'/forgot-password',
    user:'/user',
    permission:'/permission',
    //Introduce
    introduce: 'introduce',
    introduceCreate: 'introduce/create',
    introduceUpdate: 'introduce/update/:id',
    //Banner
    banner:'/banner',
    bannerCreate:'/banner/create',
    banerUpdate:'/banner/update/:id',
    //Body
    page:'/page',
    pageCreate:'/page/create',
    pageDetail:'/page/update/:id',
    body:'/page/body/:id',
    //BLog
    post:'/post',
    postUpdate:'/post/update/:id',
    postCreate:'/post/create',
    //Category
    category:'/category',
    categoryCreate:'/category/update/:id',
    categoryUpdate:'/category/create',
    //File
    file:'/file',
    //Setting
    setting:'/setting',
    //BLog
    block:'/block',
    blockUpdate:'/block/update/:id',
    blockCreate:'/block/create',
    //Menu
    menu:'/menu',
    menuCreate:'/menu/create',
    menuUpdate:'/menu/update/:id',
    //Slider
    slider:'/slider',
    sliderCreate:'/slider/create',
    sliderUpdate:'/slider/update/:id',
    //Area
    areaFactory:'/area',
    areaFactoryCreate:'/area/create',
    areaFactoryUpdate:'/area/update/:id',
    //Factory
    factory:'/factory',
    factoryCreate:'/factory/create',
    factoryyUpdate:'/factory/update/:id',
    //Factory
    recruitment:'/recruitment',
    recruitmentCreate:'/recruitment/create',
    recruitmentUpdate:'/recruitment/update/:id',
    //Factory
    contact:'/contact',
    contactUpdate:'/contact/update/:id',
    //Factory
    candidate:'/candidate',
    //Statistic
    statistic:'/statistic',
    statisticCreate:'/statistic/create',
    statisticUpdate:'/statistic/update/:id',
    //Product
    product:'/product',
    productCreate:'/product/create',
    productUpdate:'/product/update/:id',
    //categoryProduct
    categoryProduct:'/category-product',
    categoryProductCreate:'/category-product/create',
    categoryProductUpdate:'/category-product/update/:id',
    //collection
    collection:'/collection',
    collectionCreate:'/collection/create',
    collectionUpdate:'/collection/update/:id',
    //emailRegister
    emailRecruitment:'/email-recruitment',
    emailRecruitmentUpdate:'/email-recruitment/create',
    emailRegister:'/email-register',
    emailRegisterUpdate:'/email-register/create',
    //File
    introduce:'/introduce',
    notFound:'*'
};

export default routes;