import { DeleteOutlined, DownloadOutlined, EnterOutlined, SaveOutlined } from '@ant-design/icons'
import { Form, Input, notification, Popconfirm, Space, Button, Row, Col, Tabs, Card, Select, Image } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { processedSlug, translate } from '../../../helper'
import { createPage, detailPage, updatePage } from '../../../services/pageService'
import MediaModal from '../../../components/Modal/ModalMedia'
import { ImageDefault } from '../../../config/const'

function PageDetail() {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [dataForm, setDataForm] = useState({})
    const [loading, setLoading] = useState(false)
    const [hostName, setHostName] = useState('')
    const [seo, setSeo] = useState({})
    const [isModalVisible, setModalVisible] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [urlImage, setUrlImage] = useState("");
    const [metaImage, setMetaImage] = useState({
        en:"",
        vi:""
    });

    const params = useParams();

    const _detail = async (id) => {
        try {
            setLoading(true)
            const res = await detailPage(id)
            if (!res.data || res.data.code !== 200) {
                return notification.warning({
                    message: res.data.message,
                })
            }
            const { actived, name, pageLayoutsLangs, createdAt, isDefault, layoutId, header, footer,urlImage } = res.data.data;
            const dataform = {
                actived,
                name,
                pageLayoutsLangs: {},
                createdAt,
                isDefault,
                layoutId,
                header,
                footer,
                urlImage
            }
            let obj = {};
            for (let i = 0; i < pageLayoutsLangs.length; i++) {
                const { langCode } = pageLayoutsLangs[i];
                dataform.pageLayoutsLangs[langCode] = pageLayoutsLangs[i];
                obj[langCode] = pageLayoutsLangs[i].seoImage;
            }
            setMetaImage(obj);
            setUrlImage(urlImage)
            setDataForm(dataform)
            form.setFieldsValue(dataform)
        } catch (err) {
            console.log(err)
            notification.error({ message: err.message })
        } finally {
            setLoading(false)
        }
    }

    const submit = async () => {
        try {
            await form.validateFields();
            const { actived, name, pageLayoutsLangs, layoutId,isDefault } = form.getFieldsValue();
            const body = {
                name,
                layoutId,
                actived,
                isDefault,
                pageLayoutsLangs: []
            };
            let isCheckTab = true;
            ['en','vi'].forEach(item => {
                if(!pageLayoutsLangs[item]){
                    isCheckTab = false
                }
            })
            if(!params.id && !isCheckTab){
                return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
            }
            for (const item in pageLayoutsLangs) {
                pageLayoutsLangs[item].seoImage = metaImage[item];
                pageLayoutsLangs[item].langCode = item;
                pageLayoutsLangs[item].urlLink = pageLayoutsLangs['vi'].urlLink;
                if (params.id) { // type Update
                    pageLayoutsLangs[item].id = dataForm.pageLayoutsLangs[item].id;
                }
                body.pageLayoutsLangs.push(pageLayoutsLangs[item])
            };
            if(urlImage && urlImage.length > 0){
                body.urlImage = urlImage;
            }
            if (!params.id) {
                const res = await createPage(body)
                if (!res.data || res.data.code !== 200) {
                    return notification.warning({
                        message: res.data.message || 'Tạo trang thất bại vui lòng thử lại!',
                    })
                }
                notification.success({ message: res.data.message })
            };
            if (params.id) {
                body.id = parseInt(params.id)
                const res = await updatePage(body)
                if (!res.data || res.data.code !== 200) {
                    return notification.warning({
                        message: res.data.message || 'Chỉnh sửa trang thất bại vui lòng thử lại!',
                    })
                }
                notification.success({ message: res.data.message })
            };
            navigate('/page')
        } catch (err) {
            if (err.errorFields?.length > 0) {
                return notification.warning({ message: 'Vui lòng kiểm tra lại thông tin' })
            }
            notification.error({ message: err.message })
        } finally {
        }
    }

    const changeHeading = (value, langCode) => {
        form.setFieldValue(['pageLayoutsLangs', langCode, 'urlLink'], processedSlug(value.target.value));
    }

    const blurHeading = (value, langCode) => {
        let pageLayoutsLangs = {
            vi: {},
            en: {}
        }
        pageLayoutsLangs[langCode].name = value.target.value;
        setSeo(pageLayoutsLangs)
    }

    const handleMedia = (value) => {
        setSelectedMedia(value);
        setModalVisible(true)
    };

    const handleValueMedia = (value) => {
        if (selectedMedia.field === "meta_image") {
            let obj = {};
            obj[selectedMedia.lang] = value;
            setMetaImage({...metaImage, ...obj })
        }
    };

    const handleDeleteMedia = (value) => {
        if (value.field === "meta_image") {
            let obj = {};
            obj[value.lang] = "";
            setMetaImage({...metaImage, ...obj })
        }
    };

    useEffect(() => {
        if (params.id) {
            _detail(params.id);
        }
    }, [params.id]);

    useEffect(() => {
        setHostName(process.env.REACT_APP_DOMAIN)
    }, []);

    const ContentTab = ({ langCode }) => {
        return (
            <>
                <div>
                    <Form.Item
                        name={['pageLayoutsLangs', langCode, 'name']}
                        label={translate("Tiêu đề", langCode)}
                        rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}
                    >
                        <Input placeholder={translate("Nhập tiêu đề", langCode)} onInput={(value) => { changeHeading(value, langCode) }} onBlur={(value) => blurHeading(value, langCode)} />
                    </Form.Item>
                </div>
                <Card type="inner" title="Tối ưu SEO" bordered={false}>
                    <div>
                        {translate("Thiết lập các thẻ mô tả giúp khách hàng dễ dàng tìm thấy danh mục này trên công cụ tìm kiếm như Google.", langCode)}
                    </div>
                    <div>
                        Xem trước:
                        <div className='yoast-seo'>
                            <div className="sc-bSqbRM dlCkVf">
                                <span className="screen-reader-text" >
                                    Xem trước URL:</span>
                                <div className="sc-tAEdG joWiup">
                                    <div className="sc-tAEdG sc-dSfclH joWiup hgXshB">
                                        <div className="sc-JkiRB hHABJO">
                                            <img src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABs0lEQVR4AWL4//8/RRjO8Iucx+noO0MWUDo16FYABMGP6ZfUcRnWtm27jVPbtm3bttuH2t3eFPcY9pLz7NxiLjCyVd87pKnHyqXyxtCs8APd0rnyxiu4qSeA3QEDrAwBDrT1s1Rc/OrjLZwqVmOSu6+Lamcpp2KKMA9PH1BYXMe1mUP5qotvXTywsOEEYHXxrY+3cqk6TMkYpNr2FeoY3KIr0RPtn9wQ2unlA+GMkRw6+9TFw4YTwDUzx/JVvARj9KaedXRO8P5B1Du2S32smzqUrcKGEyA+uAgQjKX7zf0boWHGfn71jIKj2689gxp7OAGShNcBUmLMPVjZuiKcA2vuWHHDCQxMCz629kXAIU4ApY15QwggAFbfOP9DhgBJ+nWVJ1AZAfICAj1pAlY6hCADZnveQf7bQIwzVONGJonhLIlS9gr5mFg44Xd+4S3XHoGNPdJl1INIwKyEgHckEhgTe1bGiFY9GSFBYUwLh1IkiJUbY407E7syBSFxKTszEoiE/YdrgCEayDmtaJwCI9uu8TKMuZSVfSa4BpGgzvomBR/INhLGzrqDotp01ZR8pn/1L0JN9d9XNyx0AAAAAElFTkSuQmCC' alt="" className="sc-hYQoDq fTqTJk" />
                                        </div>
                                        <span className="sc-hZpJuv feGpeb">
                                            <div className="sc-jvvkMf gYNhjI">{hostName}
                                            </div><span className="sc-cHzrye bZphhk">{window.location.origin}</span> › {seo && seo.pageLayoutsLangs && seo.pageLayoutsLangs[langCode] ? seo.pageLayoutsLangs['vi'].urlLink : form.getFieldValue(['pageLayoutsLangs', 'vi', 'urlLink'])}</span>
                                    </div>
                                </div>
                                <span className="screen-reader-text">
                                    Xem trước tiêu đề SEO:</span>
                                <div className="sc-gIBoTZ gMwUgP">
                                    <div className="sc-fyrnIy sc-iWVLQz dwZvnc igrcjV">
                                        <span className="sc-bUbQrF fbVpha">{seo && seo.pageLayoutsLangs && seo.pageLayoutsLangs[langCode] ? seo.pageLayoutsLangs[langCode].name : form.getFieldValue(['pageLayoutsLangs', langCode, 'name'])} - {hostName}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="sc-bSqbRM dlCkVf">
                                <span className="screen-reader-text" >Xem trước mô tả:</span>
                                <div className="sc-dkQkSb gbobMG"><div className="sc-dkQkSb gbobMG">
                                    <div className="sc-jivCuG hcnbXe">
                                    </div><span className="sc-iWBMBB hThKVW">{params.id ? moment(dataForm.createdAt).format('MMM DD, YYYY') : moment().format('MMM DD, YYYY')} － </span>
                                    {seo && seo.pageLayoutsLangs && seo.pageLayoutsLangs[langCode] ? seo.pageLayoutsLangs[langCode].seoDescription : form.getFieldValue(['pageLayoutsLangs', langCode, 'seoDescription'])}
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Form.Item
                        name={['pageLayoutsLangs', 'vi', 'urlLink']}
                        label={translate("Đường dẫn", langCode)}
                        rules={[{ required: false }]}
                    >
                        <Input addonBefore="/" />
                    </Form.Item>
                    <Form.Item
                            label={translate("Meta hình ảnh (Kích thước tối thiểu 200px x 200px)",langCode)}
                            name="meta_image">
                    <Image 
                    src={metaImage && metaImage[langCode] && metaImage[langCode].length > 0 ? process.env.REACT_APP_API_ENDPOINT_DEV + metaImage[langCode] : ImageDefault} alt='AVP'
                    style={{width:'200px',height:'200px',background:'rgb(230, 230, 230)'}}/>
                    </Form.Item>
                       <Space>
                       <Button className='mb-6' onClick={() => handleMedia({ key: 'single', type: 'image', lang:langCode, field: 'meta_image' })} icon={<DownloadOutlined />} >
                            Upload
                        </Button>
                        <Button danger className='mb-6' onClick={() => handleDeleteMedia({ key: 'single', type: 'image', lang:langCode, field: 'meta_image' })} icon={<DeleteOutlined />} >
                            Remove
                        </Button>
                       </Space>
                    <Form.Item
                        name={['pageLayoutsLangs', langCode, 'seoTitle']}
                        label={translate("Tiêu đề trang", langCode)}
                        rules={[{ required: false }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={['pageLayoutsLangs', langCode, 'seoDescription']}
                        label={translate("Mô tả trang", langCode)}
                        rules={[{ required: false }]}
                    >
                        <Input.TextArea
                            showCount
                            maxLength={250}
                            style={{
                                height: 70,
                                resize: 'none',
                            }}
                            onBlur={(value) => blurHeading(value, langCode)}
                        />
                    </Form.Item>
                    <Form.Item
                        name={['pageLayoutsLangs', langCode, 'seoKeywords']}
                        label={translate("Seo từ khóa", langCode)}
                        rules={[{ required: false }]}
                    >
                        <Input />
                    </Form.Item>
                </Card>
            </>
        )
    }

    const TABS = [
        {
            label: 'Tiếng Việt',
            key: 'vi',
            children: <ContentTab langCode="vi"></ContentTab>
        },
        {
            label: 'Tiếng Anh',
            key: 'en',
            children: <ContentTab langCode="en"></ContentTab>
        }
    ]
    
    return (
        <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
        >
            <Row gutter={16}>
                <Col span={16}>
                    <Tabs
                        type="card"
                        defaultActiveKey="2"
                        items={TABS}
                    />
                </Col>
                <Col span={8}>
                    <div className="flex justify-end items-center mb-6">
                        <Space>
                            <Popconfirm
                                title={!params.id ? 'Xác nhận để tạo chuyên mục' : 'Xác nhận để chỉnh sửa chuyên mục'}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => submit()}
                            >
                                <Button type="primary" loading={loading}>
                                    <SaveOutlined />
                                    Xác nhận
                                </Button>
                            </Popconfirm>
                            <Button danger onClick={() => navigate('/page')}>
                                <EnterOutlined />
                                Quay lại</Button>
                        </Space>
                    </div>
                    <Form.Item
                        name="name"
                        label={"Tên"}
                        rules={[{ required: true, message: 'Vui lòng nhập tên' }]}
                    >
                        <Input placeholder={"Nhập tên"} />
                    </Form.Item>
                    <Form.Item
                        label="Trạng thái"
                        name="actived"
                        rules={[{ message: 'Vui lòng chọn trạng thái', required: true }]}
                    >
                        <Select
                            placeholder={'Chọn trạng thái'}
                            style={{ width: '100%' }}
                            options={[
                                {
                                    value: 1,
                                    label: 'Hiển thị',
                                },
                                {
                                    value: 0,
                                    label: 'Ẩn hiển thị',
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Mặc định"
                        name="isDefault"
                        rules={[{ message: 'Trang mặc định', required: true }]}
                    >
                        <Select
                            placeholder={'Chọn trang mặc định'}
                            style={{ width: '100%' }}
                            options={[
                                {
                                    value: 1,
                                    label: 'Mặc định',
                                },
                                {
                                    value: 0,
                                    label: 'Hủy mặc định',
                                },
                            ]}
                        />
                    </Form.Item>
                    {/* <Form.Item
                                label="Hình ảnh"
                                name="urlImage"
                            >
                            <img src={urlImage && urlImage.length > 0 ? process.env.REACT_APP_API_ENDPOINT_DEV + urlImage : 'error'} />
                        <Button onClick={() => handleMedia({ key: 'single', type: 'image', field: '' })} icon={<DownloadOutlined />} >
                        Upload
                        </Button>
                    </Form.Item> */}
                </Col>
            </Row>
            <MediaModal isModalVisible={isModalVisible} setModalVisible={setModalVisible} setValue={(value) => handleValueMedia(value[0].url)} selectedMedia={selectedMedia}></MediaModal>
        </Form>
    )
}

export default PageDetail