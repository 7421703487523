import { notification, List, Space, Button } from 'antd'
import { EnterOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { detailContact } from '../../../services/contactService'
import { useNavigate } from 'react-router-dom'
import './detail.scss'

function ContactDetail() {
  const [data, setData] = useState({})
  const navigate = useNavigate()
  const params = useParams();

  const _detail = async (id) => {
    try {
      const res = await detailContact(id)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      setData(res.data.data)
    } catch (err) {
      console.log(err)
      notification.error({ message: err.message })
    } finally {
    }
  }

  useEffect(() => {
    if (params.id) {
      _detail(params.id);
    }
  }, [params.id]);

  useEffect(() => {
  }, []);

  return (
    <>
      <div className="flex justify-end items-center">
        <Space>
          <Button danger onClick={() => navigate('/contact')}>
            <EnterOutlined />
            Quay lại</Button>
        </Space>
      </div>
      <table border="1" cellspacing="0" cellpadding="4" bordercolor="#cccccc" class="table table-hover table-danhsach w-full mt-4">
        <tbody>
          <tr> <td colspan="7" className='info'>
            <b>Thông tin liên hệ</b>
            </td> 
            </tr>
            <tr>
              <td colspan="3">
                Họ &amp; tên</td>
                <td colspan="4" width="400">
                  <span>
                   {data && data.contacts && data.contacts.name}
                   </span>
                    </td>
                  </tr>
                  <tr>
                <td colspan="3" >
                  Số điện thoại</td>
                <td colspan="4" width="400">
                  <span>
                  {data && data.contacts && data.contacts.phoneNumber}
                    </span>
                    </td>
                    </tr>
                    <tr>
                      <td colspan="3" >
                        Email
                        </td>
                    <td colspan="4" width="400">
                      <span>
                      {data && data.contacts && data.contacts.email}
                        </span>
                        </td>
                        </tr>
                        <tr>
                          <td colspan="3" >
                            Nội dung liên hệ</td><td colspan="4" width="400">
                             <span>
                             {data && data.contacts && data.content}
                             </span>
                             </td>
                             </tr>
        </tbody></table>
    </>
  )
}

export default ContactDetail