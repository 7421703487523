import React from 'react'
//antd
import { Tabs } from 'antd'
//components
import DisplayMedia from '../../components/Modal/ModalMedia/Detail/Display/index'
import UploadMedia from '../../components/Modal/ModalMedia/Detail/Upload/index'

function File() {
    const ChooseTab = [
        {
            icon: '',
            label: 'Tải file',
            key: 'Upload',
            children: <UploadMedia></UploadMedia>
        },
        {
            icon: '',
            label: 'Media',
            key: 'Display',
            children: <DisplayMedia isShowButton={false}></DisplayMedia>
        }
    ]
  return (
    <>
      <div className='mt-4'>
        <Tabs
            type="card"
            defaultActiveKey="2"
            items={ChooseTab}
        />
      </div>
    </>
  )
}

export default File
