import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
//antd
import { DeleteFilled, EditFilled, PlusOutlined } from '@ant-design/icons'
import { Button, Image, Input, notification, Popconfirm, Space, Table, Tag, Tooltip } from 'antd'
//support
import moment from 'moment'
import { PAGE_SIZE, PAGE_SIZE_OPTIONS } from '../../config/pageSize'
//service
import { deleteBanner, pagingBanner } from '../../services/bannerService'
import { ACTION, ImageDefault, MODULES } from '../../config/const'
import { checkAccessRole } from '../../helper'
import { useSelector } from 'react-redux'

function Banner() {
//state
  const [pages, setPages] = useState([])
  const [loading, setLoading] = useState(false)
  const [paramsFilter, setParamsFilter] = useState({ amount: PAGE_SIZE, page: 1, status: 'ALL' })
  let timeOut = 500
  const roles = useSelector((state) => state.permission).data;

//router
  const navigate = useNavigate()
  
//action
const onConfirm = async (id) =>{
  try{
    setLoading(true)
    const res = await deleteBanner({ id: id })
    if(!res.data || res.data.code !== 200){
      return notification.warning({
        message: res.data.message,
      })
    }
    paging()
    setLoading(false)
    notification.success({ message: res.data.message })
  }catch(err){
    console.log(err)
    notification.error({ message: err.message })
  }
 
}

//search
const searchPaging = async( search = false) =>{
      clearTimeout(timeOut)
      timeOut = setTimeout(()=>{
        paramsFilter.search = search.target.value
        setParamsFilter(paramsFilter)
        paging(paramsFilter)
      },timeOut)
}

//service
const paging = async () => {
    try{
      setLoading(true)
      const res = await pagingBanner(paramsFilter)
      if (!res.data || res.data.code !== 200) {
        return notification.warning({
          message: res.data.message,
        })
      }
      setPages(res.data.data)
      setLoading(false)
    }
    catch(error){
      notification.error({ message: error.message })
    }finally{
      setLoading(false)
    }
}

//effect
useEffect(() => {
  paging()
}, [paramsFilter])

//colunms
const columns = [
  {
    title: 'STT',
    align: 'center',
    render: (text, record, index) => index + 1,
  },
  {
    title: 'Ảnh nền',
    align: 'center',
    dataIndex: 'name',
    render: (text, record) => (
      <Image
        className='border mx-auto image-table'
        preview={true}
        src={record.bannerLangs[0] && record.bannerLangs[0].urlImage && record.bannerLangs[0].urlImage.length > 0 ? process.env.REACT_APP_API_ENDPOINT_DEV + record.bannerLangs[0].urlImage : ImageDefault} alt='AVP'
      />
    ),
  },
  {
    title: 'Tên',
    align: 'center',
    dataIndex: 'name',
    render: (text) => text,
  },
  {
    title: 'Thiết bị',
    align: 'center',
    dataIndex: 'device',
    render: (text) => text,
  },
  
  {
    title: 'Tiêu đề',
    align: 'center',
    render: (text,record) => {
      return record.bannerLangs[0].heading && <span>{record.bannerLangs[0].heading}</span>
    }
  },
  {
    title: 'Người tạo',
    align: 'center',
    dataIndex: 'createdAt',
    render: (text,record) => record && <span>{`${record.users.firstName} ${record.users.lastName} `  }</span>,
  },
  {
    title: 'Ngày tạo',
    align: 'center',
    dataIndex: 'createdAt',
    render: (text) => text && moment(text).format('YYYY-MM-DD hh:mm'),
  },
  {
    title: 'Ngày cập nhật',
    align: 'center',
    dataIndex: 'updatedAt',
    render: (text) => text && moment(text).format('YYYY-MM-DD hh:mm'),
  },
  {
    title: 'Trạng thái',
    align: 'center',
    dataIndex: 'actived',
    render: (text,record) => (
      <Tag color={ text === 1 ? 'success' : 'error'}   >
        Kích hoạt
      </Tag>
    ),
  },
  {
    title: 'Hành động',
    align: 'center',
    render: (text, record) =>{
      return (
            <Space size="small" className='flex justify-center  items-center' >
              <Tooltip placement="top" title={'Sửa'}>
                <Button onClick={()=>{navigate(`/banner/update/${record.id}`)}}  className='px-2'>
                  <EditFilled ></EditFilled>
                </Button>
              </Tooltip>
              {
                checkAccessRole(roles, MODULES.EDITOR, ACTION.DELETE) ? 
                <Popconfirm
                title="Bạn muốn xóa đối tượng này?"
                okText="Có"
                cancelText="Không"
                onConfirm={() => onConfirm(record.id)}
              >
                <Button className='px-2' danger>
                  <DeleteFilled  />
                  </Button>
              </Popconfirm>
                : ""
              }
              
            </Space>
          )
    } 
  },
]

  return (
    <>
     <div className='flex justify-between mt-2'>
      {
        checkAccessRole(roles, MODULES.EDITOR, ACTION.CREATE) ? <Button onClick={()=>{ navigate(`/banner/create`)}} className='mt-1 lg:mb-2  flex justify-center items-center px-2' >
        <PlusOutlined></PlusOutlined>
      </Button> :""
      }
       
        <Input.Search
          placeholder="Tìm kiếm..."
          allowClear
          onChange={searchPaging}
          className='w-52 md:w-64'
          loading={loading}
        />
      </div>
      <Table
        className="mt-4 lg:mt-1"
        pagination={{
          position: ['bottomCenter'],
          current: paramsFilter.page,
          pageSize: paramsFilter.amount,
          pageSizeOptions: PAGE_SIZE_OPTIONS,
          showQuickJumper: true,
          onChange: (page, pageSize) => {
            setParamsFilter({ ...paramsFilter, page: page, amount: pageSize })
          },
          total: pages.total,
        }}
        bordered
        loading={loading}
        dataSource={pages.list}
        columns={columns}
        size="small"
        rowKey={record => record.id}
        scroll={{ x: 640 }}
      />
    </>
  )
}

export default Banner