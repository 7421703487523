import { createSlice } from "@reduxjs/toolkit";

const initialState = { data: [] }

export const permission = createSlice({
    name:'permission',
    initialState:initialState,
    reducers:{
    ACCESS_ROLES: (state,action) => {
        return {
            ...state,
            data:action.payload
        }
    },
    }
})

const { actions,reducer } = permission;
export const { ACCESS_ROLES } = actions

export default reducer