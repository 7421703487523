import React, { useEffect, useState } from 'react'
//antd
import { Button, Form, Input, Modal, notification, Tree } from 'antd'
//support
import { ACTION, MODULES } from '../../../config/const'
//service
import { createRole, listAllRole, updateRole } from '../..//../services/permission'
import { useSelector } from 'react-redux'
import { checkAccessRole } from '../../../helper'


function RoleModal({open,paging,setOpenCreateModal,typeForm,detailData=undefined}) {
    //state
    const [checkedKeys, setCheckedKeys] = useState([])
    const [treeData, setTreeData] = useState([])
    const [loading,setLoading] = useState(false)
    const [dataForm, setDataForm] = useState({})
    //form
    const [form] = Form.useForm()
    const roles = useSelector((state) => state.permission).data;

    //redux
    //  const user = useSelector((state)=>state.login).dataUser.groupRoles?.isMain
    //service
    const listPermission = async () => {
        try {
          const res = await listAllRole()
          if (!res.data || res.data.code !== 200) {
            return notification.warning({ message: res.data.message })
          }
          const data = [
            {
              title: 'Cấp tất cả các quyền',
              key: '0',
              children: [],
            },
          ]
          data[0].children = res.data.data
          setTreeData(data)
        } catch (error) {
          notification.error({ message: error.message })
        } finally {
          setLoading(false)
        }
    }

     const detailPermission =  () =>{
      try {
        if(detailData){
          const { name, description,list,id } = detailData.data.data
          const dataform = {
            id,
            name,
            description
          }
          setDataForm(dataform)
          form.setFieldsValue(dataform)
          setCheckedKeys(list)
        }
      } catch(err){
        console.log(err)
        notification.error({ message: err.message })
      }
    }
   
    //action
    const onCreact = async () =>{
     if(typeForm === ACTION.CREATE){
       await form.validateFields()
       const { name,description } = form.getFieldValue() 
       const body = {
           name,
           description:!description?.length ? ' ':description,
           roleIds:checkedKeys,
       }
       const res = await createRole(body)
       if (!res.data || res.data.code !== 200) {
         setLoading(false)
         return notification.warning({
           message: res.data.message || 'Tạo nhóm phân quyền thất bại vui lòng thử lại!',
         })
       }else {
         setLoading(true)
         notification.success({ message: res.data.message })
         paging()
         setLoading(false)
         form.resetFields()
         setOpenCreateModal(false)
       }
     }
     if(typeForm === ACTION.UPDATE){
        if (!checkAccessRole(roles, MODULES.PERMISSION, ACTION.UPDATE)) {
          return notification.warning({
            message: 'Bạn không có quyền chỉnh sửa bài viết này',
          })
        }
        setLoading(true)
        await form.validateFields()
        const { name, description } = form.getFieldsValue()
        const body = {
          name,
          description: !description?.length ? ' ' : description,
          roleIds: checkedKeys
        }
        body.id = dataForm.id
        const res = await updateRole(body)
        if (!res.data || res.data.code !== 200) {
          return notification.warning({
            message: res.data.message || 'Chỉnh sửa nhóm phân quyền thất bại vui lòng thử lại!',
          })
        }
        notification.success({ message: res.data.message })
        paging()
        setLoading(false)
        form.resetFields()
        setOpenCreateModal(false)
     }
    }
    const onCheck = (checkedKeysValue) => {
        setCheckedKeys(checkedKeysValue)
    }
  /* eslint-disable no-template-curly-in-string */
        const validateMessages = {
            required: '${label} không được để trống',
            types: {
            email: '${label} phải là một email!',
            }
        };
  /* eslint-enable no-template-curly-in-string */

  //effects
  useEffect(()=>{
    listPermission()
  },[])

  useEffect(()=>{
    detailPermission()
  },[detailData])

  return (
    <Modal
        open={open}
        title= {typeForm === "CREATE" ? "Tạo nhóm phân quyền mới" : "Thông tin nhóm phân quyền"}
        onOk={()=>{onCreact() }
        }
        width={700}
        onCancel={() =>{
            setOpenCreateModal()  
            setCheckedKeys([])
            form.resetFields()
            setLoading(false)
        }}
        footer={[
          <Button key="back" onClick={() =>{
            setOpenCreateModal(false)
            setCheckedKeys([])
            form.resetFields()
            setLoading(false)
          }}>
            Hủy
          </Button>,
          <Button key="submit" type="primary"  loading={loading} onClick={onCreact}>
            {typeForm === "CREATE" ? <span>Tạo</span> : <span>Sửa</span>}
          </Button>
        ]}
      >
        <div className='flex justify-between gap-2'>
          <Form className='w-full' form={form} layout={'vertical'} validateMessages={validateMessages} 
                initialValues={{key:'EMPLOYEE'}}>
              <Form.Item
                  label="Tên nhóm"
                  name="name"
                  rules={[{required: true }]}
                >
                  <Input placeholder="Nhập tên nhóm" />
              </Form.Item>
              <Form.Item label="Ghi chú" name="description" >
                  <Input.TextArea showCount maxLength={300} autoSize={{ minRows: 3, maxRows: 5 }} />
              </Form.Item>

          </Form>
          <Tree   className='w-1/2'
                  style={{ marginTop: 10, marginLeft: 10 }}
                  defaultExpandAll={true}
                  checkable
                  checkedKeys={checkedKeys}
                  onCheck={onCheck}
                  treeData={treeData}
          />  
        </div>
      </Modal>
  )
}

export default RoleModal
